import React, { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { environment } from "environment/environment";

interface LiveChatProps {
  id: string;
  userName: string;
  email: string;
}

const LiveChat: React.FC<LiveChatProps> = ({ userName, email, id }) => {
  useEffect(() => {
    Crisp.configure(environment.CRISP_APP_KEY);
    Crisp.chat.show();

    // Set user details for the new conversation
    // Crisp.setTokenId(id);
    // Set user details
    Crisp.user.setEmail(email);
    Crisp.user.setNickname(userName);
  }, [userName, email, id]);

  return null;
};

export default LiveChat;
