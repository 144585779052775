import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services/AuthService";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import EmailVerificationCard from "../EmailVerification/emailVerificationCard";
import ResetVerificationCard from "./resetVerificationCard";
import { OtpType } from "enum/OtpType";


const ResetVerification: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [otp, setOtp] = useState("");
    const [isChangeUI, setChangeUI] = useState(true);

    document.title = "User Verification | Zulu Courier ";

    const handleSubmit1 = (enteredOtp: string, enteredClick: boolean) => {
        
        AuthService.forgetPasswordOTPVerification({ email: location.state?.email, otpCode: enteredOtp }).then(res => {
            if (res.success) {
                setOtp(enteredOtp);
                setChangeUI(enteredClick);
            } else {
                setOtp("");
                toast.error(res.error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                  style: {marginBottom: "4rem"},
                });
            }
        })
        .catch(error => {
            toast.error("Something went wrong. Please try again later.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: {marginBottom: "4rem"},
            });
        });
        
    };

    const handleSubmit2 = (enteredPassword: string) => {
        authenticator(location.state?.email, otp, enteredPassword);
    };


    const authenticator = (email: string ,otp: string, password: string) => {
        AuthService.resetVerification({email: email, otpCode: otp, password: password })
            .then( res => {
                if (res.success) {
                    AuthService.setToken(res.data);
                    // const data: any = await jwt_decode(res.data);
                    navigate(`/password-reset`, { replace: true });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                        style: {marginBottom: "4rem"},
                    });
                }
            })
            // .catch(error => {
            //     toast.error("Something went wrong. Please try again later.", {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         className: "foo-bar",
            //         style: {marginBottom: "4rem"},
            //     });
            // });
    };

    const resendOtp = () => {

        AuthService.resendOtp({ email: location.state?.email, otpType: OtpType.PASSWORD_OTP })
            .then(res => {
                if (res.success) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                        style: {marginBottom: "4rem"},
                    });
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                        style: {marginBottom: "4rem"},
                    });
                }
            })
            .catch(error => {
                toast.error("Something went wrong. Please try again later.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                    style: {marginBottom: "4rem"},
                });
            });
    };

    return (
        <>
            {
                isChangeUI ? (
                    <EmailVerificationCard email={location.state?.email} onSubmit={handleSubmit1} onResendOtp={resendOtp} />
                ) : (
                    <ResetVerificationCard onSubmit={handleSubmit2} />
                )
            }
        </>
    );
};

export default ResetVerification;