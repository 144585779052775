import React, { forwardRef, useImperativeHandle, useState } from "react";

import GooglePlacesAutocompleteProps, { GooglePlacesAutocompleteHandle } from "./types";
import usePlacesService from "./hooks/use-places-service";
import useFetchSuggestions from "./hooks/use-fetch-suggestions";

export type InputValueType = { label: string; placeId: string };

const CustomGooglePlacesAutocomplete: React.ForwardRefRenderFunction<GooglePlacesAutocompleteHandle, GooglePlacesAutocompleteProps> = (
  args: GooglePlacesAutocompleteProps,
  ref
): React.ReactElement => {
  const { placesService, sessionToken, setSessionToken } = usePlacesService({
    apiKey: args.apiKey ?? "",
    apiOptions: args.apiOptions ?? {},
    onLoadFailed: args.onLoadFailed ?? console.error,
  });
  const fetchSuggestions = useFetchSuggestions({
    autocompletionRequest: args.autocompletionRequest ?? {},
    debounce: args.debounce ?? 300,
    minLengthAutocomplete: args.minLengthAutocomplete ?? 0,
    placesService,
    sessionToken,
    withSessionToken: args.withSessionToken ?? false,
  });

  useImperativeHandle(
    ref,
    () => ({
      getSessionToken: () => {
        return sessionToken;
      },
      refreshSessionToken: () => {
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      },
      clearField: () => {
        setValue({label: "", placeId: ""})
      }
    }),
    [sessionToken]
  );

  const [options, setOptions] = useState<InputValueType[]>([]);
  const [value, setValue] = useState<InputValueType>();
  const { addressType, handleAddress } = args;
  // const { label, placeId } = value;

  return (
    <div className="autocomplete-container">
      <div className="mb-3 form-group">
        <input
          onReset={() => {
            setValue({} as InputValueType);
          }}
          onInput={() => setOptions(_ => [])}
          type="search"
          value={value?.label}
          className="form-control"
          onChange={e => {
            const targetValue = e.target.value;
            setValue({ label: targetValue, placeId: "" });
            fetchSuggestions(targetValue, val => {
              const mappedValues: InputValueType[] = val.map(v => ({
                label: v.label,
                placeId: v.value.place_id,
              }));
              setOptions(_ => [...mappedValues]);
            });
          }}
        />
      </div>
      {options.length > 0 && (
        <div className="autocomplete-overlay">
          {options.map((option, index) => (
            <div
              key={index}
              className="autocomplete-option"
              onClick={() => {
                handleAddress(option.label, option.placeId, addressType);
                setValue({ label: option.label, placeId: option.placeId });
                setOptions([]);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default forwardRef(CustomGooglePlacesAutocomplete);
