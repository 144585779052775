import React from "react";

export const ImageIcon = () => (
  <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7457 1.09351C17.5686 0.500406 24.4301 0.500405 31.2531 1.09351L34.5252 1.37795C37.6562 1.65012 40.1927 4.03269 40.66 7.14053C41.6429 13.6767 41.6429 20.3232 40.66 26.8594C40.574 27.4312 40.418 27.9784 40.2012 28.4925C40.0574 28.8333 39.6137 28.885 39.3648 28.6113L29.7851 18.0736C29.3522 17.5974 28.6792 17.4216 28.0688 17.625L22.5853 19.4529L14.6306 10.5037C14.3332 10.1692 13.9111 9.97216 13.4637 9.95901C13.0164 9.94587 12.5834 10.1178 12.267 10.4343L1.6197 21.0815C1.29201 21.4092 0.733142 21.2006 0.707016 20.738C0.450838 16.2012 0.66141 11.6447 1.33873 7.14053C1.80607 4.03269 4.34253 1.65012 7.47351 1.37795L10.7457 1.09351ZM25.3327 10.5C25.3327 8.70505 26.7878 7.24998 28.5827 7.24998C30.3776 7.24998 31.8327 8.70505 31.8327 10.5C31.8327 12.2949 30.3776 13.75 28.5827 13.75C26.7878 13.75 25.3327 12.2949 25.3327 10.5Z"
      fill="#FFC3AB"
    />
    <path
      d="M1.41558 25.8818C1.29565 26.0018 1.23868 26.171 1.26257 26.3389C1.28726 26.5125 1.31265 26.686 1.33873 26.8594C1.80608 29.9673 4.34253 32.3498 7.47351 32.622L10.7457 32.9064C17.5686 33.4995 24.4301 33.4995 31.2531 32.9064L34.5252 32.622C35.4453 32.542 36.314 32.2798 37.0939 31.8705C37.3895 31.7154 37.4393 31.3246 37.2147 31.0776L28.3297 21.3041C28.1854 21.1454 27.9611 21.0868 27.7576 21.1546L22.5966 22.8749C21.9799 23.0805 21.3 22.8988 20.8681 22.4129L13.7281 14.3803C13.521 14.1474 13.1606 14.1368 12.9402 14.3572L1.41558 25.8818Z"
      fill="#FFC3AB"
    />
  </svg>
);
