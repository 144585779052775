import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import { AuthService } from "services/AuthService"
import { toast } from "react-toastify"
toast.configure()
import "react-toastify/dist/ReactToastify.css"
import jwt_decode from "jwt-decode"
import Copyright from "components/Common/copyright"
import emailImage from "../../assets/images/forget password/email.svg"




const ForgetPassword: React.FC = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()

    const handleSubmit = (email: string) => {
        AuthService.forgetPasswordEmail({email:email,})
            .then( res => {
                if (res.success) {
                    // AuthService.setToken(res.data)
                    // const data: any = await jwt_decode(res.data)
                    navigate(`/reset-verification/${res.data}`, { state: { email: email }})
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                        style: {marginBottom: "4rem"},
                    })
                }
            })
    }

    return (
        <div className="container">

            <section className="my-4">
                <div className="position-relative">
                    <div className="row g-5 justify-content-center sign-form">


                        <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                            <div className="px-4 py-5 w-100">
                                <div className="sign-logo mb-3">
                                    <a href="/#">
                                        <img src={logo} alt="" />
                                        <img src={emailImage} alt="" />
                                    </a>
                                </div>

                                <h1 className="title mb-1">Forgot Password</h1>
                                <p className="mb-4">
                                No worries. We'll send you reset instructions.
                                </p>
                                <div className="form-groupco mt-2">
                                    <label className="place-order-title">
                                        Email<label className="title-star"></label>
                                    </label>
                                    <input

                                        type="text"
                                        name="otp"
                                        id="otp"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />

                                </div>

                                <div className="form-group">
                                    <button
                                        onClick={() => handleSubmit(email)}
                                        className="btn btn-md bg-orange text-white w-100 mt-4 button-bg">
                                        Reset Password
                                    </button>
                                </div>

                                <div className="w-100 text-center mt-5">
                                    <Copyright />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ForgetPassword;