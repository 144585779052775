import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { Order } from "models/Order";
import { OrderResponse } from "models/orderResponse";

export interface Count {
  count?: number;
}

export class OrderService {
  public static async createOrder(order: Order): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("order/create");
    const res = await axios.post<Order, AppResponse<string>>(ep, order);
    return res;
  }

  public static async orderDetails(order: Order): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("order/details");
    const res = await axios.post<Order, AppResponse<string>>(ep, order);
    return res;
  }

  public static async createStripeSession(data: any): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl(`checkout-session`);
    const res = await axios.post<Order, AppResponse<string>>(ep, data);
    return res;
  }

  public static async getOrderById(orderId: string): Promise<AppResponse<OrderResponse>> {
    const ep = Util.apiAuthUrl(`order/${orderId}`);
    const res = await axios.get<Order, AppResponse<OrderResponse>>(ep);
    return res;
  }

  public static async genarateOrderPDF(orderId: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`order/pdf/${orderId}`);
    const res = await axios.get<Order, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }
  public static async generateShippingLabelPDF(orderId: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`shipping-label/pdf/${orderId}`);
    const res = await axios.get<Order, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async genarateOrderReceiptPDF(orderId: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`order/receipt/pdf/${orderId}`);
    const res = await axios.get<Order, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async getOrdersByDuration(data: any, limit?: number, offset?: number): Promise<AppResponse<Order[]>> {
    const url = Util.apiAuthUrl(`order/duration/list/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<Order[]>>(url, data);
  }

  public static async getOrdersSearchBystatus(data: any, limit?: number): Promise<AppResponse<Order[]>> {
    const url = Util.apiAuthUrl(`order/orderlistbysearch/` + "1/" + limit);
    return await axios.post<void, AppResponse<Order[]>>(url, data);
  }

  public static async getOrdersByPaymentStatusBySearch(data: any, limit?: number, offset?: number): Promise<AppResponse<OrderResponse[]>> {
    const url = Util.apiAuthUrl(`order/searchpaymentstatus/` + "1/" + limit);
    return await axios.post<void, AppResponse<OrderResponse[]>>(url, data);
  }

  public static async getOrdersByStatus(data: any, limit?: number, offset?: number): Promise<AppResponse<Order[]>> {
    const url = Util.apiAuthUrl(`order/list/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<Order[]>>(url, data);
  }

  public static async getOrdersByPaymentStatus(data: any, limit?: number, offset?: number): Promise<AppResponse<OrderResponse[]>> {
    const url = Util.apiAuthUrl(`order/paymentstatus/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<OrderResponse[]>>(url, data);
  }

  public static async getOrderCountByUserId(userId?: string): Promise<AppResponse<Count>> {
    const ep = Util.apiAuthUrl(`order/count/${userId}`);
    const res = await axios.get<number, AppResponse<Count>>(ep);
    return res;
  }

  public static async getPendingStatusOfOrders(userId?: string): Promise<AppResponse<boolean>> {
    const ep = Util.apiAuthUrl(`order/pending-status/${userId}`);
    const res = await axios.get<void, AppResponse<boolean>>(ep);
    return res;
  }
}
