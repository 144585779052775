// src/components/filter.
import { OrderStatus } from "enum/OrderStatus";
import { Order } from "models/Order";
import React, { useEffect, useMemo, useState } from "react";
import { OrderService } from "services/OrderService";
import { Row, Col, CardBody, Card } from "reactstrap";
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../../components/Common/TableContainer";
import { format } from "date-fns";
import { AuthData, useAuth } from "context/AuthProvider";
import { useDispachCount } from "context/OrderCountProvider";
import { AdminService } from "services/AdminService";
import { Puff } from "react-loader-spinner";
import { DispatchType } from "enum/DispatchType";

const CanceledOrders: React.FC = () => {
  const [canceledOrders, setCanceledOrders] = useState([] as Order[]);
  const [user] = useAuth() as AuthData;
  const [isloading, setIsloading] = useState<boolean>(true);
  const columns = useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "uniqueId",
        Cell: ({ value }: any) => {
          return <div className="link-primary">{value}</div>;
        },
      },
      {
        Header: <div style={{ whiteSpace: "pre-line", width: "150px" }}>Individual / Company Name</div>,
        accessor: user?.isABusiness ? "userId.address.companyName" : "userId.address.contactName",
      },
      {
        Header: "Date",
        accessor: "deliveryTime",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd.MM.yyyy");
        },
      },
      {
        Header: "Pickup",
        accessor: "pickUpAddress.city",
        Cell: ({ row }: any) => {
          const value = row.original.pickUpAddress.city.split(",");
          const count = value.length;
          const city = count - 3 >= 0 ? value[count - 3] : "";
          return city;
        },
      },
      {
        Header: "PPC",
        accessor: "pickUpAddress.postalCode",
        Cell: ({ value }) => <div style={{ width: 80 }}>{value}</div>,
      },
      {
        Header: "Delivery",
        accessor: "deliveryAddress.city",
        Cell: ({ row }: any) => {
          const value = row.original.deliveryAddress.city.split(",");
          const count = value.length;
          const city = count - 3 >= 0 ? value[count - 3] : "";
          return city;
        },
      },
      {
        Header: "DPC",
        accessor: "deliveryAddress.postalCode",
        Cell: ({ value }) => <div style={{ width: 80 }}>{value}</div>,
      },
      {
        Header: "Driver",
        accessor: `driverId.firstName`,
      },
      {
        Header: "Weight (lbs)",
        accessor: "totalWeight",
        Cell: props => {
          return props.value.replace(/ pounds/gi, "");
        },
      },
      {
        Header: "Vehicle Type",
        accessor: "vehicleType",
      },
      {
        Header: "Price",
        accessor: user?.isABusiness ? "subTotal" : "totalFare",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: "Order Time",
        accessor: "packageReadyTime",
        Cell: ({ row }: any) => {
          return format(new Date(row.original.packageReadyTime), "hh:mma") + " to " + format(new Date(row.original.deliveryTime), "hh:mma");
        },
      },
      {
        Header: "Status",
        accessor: "orderStatus",
        Cell: props => {
          return props.value.replace(/_/g, " ").replace(/\b\w/g, (char: string) => char.toUpperCase());
        },
      },
    ],
    []
  );

  const LIMIT_P_C = 10;

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  const data = {
    status: OrderStatus.CANCELED,
    uniqueId: "",
    dispatchType: DispatchType.CANCELED_ORDERS,
  };

  const [dispachCount, setDispachCount] = useDispachCount();
  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  useEffect(() => {
    setIsloading(true);
    OrderService.getOrdersByStatus(data, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setCanceledOrders(res.data);
        setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }

      setIsloading(false);
    });
    statuscount();
  }, []);

  const SeeMore = () => {
    const updatedSkip = isOffset_p_c + 1;
    setOffset_p_c(updatedSkip);
    OrderService.getOrdersByDuration(data, LIMIT_P_C, updatedSkip).then(res => {
      if (res.success) {
        setCanceledOrders(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      status: OrderStatus.CANCELED,
      searchableString: serchText,
    };

    OrderService.getOrdersSearchBystatus(searchdata, LIMIT_P_C).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setCanceledOrders(res.data);
          setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
        }
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  //meta title
  document.title = "Canceled Order | Zulu Courier ";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Order Status" breadcrumbItem="View Canceled Orders" />
        {/* <Table columns={columns} data={data} /> */}

        <Col lg={12}>
          <Card>
            <CardBody>
              {isloading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>
              ) : (
                <>
                  {canceledOrders.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={canceledOrders}
                      currentPage={undefined}
                      pagelimit={undefined}
                      handlePageChange={undefined}
                      handleColumnChange={undefined}
                      selectedColumns={undefined}
                      showColumnChange={false}
                      handleSearch={searchTable}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={100}
                      className="custom-header-css"
                      isJobListGlobalFilter={undefined}
                      isAddUserList={undefined}
                      handleOrderClicks={undefined}
                      handleUserClick={undefined}
                      handleCustomerClick={undefined}
                      isAddCustList={undefined}
                      handleSendEmail={undefined}
                      isPDFGenerate={true}
                      ispopup={true}
                      isAddressBook={false}
                      hancleDeleteRecords={undefined}
                      handleSelect={undefined}
                      selectedID={undefined}
                      hasCheckBox={false}
                    />
                  ) : (
                    <Row className="justify-content-md-center justify-content-center align-items-center">
                      <Col className="col-md-auto">
                        <div>No Canceled Orders</div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {canceledOrders.length > 9 && (
                <Row className="justify-content-md-start">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <button className="btn btn-primary btn-sm px-4 me-lg-2 me-md-2 mb-2" disabled={seeMore_p_c} onClick={() => SeeMore()}>
                        See More
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default CanceledOrders;
