import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useAuth } from "context/AuthProvider";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { NotificationService } from "services/NotificationService";

import { getTimeAgo } from "helpers/get_time_date";
import { useNotification } from "context/NotificationProvider";
import io from "socket.io-client";
import { environment } from "environment/environment";
import { useDispachCount } from "context/OrderCountProvider";
import { AdminService } from "services/AdminService";
const socket = io(environment.api_url, {
  transports: ["polling"],
});

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [user] = useAuth();
  const [notification] = useNotification();

  const [readStatus, setReadStatus] = useState(false);

  //dispatch realtime
  const [dispachCount, setDispachCount] = useDispachCount();

  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  useEffect(() => {
    fetchData(); // Call the fetchData function inside useEffect

    // Listen for 'notification' events from the Socket.io server
    socket.on("notification", data => {
      // When a new notification is received, update the notifications list
      fetchData();
      statuscount();
      // setNotifications([data, ...notifications]);
      // setCount(count + 1);
    });

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("notification");
    };
  }, [notification, readStatus]);

  const fetchData = async () => {
    try {
      const response = await NotificationService.getNotifications(5, 0);
      if (response) {
        setNotifications(response.notifications);
        setCount(response.count);
      } else {
        console.log("No notifications found in the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getRouteForNotification = NotificationEvent => {
    switch (NotificationEvent) {
      case "ORDER_CREATED":
        return `/${user.role}/current-orders`;
      case "ORDER_PICKEDUP":
        return `/${user.role}/current-orders`;
      case "ORDER_DELIVERED":
        return `/${user.role}/delivered-orders`;
      case "ORDER_RETURN":
        return `/${user.role}/return-orders`;
      case "ORDER_REATTEMPT":
        return `/${user.role}/reattempt-orders`;
      case "ORDER_FAILED":
        return `/${user.role}/failed-orders`;
      case "NEW_ADDERSS_ADDED":
        return `/${user.role}/address-book`;
      default:
        return "";
    }
  };

  const changeReadStatus = notificationId => {
    NotificationService.updateNotification(notificationId).then(res => {
      if (res.success) {
        setReadStatus(!readStatus);
        setMenu(!menu);
      } else {
        console.error(res.error);
      }
    });
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle className="btn header-item noti-icon position-relative" tag="button" id="page-header-notifications-dropdown">
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href={`/${user.role}/notification`} className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ height: "200px" }}>
            {notifications.length > 0 ? (
              notifications?.map(function (notification, i) {
                return (
                  <Link to={getRouteForNotification(notification?.event)} className="text-reset notification-item" key={notification?._id}>
                    <div
                      className={notification.readStatus === false ? "d-flex bg-light" : "d-flex"}
                      onClick={() => changeReadStatus(notification?._id)}
                    >
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bx-cart d-flex" />
                        </span>
                      </div>
                      {notification?.event === "NEW_ADDERSS_ADDED" ? (
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">Address Added</h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">{notification?.content}</p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" /> {getTimeAgo(notification?.createdAt)}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">{notification.content}</h6>
                          <div className="font-size-13 fw-bold text-muted">
                            {notification.orderId &&
                              (notification.orderId.startsWith("Invoice")
                                ? `Invoice Id: ${notification.orderId}`
                                : `Order Id: ${notification.orderId}`)}
                          </div>

                          <div className="font-size-12 text-muted">
                            <p className="mb-1">{notification?.message}</p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" /> {getTimeAgo(notification?.createdAt)}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                );
              })
            ) : (
              <div className="d-flex text-center">
                <div className="flex-grow-1">
                  <div className="font-size-15 fw-bold text-muted">No new notification</div>
                </div>
              </div>
            )}

            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img src={avatar3} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">{props.t("It will seem like simplified English") + "."}</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{props.t("Your item is shipped")}</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">{props.t("If several languages coalesce the grammar")}</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img src={avatar4} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">{props.t("As a skeptical Cambridge friend of mine occidental") + "."}</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid text-center">
            <a href={`/${user.role}/notification`} className="small">
              {" "}
              View More...
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
