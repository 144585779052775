import { RouteContext } from "context/RouteProvider"
import React, { ElementType, useContext } from "react"
import { Navigate } from "react-router-dom"

interface PrivateRouteProps {
  redirectTo: string
  children: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ redirectTo, children }) => {
  const [isProtected, setIsProtected] = useContext(RouteContext)

  if (!isProtected) {
    return <Navigate to={redirectTo} />
  }

  return <>{children}</>
}

export default PrivateRoute
