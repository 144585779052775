import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Card, CardBody, Col, Label, Row } from "reactstrap";
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import DatePicker from "react-datepicker";
// import { Input, DatePicker, Calendar } from 'rsuite';
import "react-datepicker/dist/react-datepicker.css";
import { AdminService } from "services/AdminService";
import { DriverCommission } from "models/DriverCommission";
import TableContainer from "components/Common/TableContainer";
import DriverInvoiceView from "./DriverInvoiceView";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { Puff } from "react-loader-spinner";

const DriverBilling: React.FC = () => {
  const [startDate, setStartDate] = useState(moment.tz("America/Toronto").startOf("month"));
  const [endDate, setEndDate] = useState(moment.tz("America/Toronto"));

  const [showTable, setShowTable] = useState(false);
  const [driverBills, setDriverBills] = useState<DriverCommission[]>();
  const [driverBill, setDriverBill] = useState<DriverCommission>();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [modal, setModal] = useState(false);

  const data = {
    date1: startDate,
    date2: endDate,
  };

  useEffect(() => {
    setIsloading(true);
    AdminService.getDriverBilling(data)
      .then(res => {
        if (res.success) {
          setDriverBills(res.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
    setIsloading(false);
  }, [showTable]);

  const searchTable = (serchText: string) => {
    const searchdata = {
      date1: startDate,
      date2: endDate,
      status: "pending",
      searchableString: serchText,
    };

    AdminService.getDriverBillingBySearch(searchdata).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setDriverBills(res.data);
        }
      }
    });
  };

  const handleStartDate = (date: Date | null) => {
    const sDate = moment(date);
    setStartDate(sDate);
  };

  const handleEndDate = (date: Date | null) => {
    const eDate = moment(date);
    setEndDate(eDate);
  };

  const generateInvoice = () => {
    AdminService.sendDriverBillingInvoices(data)
      .then(res => {
        if (res.success) {
          setDriverBills(res.data);
          toast.success("Successfully generated invoices.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          toast.info("There are no pending driver billing invoices.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleViewInvoice = (values: any) => {
    setDriverBill(values?.original);
    handlePopup();
  };

  const columns = useMemo(
    () => [
      {
        id: "driverId",
        Header: "Driver ID",
        accessor: "driverDetails.customerId",
      },
      {
        id: "driverName",
        Header: "Driver Name",
        accessor: "driverDetails.firstName" || "",
        Cell: ({ row }: any) => {
          return row?.original?.driverDetails?.firstName + " " + row?.original?.driverDetails?.lastName;
        },
      },
      {
        id: "totalOrders",
        Header: "Total Number of Orders",
        accessor: "totalOrders",
        Cell: ({ value }) => `${value.toString().padStart(2, "0")}`,
      },
      {
        id: "totalAmount",
        Header: "Total Order Value",
        accessor: "totalOrderAmount",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        id: "commission",
        Header: "Commission Earned",
        accessor: "totalCommision",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: any) => {
          return (
            <h6 className="text-center">
              <Badge color={value === "pending" ? "warning" : "success"}>
                {value}
              </Badge>
            </h6>
          );
        },
      },
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button
                className="btn btn-primary btn-sm px-3"
                onClick={() => {
                  handleViewInvoice(row);
                  setShowTable(!showTable);
                }}
              > View Invoice</button>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Driver Billing | Zulu Courier";

  return (
    <React.Fragment>
      <div className="page-content mb-5">
        <div className="container-fluid mb-5">
          <Breadcrumb title="Driver Billing" breadcrumbItem="Create Invoice" />
          <Row>
            <Col lg="12">
              <Card className="mb-5 bg-m-none">
                <CardBody>
                  <div className="wizard clearfix mb-5">
                    <Label>Date Selection</Label>
                    <Row>
                      <Col lg="4" className="h-100">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={startDate ? startDate.toDate() : null}
                          onChange={handleStartDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />

                     
                      </Col>
                      <Col lg="1" className="text-center">
                        <Label className="mt-lg-2">To</Label>
                      </Col>
                      <Col lg="4">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={endDate ? endDate.toDate() : null}
                          onChange={handleEndDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />
                      
                      </Col>
                      <Col lg="3">
                        <button type="submit" onClick={() => setShowTable(!showTable)} className="btn btn-primary btn-sm px-3 me-2 mt-lg-2">
                          Filter
                        </button>
                        <button type="submit" onClick={generateInvoice} className="btn btn-primary btn-sm mt-lg-2">
                          Generate Invoice
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div className="wizard clearfix">
                    <Label>Billing Table</Label>
                    <Row>
                      <Col lg="12">
                        {isloading? <div className="d-flex justify-content-center align-items-center"  style={{ height: '500px' }}>
                          <Puff color="#FFD0B8" height={50} width={50} />
                        </div>:
                          <>{driverBills !== undefined && driverBills.length > 0 ? (
                            <TableContainer
                              columns={columns}
                              data={driverBills}
                              currentPage={undefined}
                              pagelimit={undefined}
                              handlePageChange={undefined}
                              handleColumnChange={undefined}
                              selectedColumns={undefined}
                              showColumnChange={false}
                              handleSearch={searchTable}
                              isGlobalFilter={true}
                              isAddOptions={false}
                              customPageSize={10}
                              className="custom-header-css"
                              isJobListGlobalFilter={undefined}
                              isAddUserList={undefined}
                              handleOrderClicks={undefined}
                              handleUserClick={undefined}
                              handleCustomerClick={undefined}
                              isAddCustList={undefined}
                              handleSendEmail={undefined}
                              isPDFGenerate={false}
                              ispopup={false}
                              isAddressBook={false}
                              hancleDeleteRecords={undefined}
                              handleSelect={undefined}
                              selectedID={undefined}
                              hasCheckBox={false}
                            />
                          ) : (
                            <Row className="justify-content-md-center justify-content-center align-items-center mb-5">
                              <Col className="col-md-auto">
                                <div>No Driver Billings</div>
                              </Col>
                            </Row>
                          )}</>}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <DriverInvoiceView isOpen={modal} handlePopup={handlePopup} driverBill={driverBill} dates={data} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverBilling;
