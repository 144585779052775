/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Util } from "Util";
import axios from "axios";
import { Invoice } from "models/Invoice";
import { AppResponse } from "models/Response";
import { Payment } from "models/Payment";
import { Order } from "models/Order";

export class InvoiceService {
  public static async getInvoices(): Promise<AppResponse<Invoice[]>> {
    const ep = Util.apiAuthUrl(`invoice/list`);
    const res = await axios.get<void, AppResponse<Invoice[]>>(ep);
    return res;
  }

  public static async genaratePDF(): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`invoice/pdf`);
    const res = await axios.get<Invoice, AppResponse<ArrayBuffer>>(ep);
    return res;
  }

  public static async getBusinessCustomerInvoices(limit?: number, offset?: number): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`user/invoice/business-customer-invoice/${offset}/${limit}`);
    const res = await axios.post<void, AppResponse<Invoice[]>>(ep);
    return res;
  }

  public static async getBcustomerInvoiceById(invoiceId: string): Promise<AppResponse<Invoice>> {
    const ep = Util.apiAuthUrl(`user/invoice/` + invoiceId);
    const res = await axios.get<void, AppResponse<Invoice>>(ep);
    return res;
  }

  public static async generateInvoicePDF(invoiceId?: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`user/invoice/pdf/` + invoiceId);
    const res = await axios.get<Invoice, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async getPaymentLogList(limit?: number, offset?: number): Promise<AppResponse<Payment[]>> {
    const ep = Util.apiAuthUrl(`payment-list/` + offset + "/" + limit);
    const res = await axios.get<void, AppResponse<Payment[]>>(ep);
    return res;
  }

  public static async getPaymentLog(id?: string): Promise<AppResponse<PaymentResponse>> {
    const ep = Util.apiAuthUrl(`paymentbyId/` + id);
    const res = await axios.get<void, AppResponse<PaymentResponse>>(ep);
    return res;
  }

  public static async getPaymentLogListBySearch(data: any, limit?: number): Promise<AppResponse<Payment[]>> {
    const url = Util.apiAuthUrl(`payment-list-search/` + "1/" + limit);
    return await axios.post<void, AppResponse<Payment[]>>(url, data);
  }

  public static async genaratePaymentPDF(id?: string): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`paymentpdf/` + id);
    const res = await axios.get<Payment, AppResponse<ArrayBuffer>>(ep, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async genarateInvoiceReceiptPDF(data: any): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiAuthUrl(`invoice/receipt/pdf`);
    const res = await axios.post<Order, AppResponse<ArrayBuffer>>(ep, data, {
      responseType: "arraybuffer", // Specify responseType as arraybuffer
    });
    return res;
  }

  public static async getBusinessCustomerInvoicesBySearch(data: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("user/invoice/business-customer-invoice-search");
    const res = await axios.post<void, AppResponse<any>>(ep, data);
    return res;
  }
}
