import { Order } from "./Order";

export enum InvoiceType {
  ONE_TIME = "one_time",
  MONTHLY = "monthly",
}

export enum PaymentType {
  ORDER = "order",
  INVOICE = "invoice",
  ADMINORDER = "admin_order",
}

export interface Invoice {
  _id?: string;
  month?: string;
  outstandingBalance?: number;
  orderIds?: [];
  uniqueIds?: [];
  fileName?: string;
  invoiceType?: string;
  paymentType?: PaymentType;
  userId?: string;
  customerId?: string;
  createdAt?: string;
  updatedAt?: string;
  invoiceId?: string;
  paymentStatus?: string;
  isAdminUpdated?: boolean;
  discount?: number;
  startDate?: Date;
  endDate?: Date;
}

export interface BillingUsers {
  billingUsers: Invoice[];
  count: number;
}

export interface BCustomerInvoice {
  _id?: string;
  invoiceId?: string;
  month?: string;
  outstandingBalance?: number;
  totalOrders?: number;
  totalAmount?: number;
  totalhst?: number;
  totalsubTotal?: number;
  orderIds?: [];
  uniqueIds?: [];
  fileName?: string;
  invoiceType?: string;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
  customerId?: string;
  startDate?: Date;
  discount?: number;
  endDate?: Date;
  isAdminUpdated?: boolean;
  paymentStatus?: string;
}
