import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
// import { DatePicker } from 'rsuite';

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onHandleDateChange: (date: Date | null) => void;
  handleDateclick: () => void;
}

function SetDate(props: ModalProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  // const [selectedDate, setSelectedDate] = useState<Date | null>(moment.tz("America/Toronto").toDate());

  const { isOpen, toggle, onHandleDateChange, handleDateclick } = props;

  const handleDateChange = (date: Date | null) => {
    
    setSelectedDate(date);
  };

  useEffect(() => {
    onHandleDateChange(selectedDate);
  }, [selectedDate]);

  const handLeDateclick = () => {
    handleDateclick();
    toggle();
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="sm">
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center">
            <div
              style={{
                whiteSpace: "nowrap",
                fontSize: "20px",
              }}
            >
              Date
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <DatePicker
              onFocus={(e) => e.target.blur()}
              selected={selectedDate}
              onChange={handleDateChange}
              showMonthDropdown
              showYearDropdown
              inline
              className="form-control"
            />
            
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handLeDateclick} size="sm">
            Set Date
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SetDate;
