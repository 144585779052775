import { QuoteModel } from "models/Quote";
import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { format } from "date-fns";
import { GenerateQuotePDF } from "./GenerateQuotePDF";

export interface Props {
  isOpen: boolean;
  handlePopup: () => void;
  quote?: QuoteModel;
}

const QuoteModalView: React.FC<Props> = ({ isOpen, handlePopup, quote }: Props) => {
  const handleDownload = () => {
    handlePopup();
    GenerateQuotePDF(quote);
  };

  const handleClose = () => {
    handlePopup();
  };
  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="xl" backdrop="static" style={{ maxWidth: "1300px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}></ModalHeader>
        <ModalBody>
          <h4 className="bg-primary bg-opacity-25 popover-header py-2 text-center">Quote Details</h4>
          <div className="mx-lg-3 px-lg-5 small">
            <Row>
              <Col lg="6">
                <div className="mb-3 me-2 form-group">
                  <div className="mt-5">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Quote ID
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.zuluId}</Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Pick - Up Address
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.pickUp?.city}</Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Pickup Date and Time
                      </Col>
                      <Col lg="1 d-lg-flex d-none">:</Col>
                      <Col lg="7">
                        {quote?.packageReadyTime !== undefined ? format(new Date(quote?.packageReadyTime), "MMMM do, yyyy hh:mm a") : ""}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Total Weight
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.totalWeight}</Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Total Distance
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.totalDistance}</Col>
                    </Row>
                  </div>
                  <div className="mt-3 hidden">
                    {/* <Row>
                      <Col lg="4" className="fw-semibold">
                        HST 13%
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{"$" + quote?.hst?.toFixed(2)}</Col>
                    </Row> */}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3 ms-2 form-group">
                  <div className="mt-5 py-2"></div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Delivery Address
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.delivery?.city}</Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Delivery Date and Time
                      </Col>
                      <Col lg="1 d-lg-flex d-none">:</Col>
                      <Col lg="7">
                        {quote?.deliveryTime !== undefined ? format(new Date(quote?.deliveryTime), "MMMM do, yyyy hh:mm a") : ""}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Vehicle Type
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{quote?.vehicleType}</Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <Row>
                      <Col lg="4" className="fw-semibold">
                        Sub Total
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{"$" + quote?.subTotal?.toFixed(2)}</Col>
                    </Row>
                  </div>
                  <div className="mt-3 hidden">
                    {/* <Row>
                      <Col lg="4" className="fw-semibold">
                        Total Fare
                      </Col>
                      <Col lg="1" className="d-lg-flex d-none">
                        :
                      </Col>
                      <Col lg="7">{"$" + quote?.totalFare?.toFixed(2)}</Col>
                    </Row> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" onClick={handleDownload} className="btn btn-primary btn-sm px-3 ms-lg-4">
            Download PDF
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default QuoteModalView;
