import React, { useEffect } from "react";
import { useNavigate  } from 'react-router-dom';

const Landing: React.FC = () => {
    const navigate = useNavigate ();

    useEffect(() => {
        navigate('/signin');
      }, []);
      
    return(
        <>
            <div className="container">
                
            </div>
        </>
    )
}

export default Landing;