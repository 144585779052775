import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Label, Row } from "reactstrap";
import { AuthService } from "services/AuthService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import Breadcrumb from "components/Common/Breadcrumb";

const ChangePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newErr, setNewErr] = useState<string>();
  const [oldErr, setOldErr] = useState<string>();
  const [confirmErr, setConfirmErr] = useState<string>();

  const handlePassword = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "oldPassword":
        if (value == "" || value == null || value == undefined) {
          setOldErr("Required");
        } else if (value.length < 6) {
          setOldErr("Password should be atleast 6 characters long.");
        } else {
          const pwData = {
            oldPassword: value,
          };
          AuthService.comparePassword(pwData).then(res => {
            if (res.success) {
              setOldErr(undefined);
            } else {
              setOldErr("Invalid old password.");
            }
          });
        }
        setOldPassword(value);
        break;
      case "newPassword":
        if (value == "" || value == null || value == undefined) {
          setNewErr("Required");
        } else if (value.length < 6) {
          setNewErr("Password should be atleast 6 characters long.");
        } else if (oldPassword === value) {
          setNewErr("New password should not be same as old password.");
        } else {
          setNewErr(undefined);
        }
        setNewPassword(value);
        break;
      case "confirmPassword":
        if (value == "" || value == null || value == undefined) {
          setConfirmErr("Required");
        } else if (newPassword !== value) {
          setConfirmErr("Password should match.");
        } else {
          setConfirmErr(undefined);
        }
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const updatePassword = () => {

    if (oldPassword == "" || oldPassword == null || oldPassword == undefined) {
      setOldErr("Required");
      toast.error("Old password is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (newPassword == "" || newPassword == null || newPassword == undefined) {
      setNewErr("Required");
      toast.error("New password is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (confirmPassword == "" || confirmPassword == null || confirmPassword == undefined) {
      setConfirmErr("Required");
      toast.error("Confirm password is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (newPassword !== confirmPassword) {
      setConfirmErr("Password should match.");
      toast.error("Password should match.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      const pwData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      AuthService.changePassword(pwData)
        .then(res => {
          if (res.success) {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            toast.success("Successfully changed password.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  document.title = "Password | Zulu Courier ";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Change Password" breadcrumbItem="Zulu Courier" />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Change Password</CardTitle>
              <div className="tab-content">
                <Row>
                  <Col lg="4">
                    <div className="mb-3 form-group">
                      <Label>
                        Old Password <Label className="title-star">*</Label>
                      </Label>
                      <input
                        type="password"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handlePassword}
                        className={oldErr ? "input-error form-control" : "form-control"}
                      />
                      {oldErr && <p className="error col-lg-12">{oldErr}</p>}
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3 form-group">
                      <Label>
                        New Password <Label className="title-star">*</Label>
                      </Label>
                      <input
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={handlePassword}
                        className={newErr ? "input-error form-control" : "form-control"}
                      />
                      {newErr && <p className="error col-lg-12">{newErr}</p>}
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3 form-group">
                      <Label>
                        Confirm Password <Label className="title-star">*</Label>
                      </Label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handlePassword}
                        className={confirmErr ? "input-error form-control" : "form-control"}
                      />
                      {confirmErr && <p className="error col-lg-12">{confirmErr}</p>}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="actions clearfix d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
                <button className="btn btn-primary btn-md px-5" onClick={updatePassword}>
                  Save
                </button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
