import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer ms-lg-4">
        <Container fluid={true}>
          <Row>
            <Col md={6}><small>{new Date().getFullYear()}  © Zulu Courier.</small></Col>
            <Col md={6}>
              <small className="text-sm-end d-none d-sm-block">All Rights Reserved!</small>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
