import { Util } from "Util";
import axios from "axios";
import { AddressBook, AddressBookList } from "models/AddressBook";
import { AppResponse } from "models/Response";


export class AddressService {
  public static async createAddress(address: AddressBook): Promise<AppResponse<AddressBook>> {
    const ep = Util.apiAuthUrl("address-book/create");
    const res = await axios.post<AddressBook, AppResponse<AddressBook>>(ep, address);
    return res;
  }

  public static async getAddressBookList(limit?: number, offset?: number): Promise<AppResponse<AddressBookList>> {
    const ep = Util.apiAuthUrl(`address-book/list/` + offset + "/" + limit);
    const res = await axios.get<void, AppResponse<AddressBookList>>(ep);
    return res;
  }

  public static async deleteAddress(addressId: any): Promise<AppResponse<AddressBook>> {
    const ep = Util.apiAuthUrl(`address-book/delete/${addressId}`);
    const res = await axios.delete<void, AppResponse<AddressBook>>(ep);
    return res;
  }

  public static async getAddressBooksBySearch(searchString: any, limit?: number, offset?: number): Promise<AppResponse<AddressBook[]>> {
    const ep = Util.apiAuthUrl(`address-book/getListBySearch/` + offset + "/" + limit);
    return await axios.post<void, AppResponse<AddressBook[]>>(ep, searchString);
  }
}
