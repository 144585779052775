import React, { useState } from "react";
import { Badge, Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import SetDate from "./SetDate";
import download from "../../assets/images/download.svg";
import { GeneratePDF } from "admin/CustomerBilling/View Billing/GeneratePDF";
import ReactPaginate from "react-paginate";
import { format, parseISO } from "date-fns";
import "../../assets/css/table.css";
export interface CustomerViewProps {
  isOpen: boolean;
  handlePopup: () => void;
  modalColumns: any;
  data: any[];
  name: string;
  id: string;
  handleSearch: any;
  popupDate: any;
  handleModalDateClick: () => any;
  editId: (id: string) => void;
  openEdit: () => void;
  pagelimit: any;
  handlePageChange: any;
}

const FinanceInvoiceView: React.FC<CustomerViewProps> = ({
  isOpen,
  handlePopup,
  modalColumns,
  data,
  name,
  id,
  handleSearch,
  popupDate,
  handleModalDateClick,
  editId,
  openEdit,
  pagelimit,
  handlePageChange,
}: CustomerViewProps) => {
  const handleClose = () => {
    handlePopup();
  };

  const [isClicked, setIsClicked] = useState(false);
  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  const toggleSetDate = () => {
    setIsClicked(!isClicked);
  };

  function getStatusColor(status: any) {
    switch (status) {
      case "paid":
        return "success";
      case "overdue":
        return "danger";
      default:
        return "warning";
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="lg" backdrop="static" style={{ maxWidth: "1200px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}></ModalHeader>
        <ModalBody className="px-lg-5">
          <h4 className="bg-primary bg-opacity-25 popover-header py-2 text-center">Past Invoices</h4>
          <div className="small">
            <Row className="pt-4">
              <Col lg="4" md="4" sm="4" xs="4" className="fw-semibold">
                Customer ID
              </Col>
              <Col lg="1" md="1" sm="1" xs="1">
                :
              </Col>
              <Col lg="7" md="7" sm="7" xs="7">
                {id}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col lg="4" md="4" sm="4" xs="4" className="fw-semibold">
                Customer Name
              </Col>
              <Col lg="1" md="1" sm="1" xs="1">
                :
              </Col>
              <Col lg="7" md="7" sm="7" xs="7">
                {name}
              </Col>
            </Row>

            <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
              <div className="search-box pt-4">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label d-flex align-items-center">
                    <Button color="primary" size="sm" onClick={toggleSetDate} style={{ maxHeight: "40px" }}>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.41602 10.0832H8.24935V11.9165H6.41602V10.0832ZM6.41602 13.7502H8.24935V15.5835H6.41602V13.7502ZM10.084 10.0833H11.9173V11.9166H10.084V10.0833ZM10.084 13.7502H11.9173V15.5835H10.084V13.7502ZM13.748 10.0833H15.5814V11.9166H13.748V10.0833ZM13.748 13.7502H15.5814V15.5835H13.748V13.7502Z"
                          fill="white"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.58333 20.1665H17.4167C18.4278 20.1665 19.25 19.3442 19.25 18.3332V7.33316V5.49983C19.25 4.48874 18.4278 3.66649 17.4167 3.66649H15.5833V1.83316H13.75V3.66649H8.25V1.83316H6.41667V3.66649H4.58333C3.57225 3.66649 2.75 4.48874 2.75 5.49983V7.33316V18.3332C2.75 19.3442 3.57225 20.1665 4.58333 20.1665ZM17.4154 7.33331L17.4163 18.3333H4.58203V7.33331H17.4154Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                    <div className="ps-2"></div>
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <div className="input-group">
                      <input
                        onChange={e => {
                          handleSearch(e.target.value);
                        }}
                        id="search-bar-0"
                        type="text"
                        className="form-control border border-light-subtle rounded-3 py-2 px-lg-5"
                        placeholder="Search .."
                        // value={value || ""}
                      />
                      <div className="input-group-append">
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive react-table small" style={{ maxWidth: "1200px" }}>
            <table  className="newTable">
              <thead className="newThead">
                <tr>
                  {modalColumns.map((column: any) => (
                    <th key={column.Header}>{column.Header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((row: any, rowIndex: number) => (
                  <tr key={rowIndex}>
                    {modalColumns.map((column: any) => (
                      <td key={column.Header} data-label={column.Header}>
                        {column.Header === "Status" ? (
                          // Check if the column is 'Status', and if so, render a Bootstrap badge
                          <Badge color={getStatusColor(row.payStatus)}>{row.payStatus}</Badge>
                        ) : column.Header === "Amount Due" ? (
                          // Add $ sign and handle the case where the value is undefined
                          row.amountDue ? (
                            <span>${row.amountDue.toFixed(2)}</span>
                          ) : (
                            <span>$ 0.00</span>
                          )
                        ) : column.Header === "Action" ? (
                          // Add $ sign and handle the case where the value is undefined
                          <button
                            className="btn btn-outline-success btn-sm w-full-view"
                            onClick={() => {
                              editId(row._id);
                              openEdit();
                            }}
                          >
                            Edit
                          </button>
                        ) : column.Header === "PDF" ? (
                          // Add $ sign and handle the case where the value is undefined
                          <span className="download_btn_container">
                              <img  className="large_screen_download" src={download} alt="" onClick={() => GeneratePDF(row._id)} />
                              <button className="btn btn-primary small_screen_download sm-custom-button" onClick={() => GeneratePDF(row._id)}>Download</button>
                          </span>
                         
                        ) : column.Header === "Amount Paid" ? (
                          // Add $ sign and handle the case where the value is undefined
                          row.amountPaid ? (
                            <span>${row.amountPaid.toFixed(2)}</span>
                          ) : (
                            <span>$ 0.00</span>
                          )
                        ) : column.Header === "Billing Period" ? (
                          (() => {
                            const createdAtString = row.startDate;
                            const updatedAtString = row.endDate;
                            const createdDate = format(parseISO(createdAtString), "MMM do");
                            const updatedDate = format(parseISO(updatedAtString), "MMM do");
                            return <span>{`${createdDate} - ${updatedDate}`}</span>;
                          })()
                        ) : (
                          row[column.accessor]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {pagelimit > 1 && (
            <ReactPaginate
              pageCount={pagelimit}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="pagination justify-content-end align-items-end rounded-pill px-3"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          )}
        </ModalBody>
      </Modal>

      <SetDate isOpen={isClicked} toggle={toggleSetDate} onHandleDateChange={popupDate} handleDateclick={handleModalDateClick} />
    </React.Fragment>
  );
};

export default FinanceInvoiceView;
