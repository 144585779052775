import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AddressModel } from "models/City";
import { environment } from "environment/environment";
import { DriverSchema } from "schemas/driverSchema";
import { AdminService } from "services/AdminService";
import { Driver } from "models/Driver";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { AddressType } from "enum/AddressType";

export interface AddressProps {
  isOpen: boolean;
  handlePopup: () => void;
  driverId: string;
}

const UpdateDriver: React.FC<AddressProps> = ({ isOpen, handlePopup, driverId }: AddressProps) => {
  const [driver, setDriver] = useState({} as Driver);
  const [address, setAddress] = useState<AddressModel>();
  const [editAddress, setEditAddress] = useState(false);
  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85, // Northeast corner latitude (Keep this as is)
      south: 41.6, // Southwest corner latitude (Keep this as is)
      east: -74.0, // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15, // Keep this as is to cover the entire Ontario province
    },
  };
  useEffect(() => {
    AdminService.getDriverById(driverId).then(res => {
      if (res.success) {
        setDriver(res.data);
        setAddress(res.data.address);
        setFieldValue("firstName", res.data.firstName);
        setFieldValue("lastName", res.data.lastName);
        setFieldValue("email", res.data.email);
        setFieldValue("typeOfPhone", res.data.typeOfPhone);
        setFieldValue("phoneNumber", res.data.phoneNumber);
        setFieldValue("percentage", res.data.percentage);
      }
    });
    setEditAddress(false);
  }, [isOpen]);

  const toggleAddressInput = () => {
    setEditAddress(!editAddress);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    typeOfPhone: "",
    phoneNumber: "",
    percentage: "",
  };
  const onSubmit = async (values: typeof initialValues, actions: any) => {
    const submitData: Driver = {
      _id: driver._id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      typeOfPhone: values.typeOfPhone,
      phoneNumber: values.phoneNumber,
      address: address,
      percentage: values.percentage,
    };
    if (address?.city == "" || address?.city == undefined) {
      toast.error("Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      await AdminService.updateDriver(submitData).then((res: { success: any; data: any }) => {
        if (res.success) {
          setAddress({});
          setEditAddress(true);
          handlePopup();
          actions.resetForm();
          toast.success("Successfully Update Driver.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          toast.error("error occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      });
    }
  };
  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      typeOfPhone: "Mobile",
      phoneNumber: "",
      percentage: "",
    },
    validationSchema: DriverSchema,
    onSubmit, // Fix the attribute name here
  });

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="xl">
        <ModalHeader toggle={handlePopup}>Update Driver</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Card>
              <CardBody>
                <div className="tab-content">
                  <TabContent className="body mt-3">
                    <TabPane>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3 form-group">
                            <Label for="basicpill-phoneno-input3" className="place-order-title">
                              First Name <Label className="title-star">*</Label>
                            </Label>
                            <input
                              value={values?.firstName}
                              onChange={handleChange}
                              id="firstName"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              className={errors.firstName && touched.firstName ? "input-error form-control" : "form-control"}
                            />
                            {errors.firstName && touched.firstName && <p className="error place-order-title">{errors.firstName}</p>}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3 form-group">
                            <Label for="basicpill-email-input4" className="place-order-title">
                              Last Name <Label className="title-star">*</Label>
                            </Label>
                            <input
                              value={values.lastName}
                              onChange={handleChange}
                              id="lastName"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              className={errors.lastName && touched.lastName ? "input-error form-control" : "form-control"}
                            />
                            {errors.lastName && touched.lastName && <p className="error place-order-title">{errors.lastName}</p>}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          {editAddress ? (
                            <div className="mb-3 form-group">
                              <div className="d-flex flex-wrap align-items-center">
                                <Label for="basicpill-firstname-input1 input-title" className="place-order-title">
                                  Address <Label className="title-star">*</Label>
                                </Label>
                                <div className="label-wrap" onClick={toggleAddressInput}>
                                  <span className="custom-label">{"cancel"}</span>
                                </div>
                              </div>
                              <div>
                                <CustomGooglePlacesAutocomplete
                                  apiKey={environment.google_maps_key}
                                  autocompletionRequest={mapprops}
                                  addressType={AddressType.pickup}
                                  selectProps={{
                                    name: "address",
                                  }}
                                  handleAddress={handleAddress}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="mb-3 form-group">
                                <div className="d-flex flex-wrap align-items-center">
                                  <Label for="basicpill-firstname-input1 input-title" className="place-order-title">
                                    Address <Label className="title-star">*</Label>
                                  </Label>
                                  <div className="label-wrap" onClick={toggleAddressInput}>
                                    <span className="font-size-12 px-2 fw-medium">{"Edit address"}</span>
                                  </div>
                                </div>
                              </div>
                              <input
                                value={address?.city}
                                type="text"
                                disabled={true}
                                placeholder=""
                                className="form-control adress-input"
                              />
                              {address?.city === undefined ||
                                address?.city === "" ||
                                (address?.city === null && <p className="text-danger">You have to select a address.</p>)}
                            </>
                          )}
                        </Col>

                        <Col lg="6">
                          <div className="mb-3 form-group">
                            <Label for="basicpill-email-input4" className="place-order-title">
                              Email <Label className="title-star">*</Label>
                            </Label>
                            <input
                              value={values.email}
                              onChange={handleChange}
                              id="email"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              className={errors.email && touched.email ? "input-error form-control" : "form-control"}
                            />
                            {errors.email && touched.email && <p className="error place-order-title">{errors.email}</p>}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3 form-group">
                            <Label for="basicpill-phoneno-input3" className="place-order-title">
                              Phone Number <Label className="title-star">*</Label>
                            </Label>
                            <input
                              value={values.phoneNumber}
                              onChange={handleChange}
                              id="phoneNumber"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              className={errors.phoneNumber && touched.phoneNumber ? "input-error form-control" : "form-control"}
                            />
                            {errors.phoneNumber && touched.phoneNumber && <p className="error place-order-title">{errors.phoneNumber}</p>}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3 form-group">
                            <Label for="basicpill-email-input4" className="place-order-title">
                              Percentage <Label className="title-star">*</Label>
                            </Label>
                            <input
                              value={values.percentage}
                              onChange={handleChange}
                              id="percentage"
                              type="number"
                              placeholder=""
                              onBlur={handleBlur}
                              className={errors.percentage && touched.percentage ? "input-error form-control" : "form-control"}
                            />
                            {errors.percentage && touched.percentage && <p className="error place-order-title">{errors.percentage}</p>}
                          </div>
                        </Col>
                      </Row>
                      <div className="clearfix">
                        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
                          <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-sm px-5 me-lg-2 me-md-2 mb-2 ms-1">
                            Update
                          </button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateDriver;
