import axios from "axios";
import { Notes } from "models/Notes";
import { AppResponse } from "models/Response";
import { Util } from "Util";

export class NoteService {
  public static async createNote(note: Notes): Promise<AppResponse<string>> {
    const ep = Util.apiAuthUrl("note/create");
    const res = await axios.post<Notes, AppResponse<string>>(ep, note);
    return res;
  }

  public static async getNoteList(): Promise<AppResponse<Notes[]>> {
    const ep = Util.apiAuthUrl(`note/list`);
    const res = await axios.get<void, AppResponse<Notes[]>>(ep);
    return res;
  }

  public static async deleteNote(nodeId: string | undefined): Promise<AppResponse<Notes>> {
    const ep = Util.apiAuthUrl(`note/delete/` + nodeId);
    const res = await axios.delete<void, AppResponse<Notes>>(ep);
    return res;
  }
}
