import Typography from 'antd/es/typography';
import React from 'react';

function getCurrentYear() {
    return new Date().getFullYear();
}

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" style={{ fontSize: '12px', color: 'gray' }} {...props}>
            {'© ' + getCurrentYear() + ' Zulucourier'}
        </Typography>

    );
}

export default Copyright;