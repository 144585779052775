import TableContainer from "components/Common/TableContainer";
import { AddressBook } from "models/AddressBook";
import React, { useEffect, useMemo, useState } from "react";
import { Puff } from "react-loader-spinner";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AdminService } from "services/AdminService";

export interface AddressBookModalProps {
  isOpen: boolean;
  handleIsOpen: () => void;
  customerId?: string;
  getAddress: (value: AddressBook) => void;
  setIsChecked: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const AdminAddressBookModal: React.FC<AddressBookModalProps> = ({ isOpen, handleIsOpen, customerId, getAddress, setIsChecked }) => {

  const [addressList, setAddressList] = useState<AddressBook[]>([]);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<string>("");

  const data = {
    customerId: customerId
  }

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Company Address",
        accessor: "address.city",
      },
      {
        Header: "Contact Name",
        accessor: "address.contactName",
      },
      {
        Header: "Contact Phone",
        accessor: "address.contactPhone",
      },
      {
        Header: "Notes",
        accessor: "deliveryNote",
      },
    ],
    []
  )

  useEffect(() => {
    setIsloading(true);
    getAddresBookList();
  }, [isOpen]);

  const getAddresBookList = () => {
    AdminService.getCustomerAddressBooks(data).then(res => {
        if (res.success) {
          setAddressList(res.data);
        } else {
          setAddressList([]);
        }
        setIsloading(false);
      })
      .catch(err => {
        setIsloading(false);
        console.error(err);
      });
  }

  const handleCheck = (value: string) => {
    setSelectedId(value);
  };

  const submitAddress = () => {
    if(addressList.length > 0){
      const aBook = addressList.filter((a: AddressBook) => {
        return selectedId === a._id;
      });
      getAddress(aBook[0]);
    }
    setSelectedId("")
    getAddresBookList();
    handleIsOpen();
  };

  const handleClose = () => {
    setIsChecked(false)
    setSelectedId("")
    getAddresBookList();
    handleIsOpen()
  }

  const closeBtn = (
    <button onClick={handleClose} type="button" className="btn-close"/>
  );

  return (
    <React.Fragment>
      {isloading &&<div className="d-flex justify-content-center align-items-center " style={{ height: "500px" }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>}
      <Modal className="modal-content" isOpen={isOpen} toggle={handleIsOpen} size="xl">
        <ModalHeader className="text-center" toggle={handleIsOpen} close={closeBtn}>Customer Saved Addresses</ModalHeader>
        <ModalBody className="overflow-auto">
          <div className="d-flex flex-wrap px-5">
            {addressList.length > 0 ?  (
              <TableContainer
                columns={columns}
                data={addressList}
                currentPage={undefined}
                pagelimit={undefined}
                handlePageChange={undefined}
                handleColumnChange={undefined}
                selectedColumns={undefined}
                showColumnChange={false}
                handleSearch ={undefined}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-table"
                isJobListGlobalFilter={undefined}
                isAddUserList={undefined}
                handleOrderClicks={undefined}
                handleUserClick={undefined}
                handleCustomerClick={undefined}
                isAddCustList={undefined}
                handleSendEmail={undefined}
                isPDFGenerate={false}
                ispopup={false}
                isAddressBook={false}
                hancleDeleteRecords={undefined}
                handleSelect={handleCheck}
                selectedID={selectedId}
                hasCheckBox={false}
              />
            ): (
              <div className="text-center text-muted">Customer has not added contacts as of yet.</div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {selectedId ==""?
            <div className="d-flex flex-row flex-wrap justify-content-end">
              <button className="btn btn-primary btn-sm px-3"  onClick={handleIsOpen}>Go Back</button>
            </div>:
            <div className="d-flex flex-row flex-wrap justify-content-end">
              <button className="btn btn-primary btn-sm px-3"  onClick={submitAddress}>Submit</button>
            </div>
          }
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AdminAddressBookModal;