import * as yup from "yup"


const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;

export const DriverSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  firstName: yup.string().required("Required"),
  phoneNumber: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number").required("Required"),
  lastName: yup.string().required("Required"),
  percentage: yup.number()
      .typeError('Invalid percentage')
      .required('Percentage is required')
      .min(0, 'Percentage must be greater than or equal to 0')
      .max(100, 'Percentage must be less than or equal to 100'),
}
)