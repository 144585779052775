import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "../../Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.svg"
import { useAuth } from "context/AuthProvider"
import { useImage } from "context/ProfileImageProvider"
import { UserTypes } from "enum/UserTypes"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [user, setUser, logout] = useAuth()
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [image] = useImage()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (process.env.REACT_APP_DEFAULTAUTH === "fake" || process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          {image ? (
            <img src={image.dataURL} className="rounded-circle header-profile-user" alt="Profile Image"></img> 
          ) : (
            <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />
          )}
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.firstName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item my-2" to={`/${user.role}/profile-settings`} onClick={() => setMenu(!menu)}>
            <i className="bx bx-user font-size-16 align-middle me-1 text-danger me-2" />
            <span>{props.t("Profile Settings")}</span>
          </Link>

          <Link className="dropdown-item my-2" to={`/${user.role}/change-password`} onClick={() => setMenu(!menu)}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1 text-danger me-2" />
            <span>{props.t("Change Password")}</span>
          </Link>
          {user.role== UserTypes.SUPER_ADMIN &&
          <Link className="dropdown-item my-2" to={`/${user.role}/dispach-user`} onClick={() => setMenu(!menu)}>
            <i className="bx bx-wrench font-size-16 align-middle me-1 text-danger me-2" />
            <span>{props.t("Add Dispatch User")}</span>
          </Link> }

          <div className="dropdown-divider" />
          <Link className="dropdown-item" onClick={() => logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger me-2" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)))
