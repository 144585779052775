import * as yup from "yup"


const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;

export const DispatchSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  firstName: yup.string().required("Required"),
  phoneNumber: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number").required("Required"),
  lastName: yup.string().required("Required"),
  createPassword: yup.string().min(6, "Password should be atleast 6 characters long").required("Required"),
  confirmPassword: yup.string().oneOf([yup.ref('createPassword'), null], "Password should match.").min(6, "Password should be atleast 6 characters long").required("Required"),
}
)