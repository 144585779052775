import { NotificationResponse } from "models/Notification";
import React, { useContext, useState } from "react";

export type NotificationData = [number | undefined, (notification: number) => void,]

const NotificationContext = React.createContext<NotificationData>([
  undefined,
  () => {}
])

export const NotificationProvider = ({ children }: any) => {
  const [notification, setNotification] = useState<number | undefined>(undefined);
  return <NotificationContext.Provider value={[notification, setNotification]}>{children}</NotificationContext.Provider>
}

export const useNotification = () => {
  return useContext(NotificationContext);
}