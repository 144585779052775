export const getTimeAgo = timestamp => {
  const now = Date.now();
  const updatedAt = new Date(timestamp);
  const diff = now - updatedAt.getTime();

  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
};

export const formatTime = timestamp => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12; // Convert to 12-hour format
  minutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed

  const formattedTime = `${hours}:${minutes}${period}`;
  return formattedTime;
};
