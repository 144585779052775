import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import addHours from 'date-fns/addHours';
import { addMinutes, isBefore, startOfDay, isSameDay } from 'date-fns';
// import { DatePicker } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';
// import {FaClock} from 'react-icons/fa';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface ModalProps {
    isOpen: boolean;
    toggle: () => void;
    onHandleDateChange: (date: Date | null) => void;
    onHandleStartTimeChange: (time: Date | null) => void;
    onHandleEndTimeChange: (time: Date | null) => void;
}

function SetDateAndTime(props: ModalProps) {
    // const minDate = startOfDay(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);
    const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null);
    const {
        isOpen,
        toggle,
        onHandleDateChange,
        onHandleStartTimeChange,
        onHandleEndTimeChange,
    } = props;

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        onHandleDateChange(date);
    };

    const handleStartTimeChange = (time: Date | null) => {
        setSelectedStartTime(time);
        onHandleStartTimeChange(time);
        // Ensure end time is at least 1 Minuite after start time
        if (time) {
            setSelectedEndTime(addMinutes(time, 1));
            onHandleEndTimeChange(addMinutes(time, 1));
        }
    };

    const handleEndTimeChange = (time: Date | null) => {
        // Ensure end time is after start time
        if (selectedStartTime && time && time <= selectedStartTime) {
            return;
        }
        setSelectedEndTime(time);
        onHandleEndTimeChange(time);
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} size='sm'>
                <ModalHeader toggle={toggle}>
                    <div className="d-flex align-items-center">
                        <div style={{
                            whiteSpace: 'nowrap', fontSize: '20px'
                        }}>
                            Set Date & Time
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-column align-items-center">
                        <DatePicker
                            onFocus={(e) => e.target.blur()}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="MMMM d, yyyy"
                            inline
                            className="form-control"
                        />

                        <div className="d-flex align-items-center mt-3">
                        <div className="w-48 me-1">
                        <DatePicker
                                    onFocus={(e) => e.target.blur()}
                                    selected={selectedStartTime}
                                    onChange={handleStartTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption="Start Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Start time"
                                    minTime={selectedStartTime || new Date()}
                                    maxTime={addHours(new Date(), 23)}
                                    className="form-control"
                                />

                        </div>
                        <span className="me-1"> - </span>
                        <div className="w-16 me-1">
                        <div className="w-48 ms-1">
                        <DatePicker
                                    onFocus={(e) => e.target.blur()}
                                    selected={selectedEndTime}
                                    onChange={handleEndTimeChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption="End Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="End time"
                                    minTime={selectedStartTime || new Date()}
                                    maxTime={addHours(new Date(), 23)}
                                    className="form-control"
                                />

                        </div>
                        </div>
                       
                        </div> 
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={toggle}
                        size="sm"
                    >Set Date & Time
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SetDateAndTime;
