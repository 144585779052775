// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Card, CardBody, Row, Label } from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import { BCustomerInvoice } from "models/Invoice";
import moment from "moment";
import { InvoiceService } from "services/InvoiceService";
import InvoiceView from "./InvoiceView";
import { OrderResponse } from "models/orderResponse";
import { OrderService } from "services/OrderService";
import { toast } from "react-toastify";
import { PaymentType } from "enum/PaymentOption";
import { format, parseISO } from "date-fns";

const Invoices: React.FC = () => {
  document.title = "Invoices | Zulu Courier ";
  const [startDate, setStartDate] = useState(moment.tz("America/Toronto").startOf("month"));
  const [endDate, setEndDate] = useState(moment.tz("America/Toronto"));
  const [invoices, setInvoices] = useState([] as BCustomerInvoice[]);
  const [showTable, setShowTable] = useState(false);
  const [invoice, setInvoice] = useState<BCustomerInvoice>();
  const [invoiceDate, setInvoiceDate] = useState({
    index: "",
    customerName: "",
    customerId: "",
    totalOrders: 0,
    totalAmount: 0,
    totalhst: 0,
    totalsubTotal: 0,
    discount: 0,
    outstandingBalance: 0,
  });
  const [orders, setOrders] = useState([] as OrderResponse[]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const [LIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [modal, setModal] = useState(false);

  const data = {
    startDate: startDate,
    endDate: endDate,
  };

  useEffect(() => {
    getCustomerInvoice();
  }, []);

  // const handleStartDate = (date: Date) => {
  //   const sDate = moment(date);
  //   setStartDate(sDate);
  // };

  // const handleEndDate = (date: Date) => {
  //   const eDate = moment(date);
  //   setEndDate(eDate);
  // };

  // const handleFilter = () => {
  //   getCustomerInvoice();
  // };

  const getStripeSession = (values: any) => {
    const orderId = values.original._id;

    OrderService.createStripeSession({ orderIdList: [orderId], paymentType: PaymentType.INVOICE })
      .then(res => {
        if (res.success) {
          window.location.href = res.data;
        } else {
          return toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(err => {
        console.error(err.message);
      });
  };

  const getCustomerInvoice = async () => {
    await InvoiceService.getBusinessCustomerInvoices(LIMIT_P_C, isOffset_p_c)
      .then(res => {
        if (res.success) {
          setInvoices(res.data.userInvoices);
          setShowTable(true);
          getTotalPayment(res.data.userInvoices);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    InvoiceService.getBusinessCustomerInvoices(LIMIT_P_C, page.selected + 1)
      .then(res => {
        if (res.success) {
          setInvoices(res.data.userInvoices);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getTotalPayment = (data: BCustomerInvoice[]) => {
    const calculatedTotalPayment = data.reduce((sum, invoice) => {
      if (invoice.paymentStatus !== "paid") {
        const amountToAdd = invoice.isAdminUpdated ? invoice.outstandingBalance : invoice.totalAmount;
        return sum + (amountToAdd ?? 0);
      }
      return sum;
    }, 0);

    const roundedTotalPayment = calculatedTotalPayment.toFixed(2);
    const roundedTotalPaymentNumber = parseFloat(roundedTotalPayment);
    setTotalPayment(roundedTotalPaymentNumber);
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleSelectedInvoices = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;

    if (isSelected) {
      // When selecting, add the ID to the selectedInvoices state
      setSelectedInvoices(previousSelectedInvoices => [...previousSelectedInvoices, id]);
    } else {
      // When deselecting, remove the ID from the selectedInvoices state
      setSelectedInvoices(previousSelectedInvoices => previousSelectedInvoices.filter(selectedId => selectedId !== id));
    }
  };

  const PaySelectedInvoicesHandler = () => {
    if (selectedInvoices.length <= 0) {
      return toast.error("Invoices not selected", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    }
    OrderService.createStripeSession({ orderIdList: selectedInvoices, paymentType: PaymentType.INVOICE })
      .then(res => {
        if (res.success) {
          window.location.href = res.data;
        } else {
          return toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(err => {
        console.error(err.message);
      });
  };

  const handleViewInvoice = (values: any) => {
    InvoiceService.getBcustomerInvoiceById(values.original?._id).then(res => {
      if (res.success) {
        setInvoice(res.data);
      }
    });

    setInvoiceDate({
      index: values.index,
      customerName: values.original?.firstName + " " + values.original?.lastName,
      totalOrders: values.original?.totalOrders,
      totalAmount: values.original?.totalAmount,
      customerId: values.original?.customerId,
      totalhst: values.original?.totalhst,
      totalsubTotal: values.original?.totalsubTotal,
      discount: values.original?.discount,
      outstandingBalance: values.original?.outstandingBalance,
    });

    values.original?.orders.map((order: any) => {
      setOrders(order);
    });
    handlePopup();
  };

  const searchTable = (searchText: string) => {
    const searchdata = {
      // startDate: startDate,
      // endDate: endDate,
      searchableString: searchText,
    };

    InvoiceService.getBusinessCustomerInvoicesBySearch(searchdata).then(res => {
      if (res.success) {
        if (res?.data?.userInvoices?.length !== 0) {
          setInvoices(res.data.userInvoices);
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "select",
        Header: "",
        Cell: ({ row }: any) => {
          return (
            <div className="form-check d-flex justify-content-center">
              {row.original.paymentStatus === "paid" ? (
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled
                  name={row.original._id}
                  // checked={selectedInvoices.includes(row.original._id)}
                  value=""
                  id={`checkbox-${row.id}`}
                  onChange={event => handleSelectedInvoices(row.original._id, event)}
                />
              ) : (
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={row.original._id}
                  // checked={selectedInvoices.includes(row.original._id)}
                  value=""
                  id={`checkbox-${row.id}`}
                  onChange={event => handleSelectedInvoices(row.original._id, event)}
                />
              )}
            </div>
          );
        },
      },
      {
        id: "invoiceId",
        Header: "Invoice Number",
        accessor: "invoiceId",
        Cell: ({ row }: any) => {
          return row.original.invoiceId;
        },
        disableSortBy: true,
        indexed: true,
      },
      {
        id: "date",
        Header: "Billing Period",
        accessor: "date",
        Cell: ({ row }: any) => {
          // const createdAtString = row.original.startDate;
          const createdAtString = row.original.createdAt;
          const startOfMonth = moment(createdAtString).startOf('month').format();
          // const updatedAtString = row.original.endDate;
          const endOfMonth = moment(createdAtString).endOf('month').format();
          // const createdDate = format(parseISO(createdAtString), "MMM do");
          const createdDate = format(parseISO(startOfMonth), "MMM do");
          // const updatedDate = format(parseISO(updatedAtString), "MMM do");
          const updatedDate = format(parseISO(endOfMonth), "MMM do");
          return `${createdDate} - ${updatedDate}`;
        },
      },
      // {
      //   id: "totalOrders",
      //   Header: "Total Number of Orders",
      //   accessor: "totalOrders",
      // },
      {
        id: "totalAmount",
        Header: "Amount",
        accessor: "totalAmount",
        // Cell: ({ value }) => `$${value.toFixed(2)}`,
        Cell: ({ row }: any) => {
          const amount = row.original.isAdminUpdated ? row.original.outstandingBalance : row.original.totalAmount;
          return `$${amount.toFixed(2)}`;
        },
      },
      {
        id: "action",
        Header: "",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button className="btn btn-sm btn btn-outline-primary" onClick={() => handleViewInvoice(row)}>
                View
              </button>
              {row.original.paymentStatus === "paid" ? (
                <button
                  className="btn btn-sm btn btn-primary m-2 disabled"
                  // onClick={() => getStripeSession(row)}
                >
                  Paid
                </button>
              ) : (
                <button className="btn btn-sm btn btn-primary m-2" onClick={() => getStripeSession(row)}>
                  Pay
                </button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Invoice" breadcrumbItem="All Invoices" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center mb-2 w-md-50 justify-content-between ">
                      <p className="m-0 me-2">
                        Total Amount Due: <span className="fw-semibold">$ {totalPayment.toFixed(2)}</span>
                      </p>
                      <button onClick={PaySelectedInvoicesHandler} className="btn btn-sm btn-primary mx-2">
                        Pay All Selected Invoices
                      </button>
                    </div>
                  </div>
                  {showTable && (
                    <div className="wizard clearfix">
                      <Label>Billing Table</Label>
                      <Row>
                        <Col lg="12">
                          {invoices.length > 0 ? (
                            <TableContainer
                              columns={columns}
                              data={invoices}
                              currentPage={undefined}
                              pagelimit={pageCount}
                              handlePageChange={SeeMore}
                              handleColumnChange={undefined}
                              selectedColumns={undefined}
                              showColumnChange={false}
                              handleSearch={searchTable}
                              isGlobalFilter={true}
                              isAddOptions={false}
                              customPageSize={10}
                              className="custom-header-css"
                              isJobListGlobalFilter={undefined}
                              isAddUserList={undefined}
                              handleOrderClicks={undefined}
                              handleUserClick={undefined}
                              handleCustomerClick={undefined}
                              isAddCustList={undefined}
                              handleSendEmail={undefined}
                              isPDFGenerate={false}
                              isAddressBook={false}
                              ispopup={false}
                              hancleDeleteRecords={undefined}
                              handleSelect={undefined}
                              selectedID={undefined}
                              hasCheckBox={false}
                            />
                          ) : (
                            <Row className="justify-content-md-center justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div style={{ height: "100px" }}>No Billings</div>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <InvoiceView isOpen={modal} handlePopup={handlePopup} invoice={invoice} invoiceDate={invoiceDate} orders={orders} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invoices;
