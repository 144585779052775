export enum PaymentOption {
  SINGLE = "single",
  MONTHLY = "monthly",
}

export enum PaymentType {
  ORDER = "order",
  INVOICE = "invoice",
  ADMINORDER ="admin_order"
}
