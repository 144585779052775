import { AuthData, useAuth } from "context/AuthProvider";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import { OrderService } from "services/OrderService";
import successImg from "../../assets/images/payments/success.png";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user] = useAuth();
  const [orderId, setOrderId] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const pathnameSegments = location.pathname.split("/");
    const orderId = pathnameSegments[pathnameSegments.length - 1];

    setOrderId(orderId);
  }, [location]);

  const generateOrderPdf = () => {
    setButtonDisabled(true);
    OrderService.genarateOrderReceiptPDF(orderId).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };
  return (
    <React.Fragment>
      <div className="payment-card-wrapper">
        <div className="p-3">
          <Card className="payment-card">
            <div className="card-header">
              <Row>
                <div className="image-container">
                  <img src={successImg} className="image" />
                </div>
              </Row>
            </div>
            <CardBody className="px-5 py-3">
              <Row className="mb-3 mt-5">
                <h4 className="text-muted text-center">Payment Successful</h4>
                <p className="text-muted text-center font-size-16">Your payment has been accepted.</p>
              </Row>
              <Row className="mb-3">
                <div class="container">
                  <div class="row">
                    <div class="col-6 d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        style={{ backgroundColor: "#34C38F" }}
                        onClick={() => navigate(`/${user?.role}/place-orders`)}
                      >
                        {" "}
                        Go back
                      </button>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <button className="btn btn-primary" onClick={() => generateOrderPdf()} disabled={isButtonDisabled}>
                        Download PDF
                      </button>
                    </div>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="d-flex justify-content-center">© 2023 ZuluCourier.</div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSuccess;
