import { Radio, TimePicker } from "antd";
import { UserTypes } from "enum/UserTypes";
import { environment } from "environment/environment";
import { AddressModel } from "models/City";
import { User } from "models/User";
import React, { useEffect, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { Card, CardBody, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { AdminService } from "services/AdminService";
import "../../assets/css/custom.css";
import { OperatingHours } from "models/OperatingHour";
import { OpenCloseToggle } from "./ToggleSwitch";
import dayjs from "dayjs";
import { AddressType } from "enum/AddressType";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { Image } from "models/Upload";
import { AuthService } from "services/AuthService";

export interface CustomerViewProps {
  isOpen: boolean;
  handlePopup: () => void;
  customer: User | undefined;
  getAllCustomers: () => void;
}

const getInitials = (name: any) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

const createImageFromInitials = (size: number, name: any, color: any) => {
  if (name == null) return;
  name = getInitials(name);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context!.fillStyle = color;
  context!.fillRect(0, 0, size, size);

  context!.fillStyle = "#ffffff";
  context!.textBaseline = "middle";
  context!.textAlign = "center";
  context!.font = `${size / 2}px Roboto`;
  context!.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

export interface operatingHoursProps {
  day: string;
  OP: OperatingHours;
  setOP: (value: OperatingHours) => void;
}

export const OperatingHour: React.FC<operatingHoursProps> = ({ day, OP, setOP }) => {
  const format = "hh:mm A";
  let beginTime: string;
  let endTime: string;

  useEffect(() => {
    OP?.begin === "" || OP?.begin === undefined || OP?.begin === null ? (beginTime = "08:00 AM") : (beginTime = OP?.begin);
    OP?.end === "" || OP?.end === undefined || OP?.end === null ? (endTime = "05:00 PM") : (endTime = OP?.end);

    setOP({ ...OP, day: day, begin: beginTime, end: endTime });
  }, []);

  return (
    <Row className="mb-lg-0 mb-md-0 mb-3">
      <Col lg="2" md="2">
        <Label>{day}</Label>
      </Col>
      <Col lg="1" md="1" className="my-lg-1 mx-lg-3">
        <OpenCloseToggle isOpen={OP?.isOperating} OP={OP} setOP={setOP} />
      </Col>
      <Col lg="1" md="1">
        <Label>{OP?.isOperating ? "Open" : "Close"}</Label>
      </Col>
      {OP?.isOperating && (
        <Col lg="7" md="7" className="ms-lg-4">
          <TimePicker
            defaultValue={dayjs(OP.begin, format)}
            format={format}
            className="me-lg-2"
            onChange={(value, dateString) => {
              setOP({ ...OP, begin: dateString });
            }}
          />
          <Label>To</Label>
          <TimePicker
            value={dayjs(OP.end, format)}
            format={format}
            className="ms-lg-2"
            onChange={(value, dateString) => {
              setOP({ ...OP, end: dateString });
            }}
          />
        </Col>
      )}
    </Row>
  );
};

const CustomerView: React.FC<CustomerViewProps> = ({ isOpen, handlePopup, customer, getAllCustomers }) => {
  const [imgSrc, setImgSrc] = useState<Image>();
  const [error, setError] = useState<User>();
  const [address, setAddress] = useState<AddressModel>();
  const [addressError, setAddressError] = useState<AddressModel>();
  const [geoAddress, setGeoAddress] = useState(false);

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [isABusiness, setIsABusiness] = useState<boolean | undefined>();
  const [role, setRole] = useState<UserTypes | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [invoiceEmail, setInvoiceEmail] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>();

  const [sundayOP, setSundayOP] = useState<OperatingHours>({ day: "Sunday", begin: "", end: "", isOperating: false });
  const [mondayOP, setMondayOP] = useState<OperatingHours>({ day: "Monday", begin: "", end: "", isOperating: false });
  const [tuesdayOP, setTuesdayOP] = useState<OperatingHours>({ day: "Tuesday", begin: "", end: "", isOperating: false });
  const [wednesdayOP, setWednesdayOP] = useState<OperatingHours>({ day: "Wednesday", begin: "", end: "", isOperating: false });
  const [thursdayOP, setThursdayOP] = useState<OperatingHours>({ day: "Thursday", begin: "", end: "", isOperating: false });
  const [fridayOP, setFridayOP] = useState<OperatingHours>({ day: "Friday", begin: "", end: "", isOperating: false });
  const [saturdayOP, setSaturdayOP] = useState<OperatingHours>({ day: "Saturday", begin: "", end: "", isOperating: false });

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmErr, setConfirmErr] = useState<string>();
  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };
  useEffect(() => {
    customer?.operatingHours?.map(oh => {
      oh.day === "Sunday"
        ? setSundayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Monday"
        ? setMondayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Tuesday"
        ? setTuesdayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Wednesday"
        ? setWednesdayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Thursday"
        ? setThursdayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Friday"
        ? setFridayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : oh.day === "Saturday"
        ? setSaturdayOP({ day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating })
        : "";
    });

    setFirstName(customer?.firstName);
    setLastName(customer?.lastName);
    setEmail(customer?.email);
    setPhoneNumber(customer?.phoneNumber);
    setIsABusiness(customer?.isABusiness);
    setRole(customer?.role);
    setInvoiceEmail(customer?.invoiceEmail);
    setCity(customer?.address?.city);
    setCompanyName(customer?.address?.companyName);
    setAddress(customer?.address);

    AuthService.getProfileImage(customer?.photoId).then(res => {
      if (res.success !== false) {
        setImgSrc({ dataURL: `data:image/*;base64,${res}` });
      }
    });
  }, [customer]);

  const handleEdit = (event: any) => {
    event.preventDefault();
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "firstName":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, firstName: "Required" });
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setError({ ...error, firstName: "Only letters are allowed!" });
        } else {
          setError({ ...error, firstName: undefined });
        }
        setFirstName(value);
        setAddress({ ...address, contactName: value + " " + lastName });
        break;
      case "lastName":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, lastName: "Required" });
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setError({ ...error, lastName: "Only letters are allowed!" });
        } else {
          setError({ ...error, lastName: undefined });
        }
        setLastName(value);
        setAddress({ ...address, contactName: firstName + " " + value });
        break;
      case "email":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, email: "Required" });
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          setError({ ...error, email: "Invalid email" });
        } else {
          setError({ ...error, email: undefined });
        }
        setEmail(value);
        break;
      case "address":
        if (value != "" || value == undefined) {
          setGeoAddress(true);
        }
        break;
      case "phoneNumber":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, phoneNumber: "Required" });
        } else if (!/^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/.test(value)) {
          setError({ ...error, phoneNumber: "Invalid Canadian phone number" });
        } else {
          setError({ ...error, phoneNumber: undefined });
        }
        setPhoneNumber(value);
        setAddress({ ...address, contactPhone: value });
        break;
      case "isBusiness":
        setIsABusiness(value);
        if (value == true) {
          setRole(UserTypes.BUSINESS_CUSTOMER);
        } else {
          setRole(UserTypes.PERSONAL_CUSTOMER);
        }
        break;
      case "companyName":
        if (value == "" || value == undefined || value == null) {
          setAddressError({ ...error, companyName: "Required" });
        } else {
          setAddressError({ ...error, companyName: undefined });
        }
        setCompanyName(value);
        setAddress({ ...address, companyName: value });
        break;
      case "invoiceEmail":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, invoiceEmail: "Required" });
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          setError({ ...error, invoiceEmail: "Invalid invoice email" });
        } else {
          setError({ ...error, invoiceEmail: undefined });
        }
        setInvoiceEmail(value);
        break;
      default:
        break;
    }
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          setAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleUpdate = (event: any) => {
    event.preventDefault();

    if (city == "" || city == undefined) {
      setAddressError({ ...addressError, city: "Required" });
    } else if (isABusiness && (companyName == "" || companyName == undefined)) {
      setAddressError({ ...addressError, companyName: "Required" });
    } else if (invoiceEmail == "" || invoiceEmail == undefined) {
      setError({ ...error, invoiceEmail: "Required" });
    } else {
      const customerData: User = {
        _id: customer?._id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        address: address,
        phoneNumber: phoneNumber,
        isABusiness: isABusiness,
        role: role,
        invoiceEmail: invoiceEmail,
      };

      AdminService.updateCustomer(customerData)
        .then(res => {
          if (res.success) {
            toast.success("Successfully updated Customer Details.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          } else {
            toast.error("error occured", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleSetHours = () => {
    const customerData: User = {
      _id: customer?._id,
      operatingHours: [sundayOP, mondayOP, tuesdayOP, wednesdayOP, thursdayOP, fridayOP, saturdayOP],
    };

    AdminService.updateBusinessCustomer(customerData)
      .then(res => {
        if (res.success) {
          toast.success("Successfully updated Business Customer Operating Hours.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          toast.error("error occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handlePassword = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "newPassword":
        if (value == "" || value == null || value == undefined) {
          setPasswordErr("Required");
        } else if (value.length < 6) {
          setPasswordErr("Password should be atleast 6 characters long.");
        } else {
          setPasswordErr(undefined);
        }
        setNewPassword(value);
        break;
      case "confirmPassword":
        if (value == "" || value == null || value == undefined) {
          setConfirmErr("Required");
        } else if (newPassword !== value) {
          setConfirmErr("Password should match.");
        } else {
          setConfirmErr(undefined);
        }
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const updatePassword = () => {
    const customerData: User = {
      _id: customer?._id,
      password: newPassword,
    };

    AdminService.updateCustomerData(customerData)
      .then(res => {
        if (res.success) {
          setNewPassword("");
          setConfirmPassword("");
          toast.success("Successfully updated Customer Password.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          toast.error("error occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const clearAddress = () => {
    setGeoAddress(true);
    setCity(undefined);
  };

  const handleClose = () => {
    setGeoAddress(false);
    setError(undefined);
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setPhoneNumber(undefined);
    setIsABusiness(undefined);
    setRole(undefined);
    setInvoiceEmail(undefined);
    setCity(undefined);
    setCompanyName(undefined);
    setAddress(undefined);
    setImgSrc(undefined);

    setSundayOP({});
    setMondayOP({});
    setTuesdayOP({});
    setWednesdayOP({});
    setThursdayOP({});
    setFridayOP({});
    setSaturdayOP({});

    getAllCustomers();
    handlePopup();
  };

  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="xl" backdrop="static" style={{ maxWidth: "1300px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}>
          Manage Customer
        </ModalHeader>
        <ModalBody className="bg-body">
          <Row>
            <Col lg="4">
              <Card>
                <CardBody>
                  <h6>
                    {role === UserTypes.BUSINESS_CUSTOMER
                      ? "Business Customer"
                      : role === UserTypes.PERSONAL_CUSTOMER
                      ? "Personal Customer"
                      : role === UserTypes.DRIVER
                      ? "Driver"
                      : "Dispatch"}
                  </h6>
                  <div className="content clearfix">
                    <Row>
                      <Col lg="2">
                        <div className="text-center">
                          <img
                            className="rounded-circle header-profile-user"
                            id="preview"
                            src={imgSrc?.dataURL !== undefined ?
                              imgSrc?.dataURL :
                              createImageFromInitials(40, customer?.firstName, "#ff115a")}
                            alt="profile-pic"
                          />
                        </div>
                      </Col>
                      <Col lg="10">
                        <Label className="text-primary fs-6">
                          {customer?.firstName} {customer?.lastName}
                        </Label>
                        <Row>
                          <div>
                            <Label className="me-2">Personal Details</Label>
                            {/* <button
                              className="btn btn-outline-success btn-sm px-3 bx bx-edit-alt rounded-5 w-25 py-0"
                              style={{fontSize: "15px"}}
                              onClick={handleEdit}
                            >
                              Edit
                            </button> */}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>First Name</Label>
                            <input
                              type="text"
                              name="firstName"
                              value={firstName}
                              onChange={handleChange}
                              className={error?.firstName ? "input-error form-control" : "form-control"}
                            />
                            {error?.firstName !== undefined && <p className="error col-lg-11">{error.firstName}</p>}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>Last Name</Label>
                            <input
                              type="text"
                              name="lastName"
                              value={lastName}
                              onChange={handleChange}
                              className={error?.lastName ? "input-error form-control" : "form-control"}
                            />
                            {error?.lastName !== undefined && <p className="error col-lg-11">{error.lastName}</p>}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>Email</Label>
                            <input
                              type="text"
                              name="email"
                              value={email}
                              onChange={handleChange}
                              className={error?.email ? "input-error form-control" : "form-control"}
                              readOnly
                            />
                            {error?.email !== undefined && <p className="error col-lg-11">{error.email}</p>}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>Permanent Address</Label>
                            {geoAddress || city == undefined ? (
                              <CustomGooglePlacesAutocomplete
                                apiKey={environment.google_maps_key}
                                autocompletionRequest={mapprops}
                                addressType={AddressType.pickup}
                                selectProps={{
                                  name: "address",
                                }}
                                handleAddress={handleAddress}
                              />
                            ) : (
                              <input
                                type="text"
                                name="address"
                                value={city}
                                //onMouseEnter={handleChange}
                                onSelect={() => clearAddress()}
                                className={addressError?.city ? "input-error form-control" : "form-control"}
                              />
                            )}
                            {addressError?.city && <p className="error col-lg-5 place-order-title">{addressError.city}</p>}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>Phone Number</Label>
                            <input
                              type="text"
                              name="phoneNumber"
                              value={phoneNumber}
                              onChange={handleChange}
                              className={error?.phoneNumber ? "input-error form-control" : "form-control"}
                            />
                            {error?.phoneNumber !== undefined && <p className="error col-lg-11">{error.phoneNumber}</p>}
                          </div>
                        </Row>
                        <Row>
                          <div className="mb-3 form-group">
                            <Label className="me-2">Are You A Business?</Label>
                            <Radio.Group value={isABusiness} onChange={handleChange} name="isBusiness">
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </div>
                        </Row>
                        {isABusiness && (
                          <Row>
                            <div className="mb-3 form-group">
                              <Label>Business Name</Label>
                              <input
                                type="text"
                                name="companyName"
                                value={companyName}
                                onChange={handleChange}
                                className={addressError?.companyName ? "input-error form-control" : "form-control"}
                              />
                              {addressError?.companyName !== undefined && <p className="error col-lg-11">{addressError?.companyName}</p>}
                            </div>
                          </Row>
                        )}
                        <Row>
                          <div className="mb-3 form-group">
                            <Label>Invoice Email</Label>
                            <input
                              type="text"
                              name="invoiceEmail"
                              value={invoiceEmail}
                              onChange={handleChange}
                              className={error?.invoiceEmail ? "input-error form-control" : "form-control"}
                            />
                            {error?.invoiceEmail !== undefined && <p className="error col-lg-11">{error.invoiceEmail}</p>}
                          </div>
                        </Row>
                        <Row>
                          <button className="btn btn-primary btn-sm px-3 rounded-1 w-100" onClick={handleUpdate}>
                            Update changes
                          </button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Card>
                <CardBody className="mx-lg-5">
                  <h6>Notes</h6>
                  <ul>
                    <li className="custom-li">This is first note.</li>
                  </ul>
                </CardBody>
              </Card>

              {customer?.isABusiness && (
                <Card>
                  <CardBody className="mx-lg-5">
                    <h6>Hours of Operation</h6>
                    <div className="content clearfix mx-lg-4">
                      <OperatingHour day="Sunday" OP={sundayOP} setOP={setSundayOP} />
                      <OperatingHour day="Monday" OP={mondayOP} setOP={setMondayOP} />
                      <OperatingHour day="Tuesday" OP={tuesdayOP} setOP={setTuesdayOP} />
                      <OperatingHour day="Wednesday" OP={wednesdayOP} setOP={setWednesdayOP} />
                      <OperatingHour day="Thursday" OP={thursdayOP} setOP={setThursdayOP} />
                      <OperatingHour day="Friday" OP={fridayOP} setOP={setFridayOP} />
                      <OperatingHour day="Saturday" OP={saturdayOP} setOP={setSaturdayOP} />

                      <Row className="justify-content-end">
                        <Col className="col-md-auto">
                          <div className="d-flex gap-1">
                            {/* <button
                              className="btn btn-primary btn-sm px-3 me-2"
                              //onClick={}
                            >
                              Cancel
                            </button> */}
                            <button className="btn btn-primary btn-sm px-3" onClick={handleSetHours}>
                              Set Hours
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}

              <Card>
                <CardBody className="mx-lg-5">
                  <h6>Change Password</h6>
                  <Row>
                    <Col lg="5">
                      <div className="mb-3 form-group">
                        <Label>New Password</Label>
                        <input
                          type="password"
                          name="newPassword"
                          value={newPassword}
                          onChange={handlePassword}
                          className={passwordErr ? "input-error form-control" : "form-control"}
                        />
                        {passwordErr && <p className="error col-lg-12">{passwordErr}</p>}
                      </div>
                    </Col>
                    <Col lg="5">
                      <div className="mb-3 form-group">
                        <Label>Confirm Password</Label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={handlePassword}
                          className={confirmErr ? "input-error form-control" : "form-control"}
                        />
                        {confirmErr && <p className="error col-lg-12">{confirmErr}</p>}
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="mt-lg-4">
                        <button className="btn btn-primary btn-sm px-3 mt-lg-3 w-100" onClick={updatePassword}>
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerView;
