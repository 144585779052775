import { OperatingHours } from "models/OperatingHour";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";

export interface OpenCloseProps {
  isOpen: boolean | undefined;
  OP: OperatingHours;
  setOP: (value: OperatingHours) => void;
}

export const OpenCloseToggle: React.FC<OpenCloseProps> = ({ isOpen, OP, setOP }: OpenCloseProps) => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  });

  const handleChange = (val: boolean) => {
    setChecked(val);
    setOP({ ...OP, isOperating: val });
  };

  return (
    <div className="app text-start">
      <Switch
        checked={checked}
        onChange={handleChange}
        onColor="#F75D21"
        width={50}
        height={20}
        handleDiameter={15}
        checkedIcon={<></>}
        uncheckedIcon={<></>}
      />
    </div>
  );
};
