import { ImageIcon } from "assets/images/svg-images/SvgImages";
import { DispatchType } from "enum/DispatchType";
import { OrderStatus } from "enum/OrderStatus";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Table, FormGroup, Label, Input } from "reactstrap";

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSelectedStatusChange: (orderId: string | undefined) => void;
  currentStatus: OrderStatus | DispatchType;
  handleStatusAssign: (droppedStatusData: {
    imageFile?: File | null;
    receiverName?: string;
    notes?: string;
    date?: string;
    time?: string;
    status?: string;
  }) => void;
}

function UpdateStatus(props: ModalProps) {
  //   const statusList = ["Picked-Up", "Failed pick-Up", "Delivered", "Dropped", "Returned", "Reattempt", "Cancelled"];

  let statusList: OrderStatus[] = [
    OrderStatus.PICKED,
    OrderStatus.PICK_UP_FAILED,
    OrderStatus.DELIVERED,
    OrderStatus.DROPPED,
    OrderStatus.RETURN,
    OrderStatus.REATTEMPT,
    OrderStatus.CANCELED,
  ];

  if (props.currentStatus === OrderStatus.RETURN) {
    statusList = [OrderStatus.RETURNED];
  } else if (props.currentStatus === OrderStatus.REATTEMPT) {
    statusList = [OrderStatus.Reattempted];
  }

  const [isSearch, setSearch] = useState("");

  const [showImageUploadSec, setShowImageUploadSec] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Explicitly specify the type as File or null
  const { onSelectedStatusChange } = props;
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);

  const [receiverName, setReceiverName] = useState<string>("");
  const [shipperName, setShipperName] = useState<string>("");
  const [returnName, setReturnName] = useState<string>("");
  const [redeliverName, setRedeliverName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");

  const handleReceiverNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceiverName(e.target.value);
  };

  const handleShipperrNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipperName(e.target.value);
  };

  const handleReturnNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReturnName(e.target.value);
  };

  const handleRedeliverNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRedeliverName(e.target.value);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(e.target.value);
  };

  const handleSearchChange = (e: { target: { value: string } }) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const now = new Date();

    // Format the current date as "YYYY-MM-DD" for the input type="date"
    const formattedDate = now.toISOString().substr(0, 10);

    // Format the current time as "HH:MM" for the input type="time"
    const formattedTime = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;

    setSelectedDate(formattedDate);
    setSelectedTime(formattedTime);
  }, []);

  const handleStatusAssign = () => {
    let error = null;

    if (selectedStatus === OrderStatus.DROPPED || selectedStatus === OrderStatus.RETURN || selectedStatus === OrderStatus.REATTEMPT || selectedStatus === OrderStatus.PICK_UP_FAILED) {
      if (selectedFile === null) {
        error = "Please select the image.";
      } else if (selectedDate.trim() === "" || selectedTime.trim() === "") {
        error = "Please select the Date and Time.";
      }
    } else if (selectedStatus === OrderStatus.DELIVERED) {
      if (receiverName === "") {
        error = "Please enter receiver name.";
      } else if (selectedDate.trim() === "" || selectedTime.trim() === "") {
        error = "Please select the Date and Time.";
      }
    } else if (selectedStatus === OrderStatus.RETURNED) {
      if (returnName === "") {
        error = "Please enter return name.";
      } else if (selectedDate.trim() === "" || selectedTime.trim() === "") {
        error = "Please select the Date and Time.";
      }
    } else if (selectedStatus === OrderStatus.Reattempted) {
      if (redeliverName === "") {
        error = "Please enter redeliver name.";
      } else if (selectedDate.trim() === "" || selectedTime.trim() === "") {
        error = "Please select the Date and Time.";
      }
    } else if (selectedStatus === OrderStatus.PICKED || selectedStatus === OrderStatus.PICK_UP_FAILED) {
      if (shipperName === "") {
        error = "Please enter shipper name.";
      } else if (selectedDate.trim() === "" || selectedTime.trim() === "") {
        error = "Please select the Date and Time.";
      }
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
      return;
    }

    // const statusData =
    //   selectedStatus === OrderStatus.DROPPED || selectedStatus === OrderStatus.DELIVERED
    //     ? {
    //         imageFile: selectedFile,
    //         receiverName,
    //         notes,
    //         date: selectedDate,
    //         time: selectedTime,
    //         status: selectedStatus,
    //       }
    //     : { status: selectedStatus };

    const statusData = {
      imageFile: selectedFile,
      receiverName,
      shipperName,
      returnName,
      redeliverName,
      notes,
      date: selectedDate,
      time: selectedTime,
      status: selectedStatus,
    };

    props.handleStatusAssign(statusData);
  };

  const handleRadioChange = (status: string | undefined) => {
    onSelectedStatusChange(status);
    setSelectedStatus(status);
    if (status === "dropped") {
      setShowImageUploadSec(true);
    } else {
      setShowImageUploadSec(false);
    }
    // onSelectedDriverChange(driverId); // Call the parent callback function
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      setSelectedFile(selectedFile);
      // You can handle the selected file here, such as displaying its details or uploading it.
    }
  };
  const handleModalClose = () => {
    props.toggle();
    setSelectedStatus(undefined);
    setSelectedFile(null);
  };
  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setSelectedFile(droppedFile);
      // You can handle the dropped file here, such as displaying its details or uploading it.
    }
  };
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={handleModalClose} size="lg">
        <ModalHeader toggle={handleModalClose}>
          <Row>
            <Col>
              <p className="text-left mx-4">Update Status</p>
            </Col>
            {/* <Col className="d-flex justify-content-end">
              <input
                type="search"
                name="search"
                id="password"
                className="form-control"
                placeholder="Search"
                style={{ height: "30px" }}
                value={isSearch}
                onChange={handleSearchChange}
              />
            </Col> */}
          </Row>
        </ModalHeader>

        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column", height: "auto" }}>
            <Table>
              {/* <thead>
                <tr>
                  <th>Status</th>
                  </tr>
              </thead> */}
              <tbody>
                {statusList.map((value, i) => (
                  <tr key={i}>
                    <td className="px-2">
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0px",
                          fontSize: "12px",
                        }}
                      >
                        <input
                          type="radio"
                          value={i}
                          checked={selectedStatus === value}
                          onChange={() => handleRadioChange(value)}
                          style={{ marginRight: "30px" }}
                        />
                        {value} 12
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div>
              {(selectedStatus === "dropped" ||
                selectedStatus === "pick_up_failed" ||
                selectedStatus === "return" ||
                selectedStatus === "reattempt") && (
                <div className="border border-light w-auto p-1">
                  <div className="bg-warning bg-opacity-10 p-3  h-50 w-auto d-flex justify-content-center align-items-center">
                    <div className="w-50 p-1" style={{ border: "1px dashed rgba(250, 143, 101, 0.5)" }}>
                      <div
                        className="border border-light w-auto"
                        onDrop={handleFileDrop}
                        onDragOver={e => e.preventDefault()} // Allow dropping// Open file dialog on click
                      >
                        <Label for="fileInput" className="d-flex flex-column justify-content-center align-items-center">
                          <ImageIcon />
                          <p className="text-center mt-2" style={{ fontSize: "12px" }}>
                            {selectedFile ? selectedFile.name : "Drop Image here or click to upload."}
                          </p>
                        </Label>
                        <Input id="fileInput" name="file" type="file" style={{ display: "none" }} onChange={handleFileSelect} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                {selectedStatus === "delivered" && (
                  <FormGroup>
                    <Label for="nameOfRec" style={{ fontSize: "13px" }}>
                      Name of receiver <Label className="title-star">*</Label>
                    </Label>
                    <Input
                      id="nameOfRec"
                      name="name"
                      placeholder=""
                      type="text"
                      style={{ fontSize: "13px" }}
                      onChange={handleReceiverNameChange}
                    />
                  </FormGroup>
                )}
                {(selectedStatus === "picked" || selectedStatus === "pick_up_failed") && (
                  <FormGroup>
                    <Label for="picked" style={{ fontSize: "13px" }}>
                      Name of shipper <Label className="title-star">*</Label>
                    </Label>
                    <Input
                      id="nameOfShip"
                      name="name"
                      placeholder=""
                      type="text"
                      style={{ fontSize: "13px" }}
                      onChange={handleShipperrNameChange}
                    />
                  </FormGroup>
                )}
                {selectedStatus === "returned" && (
                  <FormGroup>
                    <Label for="picked" style={{ fontSize: "13px" }}>
                      Returned Name<Label className="title-star">*</Label>
                    </Label>
                    <Input
                      id="nameOfReturn"
                      name="name"
                      placeholder=""
                      type="text"
                      style={{ fontSize: "13px" }}
                      onChange={handleReturnNameChange}
                    />
                  </FormGroup>
                )}
                {selectedStatus === "reattempted" && (
                  <FormGroup>
                    <Label for="picked" style={{ fontSize: "13px" }}>
                      Redelivered Name<Label className="title-star">*</Label>
                    </Label>
                    <Input
                      id="nameOfRedelivered"
                      name="name"
                      placeholder=""
                      type="text"
                      style={{ fontSize: "13px" }}
                      onChange={handleRedeliverNameChange}
                    />
                  </FormGroup>
                )}

                {(selectedStatus === "picked" ||
                  selectedStatus === "pick_up_failed" ||
                  selectedStatus === "delivered" ||
                  selectedStatus === "dropped" ||
                  selectedStatus === "return" ||
                  selectedStatus === "returned" ||
                  selectedStatus === "reattempted" ||
                  selectedStatus === "reattempt") && (
                  <>
                    <FormGroup>
                      <Label for="notes" style={{ fontSize: "13px" }}>
                        Notes
                      </Label>
                      <Input id="notes" name="notes" type="textarea" rows={2} style={{ fontSize: "13px" }} onChange={handleNotesChange} />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="date" style={{ fontSize: "13px" }}>
                            Date <Label className="title-star">*</Label>
                          </Label>
                          <Input
                            id="date"
                            name="date"
                            placeholder=""
                            type="date"
                            style={{ fontSize: "13px" }}
                            onChange={handleDateChange}
                            value={selectedDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="time" style={{ fontSize: "13px" }}>
                            Time <Label className="title-star">*</Label>
                          </Label>
                          <Input
                            id="time"
                            name="time"
                            placeholder=""
                            type="time"
                            style={{ fontSize: "13px" }}
                            onChange={handleTimeChange}
                            value={selectedTime}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleStatusAssign} size="sm">
            Update Status
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default UpdateStatus;
