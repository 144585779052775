export enum OrderStatus {
  NOT_ASSIGNED = "not_assigned",
  ASSIGNED = "assigned",
  PICKED = "picked",
  PICK_UP_FAILED = "pick_up_failed",
  DELIVERED = "delivered",
  DELIVERED_ORDERS = "delivered",
  DELIVERED_TODAY = "delivered_today",
  DROPPED = "dropped",
  CANCELED = "canceled",
  REATTEMPT_DELIVERED = "reattempted_and_delivered",
  REATTEMPT = "reattempt",
  REATTEMPT_FAILED = "reattempted_and_failed",
  RETURN = "return",
  RETURNED = "returned",
  REDELIVERED = "redelivered",
  Reattempted = "reattempted",
}
