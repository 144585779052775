import { AuthData, useAuth } from "context/AuthProvider"
import { OpenCloseToggle } from "admin/ManageCustomers/ToggleSwitch";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { OperatingHours } from "models/OperatingHour";
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { User } from "models/User";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "services/AuthService";

export interface operatingHoursProps {
  day: string;
  OP: OperatingHours;
  setOP: (value: OperatingHours) => void;
}

export const OperatingHour: React.FC<operatingHoursProps> = ({day, OP, setOP}) => {
  const format = 'hh:mm A';
  let beginTime: string;
  let endTime: string;

  useEffect(() => {
    (OP?.begin === "" || OP?.begin === undefined || OP?.begin === null) ? beginTime = "08:00 AM" : beginTime = OP?.begin;
    (OP?.end === "" || OP?.end === undefined || OP?.end === null) ? endTime = "05:00 PM" : endTime = OP?.end;

    setOP({...OP, day: day, begin: beginTime, end: endTime})
  }, [])

  return (
    <Row className="mb-lg-0 mb-md-0 mb-3">
      <Col lg="2" md="2"><Label>{day}</Label></Col>
      <Col lg="1" md="1" className="my-lg-1 mx-lg-3">
        <OpenCloseToggle isOpen={OP?.isOperating} OP={OP} setOP={setOP} />
      </Col>
      <Col lg="1" md="1"><Label>{OP?.isOperating ? "Open" : "Close" }</Label></Col>
      {OP?.isOperating && (
        <Col lg="7" md="7" className="ms-lg-4">
          <TimePicker
            defaultValue={dayjs(OP.begin, format)}
            format={format}
            className="me-lg-2"
            onChange={(value, dateString) => {
              setOP({...OP, begin: dateString});
            }}
            />
          <Label>To</Label>
          <TimePicker
            value={dayjs(OP.end, format)}
            format={format}
            className="ms-lg-2"
            onChange={(value, dateString) => {
              setOP({...OP, end: dateString});
            }} />
        </Col>
      )}
    </Row>
  )
}

const OperatingHoursView: React.FC = () => {

  const [user, setUser] = useAuth() as AuthData;

  const [sundayOP, setSundayOP] = useState<OperatingHours>({day: "Sunday", begin: "", end: "", isOperating: false})
  const [mondayOP, setMondayOP] = useState<OperatingHours>({day: "Monday", begin: "", end: "", isOperating: false})
  const [tuesdayOP, setTuesdayOP] = useState<OperatingHours>({day: "Tuesday", begin: "", end: "", isOperating: false})
  const [wednesdayOP, setWednesdayOP] = useState<OperatingHours>({day: "Wednesday", begin: "", end: "", isOperating: false})
  const [thursdayOP, setThursdayOP] = useState<OperatingHours>({day: "Thursday", begin: "", end: "", isOperating: false})
  const [fridayOP, setFridayOP] = useState<OperatingHours>({day: "Friday", begin: "", end: "", isOperating: false})
  const [saturdayOP, setSaturdayOP] = useState<OperatingHours>({day: "Saturday", begin: "", end: "", isOperating: false})

  useEffect(() => {

    user?.operatingHours

    user?.operatingHours?.map(oh => {
      oh.day === "Sunday" ?
        setSundayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) :
      oh.day === "Monday" ?
        setMondayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) : 
      oh.day === "Tuesday" ?
        setTuesdayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) :
      oh.day === "Wednesday" ?
        setWednesdayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) :
      oh.day === "Thursday" ?
        setThursdayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) :
      oh.day === "Friday" ?
        setFridayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) :
      oh.day === "Saturday" ?
        setSaturdayOP({day: oh.day, begin: oh.begin, end: oh.end, isOperating: oh.isOperating}) : ""
    })
  }, [])

  const handleSetHours = () => {
    const opData: User = {
      operatingHours: [sundayOP, mondayOP, tuesdayOP, wednesdayOP, thursdayOP, fridayOP, saturdayOP]
    }

    AuthService.updateOperatingHours(opData).then(res => {
      if (res.success) {
        setUser({...user, operatingHours: [sundayOP, mondayOP, tuesdayOP, wednesdayOP, thursdayOP, fridayOP, saturdayOP]})
        toast.success("Successfully updated Operating Hours.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        })
      } else {
        toast.error("error occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        })
      }
    })
    .catch(error => {
      console.error(error)
    })
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Hours of Operation</CardTitle>
          <div className="content clearfix mx-lg-4">
            <OperatingHour day="Sunday" OP={sundayOP} setOP={setSundayOP} />
            <OperatingHour day="Monday" OP={mondayOP} setOP={setMondayOP} />
            <OperatingHour day="Tuesday" OP={tuesdayOP} setOP={setTuesdayOP} />
            <OperatingHour day="Wednesday" OP={wednesdayOP} setOP={setWednesdayOP} />
            <OperatingHour day="Thursday" OP={thursdayOP} setOP={setThursdayOP} />
            <OperatingHour day="Friday" OP={fridayOP} setOP={setFridayOP} />
            <OperatingHour day="Saturday" OP={saturdayOP} setOP={setSaturdayOP} />

            <Row className="justify-content-end">
              <Col className="col-md-auto">
                <div className="d-flex gap-1">
                  <button
                    className="btn btn-primary opacity-75 btn-sm px-3 me-2"
                    //onClick={}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary btn-sm px-3"
                    onClick={handleSetHours}>
                    Set Hours
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default OperatingHoursView
