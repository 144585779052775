import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FinanceTable from "components/Common/FinanceTable";
import { FinanceIdData, FinanceListData, FinanceSummaryData, UpdateFinanceData } from "models/Finance";
import { format, parseISO } from "date-fns";
import { AdminService } from "services/AdminService";
import FinanceInvoiceView from "./FinanceInvoiceView";
import FinanceUpdateStatus from "./FinanceUpdateStatus";
import { toast } from "react-toastify";
import { Puff } from "react-loader-spinner";

const AdminFinance: React.FC = () => {
  const [financeList, setFinanceList] = useState<FinanceListData[]>([]);
  const [financeSummaryData, setFinanceSummaryData] = useState<FinanceSummaryData>();
  const [financePastInvoiceData, setFinancePastInvoiceData] = useState<FinanceIdData[]>([]);

  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [modalOffset_p_c, setModalOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [modalPageCount, modalSetPageCount] = useState<number>(1);
  const [pastInvoiceId, setPastInvoiceId] = useState();
  const [pastInvoiceNameId, setPastInvoiceNameId] = useState();
  const [pastInvoiceName, setPastInvoiceName] = useState();
  const [popupDate, setPopupDate] = useState<Date | null>(null);
  const [modalPopupDate, setModalPopupDate] = useState<Date | null>(null);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editInvoiceId, setEditInvoiceId] = useState();
  const [editNewAmount, setEditNewAmount] = useState();
  const [editNewDiscount, setEditNewDiscount] = useState();
  const [editNewPayment, setEditNewPayment] = useState();
  const [editNewStatus, setEditNewStatus] = useState();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [totalUnpaidInvoiceCount, setTotalUnpaidInvoiceCount] = useState(0);
  const [totalAmountDue, setTotalAmountDue] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "Customer ID",
        accessor: "latestInvoice.customerDetails.customerId",
      },
      {
        Header: "Business Name",
        accessor: (row: any) => {
          const customerDetails = row.latestInvoice.customerDetails;
          return customerDetails.isABusiness
            ? customerDetails.address.companyName
            : `${customerDetails.firstName} ${customerDetails.lastName}`;
        },
      },
      {
        Header: "Invoice Number",
        accessor: "latestInvoice.invoiceId",
      },
      {
        Header: "From Date",
        accessor: "latestInvoice.startDate",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd.MM.yyyy");
        },
      },
      {
        Header: "To Date",
        accessor: "latestInvoice.endDate",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd.MM.yyyy");
        },
      },
      {
        Header: "Amount Due",
        accessor: "totalOutstandingBalance",
        Cell: ({ value }: any) => {
          const formattedValue = value ? `$ ${value.toFixed(2)}` : "$ 0.00";
          return <span>{formattedValue}</span>;
        },
      },
      {
        Header: "Amount Billed",
        accessor: "amountBilled",
        Cell: ({ value }: any) => {
          const formattedValue = value ? `$ ${value.toFixed(2)}` : "$ 0.00";
          return <span>{formattedValue}</span>;
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amountPaid",
        Cell: ({ value }: any) => {
          const formattedValue = value ? `$ ${value.toFixed(2)}` : "$ 0.00";
          return <span>{formattedValue}</span>;
        },
      },

      {
        Header: "Pending Payment",
        accessor: "unpaidInvoiceCount",
      },
      {
        Header: "Status",
        accessor: "payStatus",
      },
    ],
    []
  );

  const modalColumns = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "invoiceId",
      },
      {
        id: "action",
        Header: "Billing Period",
      },

      {
        Header: "Amount Due",
        accessor: "amountDue",
      },
      {
        Header: "Amount Paid",
        accessor: "amountPaid",
      },

      {
        Header: "Status",
        accessor: "payStatus",
      },
      {
        Header: "PDF",
        // accessor: "payStatus",
      },
      {
        id: "action",
        Header: "Action",
        // accessor: "_id",
        // Cell: ({ row }: any) => {
        //   return (
        //     <div className="col-md-12 text-center">
        //       <button
        //         className="btn btn-primary btn-sm px-3"
        //         // onClick={() => handleAction(row)}
        //       >
        //         View
        //       </button>
        //     </div>
        //   );
        // },
      },
    ],
    []
  );

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    // AdminService.getAllFinance(LIMIT_P_C, isOffset_p_c).then(res => {
     AdminService.getAllFinance(selectedOption.value,1).then(res => {
      if (res.success) {
        setFinanceList(res.data.financeList);
        //setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      } else {
      }
    });
  };

  useEffect(() => {
    setIsloading(true);
    AdminService.getAllFinance(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setFinanceList(res.data.financeList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      } else {
      }
      setIsloading(false);
    });
  }, []);

  useEffect(() => {
    if (pastInvoiceId !== undefined) {
      getFinanceModal();
    }
  }, [pastInvoiceId]);

  const handleFianceDate = () => {
    getFinanceByDate(popupDate);
  };

  const handleFianceModalDate = () => {
    getFinanceModalByDate(modalPopupDate);
  };

  const getFinanceByDate = (date: any) => {
    const data = { date };
    AdminService.getFinanceByDate(data, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setFinanceList(res.data.financeList);
      } else {
        // Handle the error case
      }
    });
  };

  useEffect(() => {
    AdminService.getFinanceSummary().then(res => {
      setFinanceSummaryData(res.data);
    });
  }, []);

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AdminService.getAllFinance(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setFinanceList(res.data.financeList);
       // setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const ModalSeeMore = (page: any) => {
    setModalOffset_p_c(page.selected + 1);
    AdminService.getFinanceModal(pastInvoiceId, LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setFinancePastInvoiceData(res.data.financeList);        
        modalSetPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  useEffect(() => {
    // Calculate totals when financeList changes
    const calculateTotals = () => {
      let unpaidInvoiceCount = 0;
      let amountDue = 0;
      let amountPaid = 0;
     

      financeList.forEach((financeItem: any) => {
        unpaidInvoiceCount += financeItem.unpaidInvoiceCount || 0;
          amountDue += financeItem.totalOutstandingBalance || 0;
        
        amountPaid += financeItem.amountPaid || 0;
      });

      setTotalUnpaidInvoiceCount(unpaidInvoiceCount);
      setTotalAmountDue(amountDue);
      setTotalAmountPaid(amountPaid);
    };

    calculateTotals();
  }, [financeList]);

  // const handleColumnChange = (selectedOption: any) => {
  //   setLIMIT_P_C(selectedOption.value);
  //   AdminService.getAllFinance(selectedOption.value, 1).then(res => {
  //     if (res.success) {
  //       setFinanceList(res.data.financeList);
  //       setPageCount(Math.ceil(res.data.count / selectedOption.value));
  //     }
  //   });
  // };
  const clearEditData = () => {
    setEditInvoiceId(undefined);
    setEditNewAmount(undefined);
    setEditNewDiscount(undefined);
    setEditNewPayment(undefined);
    setEditNewStatus(undefined);
    setEditNewPayment(undefined);
    setEditNewStatus(undefined);
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleEditPopup = () => {
  
    setEditNewAmount(undefined);
    setEditNewAmount(undefined);
    setEditNewStatus(undefined);
    setEdit(!edit);
    // getFinanceModal();
  };

  const handleEditUpdate = () => {
    
    const data: UpdateFinanceData = {
      invoiceId: editInvoiceId,
      paidAmount: editNewAmount,
      discount: editNewDiscount,
      paymentMethod: editNewPayment,
      paymentStatus: editNewStatus,
    };

    if (((typeof data.paidAmount === "string" && data.paidAmount === "0") ||data.paidAmount === null || data.paidAmount === undefined || data.paidAmount ===0) && ((typeof data.discount === "string" && data.discount === "0") ||data.discount === null || data.discount === undefined || data.discount === 0)) {
      toast.error("Please provide at least one of Paid Amount or Discount", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
      
    } else if (
      // Check if invoiceId, paymentMethod, and status are not empty or undefined
      data.invoiceId === null ||
      data.invoiceId === "" ||
      data.invoiceId === undefined ||
      // data.paymentMethod === null ||
      // data.paymentMethod === "" ||
      // data.paymentMethod === undefined ||
      data.paymentStatus === null ||
      data.paymentStatus === "" ||
      data.paymentStatus === undefined
    ) {
      toast.error("Please select status", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (data.discount !== undefined || data.paidAmount !== undefined) {
      AdminService.updatePaymentDetails(data).then(res => {
        if (res.success) {
          toast.success("Invoice updated Successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
          AdminService.getAllFinance(LIMIT_P_C, isOffset_p_c).then(res => {
            if (res.success) {
              setFinanceList(res.data.financeList);
              setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
            } else {
            }
          });
          AdminService.getFinanceSummary().then(res => {
            setFinanceSummaryData(res.data);
          });
          if (pastInvoiceId !== undefined) {
            getFinanceModal();
          }
          clearEditData();
        } else {
          toast.error(`${res.error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      });
      handleEditPopup();
    }
  };

  const handlePastListData = (data: any) => {
    setPastInvoiceId(data);
  };

  const handlePastListNameData = (data: any) => {
    setPastInvoiceName(data);
  };

  const handlePastListIdData = (data: any) => {
    setPastInvoiceNameId(data);
  };

  const handleModalPopupDate = (data: any) => {
    setModalPopupDate(data);
  };

  const handlePopupDate = (data: any) => {
    setPopupDate(data);
  };

  const handleEditId = (data: any) => {
    setEditInvoiceId(data);
  };

  const handleAmount = (data: any) => {
    setEditNewAmount(data);
  };

  const handleDiscount = (data: any) => {
    setEditNewDiscount(data);
  };

  const handlePayment = (data: any) => {
    setEditNewPayment(data);
  };
  const handleStatus = (data: any) => {
    setEditNewStatus(data);
  };

  const searchTable = (searchText: any) => {
    const searchdata = {
      searchableString: searchText,
    };

    if (searchText === "") {
      AdminService.getAllFinance(LIMIT_P_C, isOffset_p_c).then(res => {
        if (res.success) {
          setFinanceList(res.data.financeList);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        } else {
          // Handle the error case
        }
      });
    } else {
      AdminService.getFinanceBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
        if (res.success) {
          setFinanceList(res.data.financeList);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        } else {
          // Handle the error case
        }
      });
    }
  };

  const getFinanceModal = () => {
    AdminService.getFinanceModal(pastInvoiceId, LIMIT_P_C, modalOffset_p_c).then(res => {
      if (res.success) {
        setFinancePastInvoiceData(res.data.financeList);
        modalSetPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const searchModal = (searchText: string) => {
    const searchdata = {
      searchableString: searchText,
    };

    if (searchText === "") {
      getFinanceModal();
    } else {
      AdminService.getFinanceModalBySearch(searchdata, pastInvoiceId, LIMIT_P_C, modalOffset_p_c).then(res => {
        if (res.success) {
          setFinancePastInvoiceData(res.data.financeList);
        }
      });
    }
  };

  const getFinanceModalByDate = (date: any) => {
    const data = { date };
    AdminService.getFinanceModalByDate(data, pastInvoiceId, LIMIT_P_C, modalOffset_p_c).then(res => {
      if (res.success) {
        setFinancePastInvoiceData(res.data.financeList);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Finance" breadcrumbItem={"All Finance"} />
          <Col>
            <>
              <Card>
                <CardBody>
                  {isloading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                      <Puff color="#FFD0B8" height={50} width={50} />
                    </div>
                  ) : (
                    <>
                      {financeList !== undefined ? (
                        <FinanceTable
                          columns={columns}
                          data={financeList}
                          ispopup={true}
                          handleSearch={searchTable} //searchTable
                          selectedColumns={LIMIT_P_C}
                          showColumnChange={true}
                          customPageSize={50}
                          className={undefined}
                          isPDFGenerate={true}
                          hancleDeleteRecords={undefined}
                          selectedID={undefined}
                          handlePageChange={SeeMore} //SeeMore
                          handleColumnChange={handleColumnChange} //handleColumnChange
                          openModal={handlePopup}
                          pastListData={handlePastListData} //handlePastListData
                          pagelimit={pageCount}
                          pastListNameData={handlePastListNameData}
                          pastListIdData={handlePastListIdData}
                          popUpDate={handlePopupDate}
                          handLeFianceDate={handleFianceDate}
                          editId={handleEditId}
                          openEdit={handleEditPopup}
                        />
                      ) : (
                        <Row className="justify-content-md-center justify-content-center align-items-center mb-5">
                          <Col className="col-md-auto">
                            <div>No Finance Data</div>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
              <Row>
                <Col sm="6">
                  <Card body>
                    <CardTitle tag="h5" className="pb-5">
                      Current Month Receivables
                    </CardTitle>

                    <Row className="text-center">
                      <Col>
                        <CardText tag="h5">
                          {financeSummaryData?.currentMonthSummary.latestTotalAmount
                            ? `$${financeSummaryData.currentMonthSummary.latestTotalAmount.toFixed(2)}`
                            : "$0.00"}
                        </CardText>
                      </Col>
                      <Col>
                        <CardText tag="h5">
                          {financeSummaryData?.currentMonthSummary.latestTotalOutstanding
                            ? `$${financeSummaryData.currentMonthSummary.latestTotalOutstanding.toFixed(2)}`
                            : "0"}
                        </CardText>
                      </Col>
                      <Col>
                        <CardText tag="h5">
                          {financeSummaryData?.currentMonthSummary.latestInvoiceCount
                            ? `${Math.floor(financeSummaryData.currentMonthSummary.latestInvoiceCount)}`
                            : "0"}
                        </CardText>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                        <CardText>
                          <small>Total Payments</small>
                        </CardText>
                      </Col>
                      <Col>
                        <CardText>
                          <small>Total Receivable Payments</small>
                        </CardText>
                      </Col>
                      <Col>
                        <CardText>
                          <small>Total Number of Invoices</small>
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                  <Card body>
                    <CardTitle tag="h5" className="pb-5">
                      Total Accounts Receivable
                    </CardTitle>

                    <Row className="text-center">
                      <Col>
                        <CardText tag="h5">
                          $
                          {financeSummaryData?.totalAccountSummary.totalPayments
                            ? `${financeSummaryData.totalAccountSummary.totalPayments.toFixed(2)}`
                            : "0.00"}
                        </CardText>
                      </Col>
                      <Col>
                        <CardText tag="h5">
                          $
                          {financeSummaryData?.totalAccountSummary.totalRecievedPayments
                            ? `${financeSummaryData.totalAccountSummary.totalRecievedPayments.toFixed(2)}`
                            : "0.00"}
                        </CardText>
                      </Col>
                    </Row>
                    <Row className="text-center pb-5">
                      <Col>
                        <CardText>
                          <small>Total Payments</small>
                        </CardText>
                      </Col>
                      <Col>
                        <CardText>
                          <small>Total Received Payments</small>
                        </CardText>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                        <CardText tag="h5">
                          $
                          {financeSummaryData?.totalAccountSummary.totalRecievablePayments
                            ? `${financeSummaryData.totalAccountSummary.totalRecievablePayments.toFixed(2)}`
                            : "0.00"}
                        </CardText>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                        <CardText>
                          <small>Total Receivable Payments</small>
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body>
                    <CardTitle tag="h5" className="pb-5">
                      Current page info
                    </CardTitle>

                    <Row className="text-center">
                      <Col>
                        <CardText tag="h5">${totalAmountDue.toFixed(2)}</CardText>
                      </Col>
                      <Col>
                        <CardText tag="h5">${totalAmountPaid.toFixed(2)}</CardText>
                      </Col>
                      <Col>
                        <CardText tag="h5">{totalUnpaidInvoiceCount}</CardText>
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                        <CardText>
                          <small>Amount Due</small>
                        </CardText>
                      </Col>
                      <Col>
                        <CardText>
                          <small>Amount Paid</small>
                        </CardText>
                      </Col>
                      <Col>
                        <CardText>
                          <small>Pending payments</small>
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          </Col>
        </div>
      </div>
      <FinanceInvoiceView
        isOpen={modal}
        handlePopup={handlePopup}
        modalColumns={modalColumns}
        data={financePastInvoiceData}
        name={pastInvoiceName || ""}
        id={pastInvoiceNameId || ""}
        handleSearch={searchModal}
        popupDate={handleModalPopupDate}
        handleModalDateClick={handleFianceModalDate}
        editId={handleEditId}
        openEdit={handleEditPopup}
        pagelimit={modalPageCount}
        handlePageChange={ModalSeeMore}
      />
      <FinanceUpdateStatus
        isOpen={edit}
        toggle={handleEditPopup}
        handleEditUpdate={handleEditUpdate}
        handleAmount={handleAmount}
        handleDiscount={handleDiscount}
        handlePayment={handlePayment}
        handleStatus={handleStatus}
      />
    </React.Fragment>
  );
};

export default AdminFinance;
