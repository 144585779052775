import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Card, CardBody, Row, Label, Table } from "reactstrap";
import ManageOrder from "../ManageOrder";
import { AdminService } from "services/AdminService";
import { Setting } from "models/Setting";
import UpdateService from "./UpdateService";
import { useAuth } from "context/AuthProvider";

const ManageServices: React.FC = () => {
  const [settings, setSettings] = useState<Setting>();
  const [modalUpdate, setModalUpdate] = useState(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [user] = useAuth();
  useEffect(() => {
    AdminService.getSettings().then(res => {
      if (res.success) {
        setSettings(res.data)
      }
    })
  }, [isModified]);

  const handlePopup = () => {
    setModalUpdate(!modalUpdate)
  }
  const handleUpdateService = () => {
    handlePopup()
  }
  
  //meta title
  document.title = "Manage Order";
  const className = "d-flex flex-row justify-content-center text-wrap"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Manage Service" breadcrumbItem="Create" />
        <Row>
          <Col lg={12}>
            <ManageOrder />
            {user?.role === "super_admin" && (
            <Card>
              <CardBody>
                <div className="d-inline-flex w-100 justify-content-between">
                  <Label className="fs-6 fw-bold">Manage Service</Label>
                </div>
                <div className="table-responsive react-table">
                  <table className="newTable">
                    <thead className="newThead">
                      <tr>
                        <th><div className={className}>Base Price(Up to 5 Km)</div></th>
                        <th><div className={className}>Additional Km</div></th>
                        <th><div className={className}>After Km</div></th>
                        <th><div className={className}>Weight</div></th>
                        <th><div className={className}>Vehicle Type</div></th>
                        <th><div className={className}>Action</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Base Price(Up to 5 Km)">
                          {settings?.basePrice !== undefined && (
                            <div>${settings?.basePrice.toFixed(2)}</div>
                          )}
                        </td>
                        <td data-label="Additional Km">
                          {settings?.pricePerAdditionalKM !== undefined && (
                            <div>${settings?.pricePerAdditionalKM.toFixed(2)}</div>
                          )}
                        </td>
                        <td data-label="After Km">
                          <div>
                            {settings?.pricePerAfterKM?.price !== undefined && settings?.pricePerAfterKM?.price !== null  && (
                              <ul>
                                <li>After {settings?.pricePerAfterKM?.km} - ${settings?.pricePerAfterKM?.price.toFixed(2)}</li>
                              </ul>
                            )}
                          </div>
                        </td>
                        <td data-label="Weight">
                          {settings?.baseWeightPrice !== undefined && (
                            <div>${settings?.baseWeightPrice.toFixed(2)}</div>
                          )}</td>
                        <td data-label="Vehicle Type">
                          {settings?.vehiclePrice !== undefined && (
                            <div>
                              {settings?.vehiclePrice.map((val: any) => (
                                <ul key={val.vehicle}>
                                  <li>{val.vehicle} - ${val.price.toFixed(2)}</li>
                                </ul>
                              ))}
                            </div>
                          )}
                        </td>
                        <td data-label="Action">
                          <div className="text-center">
                            <button className="btn btn-primary btn-sm px-3 py-2 mt-2 rounded-1" onClick={handleUpdateService}>
                              Update Service
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card> )}
          </Col>
        </Row>
      </div>
      <UpdateService
        isOpen={modalUpdate}
        handlePopup={handlePopup}
        isModified={isModified}
        setIsModified={setIsModified}
        settings={settings} />
    </div>
  )
}

export default ManageServices;