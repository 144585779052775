import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services/AuthService";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import EmailVerificationCard from "./emailVerificationCard";
import { OtpType } from "enum/OtpType";

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  document.title = "User Verification | Zulu Courier ";

  const handleSubmit = (otp: string) => {
  
    
    AuthService.emailOTPVerification({ email: location.state?.email, otpCode: otp })
      .then(async res => {
        if (res.success) {
          AuthService.setToken(res.data);
          const data: any = await jwt_decode(res.data);
          navigate(`/${data?.user_role}/place-orders`, { replace: true });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          });
        }
      })
      .catch(error => {
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        });
      });
  };

  const resendOtp = () => {
    AuthService.resendOtp({ email: location.state?.email, otpType: OtpType.SIGNUP_OTP})
      .then(res => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          });
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          });
        }
      })
      .catch(error => {
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        });
      });
  };

  return (
    <EmailVerificationCard email={location.state?.email} onSubmit={handleSubmit} onResendOtp={resendOtp} />
  );
};

export default EmailVerification;
