import { Image } from "models/Upload"
import React, { useContext, useState } from "react"

export type ImageData = [Image | undefined, (image: Image) => void]

const ImageContext = React.createContext<ImageData>([ undefined, () => {} ])

export const ProfileImageProvider = ({ children }: any) => {
    const [image, setImage] = useState<Image | undefined>(undefined)
    return <ImageContext.Provider value={[image, setImage]}>{children}</ImageContext.Provider>
}

export const useImage = () => {
    return useContext(ImageContext)
}