// src/components/filter.
import React from "react"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContent from "./TableContent"

const AddressBook: React.FC = () => {

  //meta title
  document.title = "Address Book | Zulu Courier"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Address Book" breadcrumbItem="Create Address" />
        <TableContent />
      </div>
    </div>
  )
}

export default AddressBook
