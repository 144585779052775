import React, { useState } from "react";
import { Card, CardBody, Col, Form, Label, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AddressService } from "services/AddressService";
import { AddressModel } from "models/City";
import { AddressBook } from "models/AddressBook";
import { AuthData, useAuth } from "context/AuthProvider";
import { environment } from "environment/environment";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { AddressType } from "enum/AddressType";
import { NotificationData, useNotification } from "context/NotificationProvider";
import { NotificationService } from "services/NotificationService";

export interface AddressProps {
  isOpen: boolean;
  handlePopup: () => void;
  setIsModified: (value: boolean) => void;
  isModified: boolean;
}

const CreateAddress: React.FC<AddressProps> = ({ isOpen, handlePopup, isModified, setIsModified }: AddressProps) => {
  const [user] = useAuth() as AuthData;
  const [notification, setNotification] = useNotification() as NotificationData;
  const [address, setAddress] = useState<AddressModel>();
  const [error, setError] = useState<AddressModel>();

  const [companyName, setCompanyName] = useState<string>();
  const [notes, setNotes] = useState<string>();

  const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;
  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "companyName":
        if (user?.isABusiness && (value == "" || value == undefined || value == null)) {
          setError({ ...error, companyName: "Required" });
        } else {
          setError({ ...error, companyName: undefined });
        }
        setCompanyName(value);
        break;
      case "unit":
        setAddress({ ...address, unit: value });
        break;
      case "contactName":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, contactName: "Required" });
        } else {
          setError({ ...error, contactName: undefined });
        }
        setAddress({ ...address, contactName: value });
        break;
      case "contactPhone":
        if (value !== "") {
          if (!canadianPhoneRegExp.test(value)) {
            setError({ ...error, contactPhone: "Invalid Canadian phone number" });
          } else {
            setError({ ...error, contactPhone: undefined });
          }
          setAddress({ ...address, contactPhone: value });
        }
        break;
      case "notes":
        setNotes(value);
        break;
      default:
        break;
    }
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const cityAddress = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setAddress({ ...address,
            city: cityAddress,
            lat: latLng.lat,
            lng: latLng.lng,
            postalCode: postalCode
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleSubmit = () => {
    if (user?.isABusiness && (companyName == "" || companyName == undefined)) {
      toast.error("Company Name is Required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (address?.city == "" || address?.city == undefined) {
      toast.error("Address is Required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (address?.contactName == "" || address?.contactName == undefined) {
      toast.error("Contact Name is Required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (address?.contactPhone !== "" && address?.contactPhone !== undefined && !canadianPhoneRegExp.test(address?.contactPhone)) {
      toast.error("Invalid Canadian phone number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      const addressData: AddressBook = {
        address: address,
        companyName: companyName,
        deliveryNote: notes,
      };

      AddressService.createAddress(addressData)
        .then(res => {
          if (res.success) {
            setCompanyName(undefined);
            setAddress(undefined);
            setNotes(undefined);
            setIsModified(!isModified);

            NotificationService.getNotifications(5, 0).then(res => {
              if (res.count !== undefined) {
                setNotification(res.count);
              }
            });
            toast.success("Successfully added Address Details.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
            handlePopup();
          } else {
            toast.error("error occured", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleClose = () => {
    setCompanyName(undefined);
    setAddress(undefined);
    setNotes(undefined);
    setIsModified(!isModified);
    handlePopup();
  };

  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="xl">
        <ModalHeader toggle={handlePopup} close={closeBtn}>
          Add New Contacts
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <div className="tab-content">
                <TabContent className="body mt-3">
                  <TabPane>
                    <Form>
                      <Row>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label className={user?.isABusiness ? "" : "mb-3"}>
                              Company Name
                              {user?.isABusiness && <Label className="title-star">*</Label>}
                            </Label>
                            <input
                              type="text"
                              name="companyName"
                              value={companyName}
                              onChange={handleChange}
                              className={user?.isABusiness && error?.companyName ? "input-error form-control" : "form-control"}
                            />
                            {user?.isABusiness && error?.companyName && <p className="error">{error.companyName}</p>}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label>
                              Address <Label className="title-star">*</Label>
                            </Label>
                            <CustomGooglePlacesAutocomplete
                              apiKey={environment.google_maps_key}
                              autocompletionRequest={mapprops}
                              addressType={AddressType.pickup}
                              selectProps={{ name: "address" }}
                              handleAddress={handleAddress}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label>Unit Number</Label>
                            <input name="unit" type="text" value={address?.unit} onChange={handleChange} className="form-control" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label>
                              Contact Name <Label className="title-star">*</Label>
                            </Label>
                            <input
                              name="contactName"
                              type="text"
                              value={address?.contactName}
                              onChange={handleChange}
                              className={error?.contactName ? "input-error form-control" : "form-control"}
                            />
                            {error?.contactName && <p className="error">{error.contactName}</p>}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label>Contact Phone</Label>
                            <input
                              name="contactPhone"
                              type="text"
                              value={address?.contactPhone}
                              onChange={handleChange}
                              className={error?.contactPhone ? "input-error form-control" : "form-control"}
                            />
                            {error?.contactPhone && <p className="error">{error.contactPhone}</p>}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3 form-group">
                            <Label>Notes</Label>
                            <input name="notes" type="text" value={notes} onChange={handleChange} className="form-control" />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>

              <div className="actions clearfix">
                <button type="submit" onClick={handleSubmit} className="btn btn-primary px-5">
                  Save Contact
                </button>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreateAddress;
