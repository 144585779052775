import React, { Fragment,useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import download from "../../assets/images/download.svg";
import { OrderService } from "services/OrderService";
import { toast } from "react-toastify";
toast.configure()
import "react-toastify/dist/ReactToastify.css";
import { RequestState } from "enum/RequestState";
import OrderStatusModal from "modals/OderDetailsModal";
import { Tooltip } from "react-tooltip";
import { environment } from "environment/environment";
import Geocode from "react-geocode";
import "../../assets/css/custom.css"
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import "../../assets/css/table.css";

Geocode.setApiKey(environment.google_maps_key)
Geocode.setLanguage("en");
Geocode.setRegion("ca");

function HeaderFields({
  preGlobalFilteredRows,
  globalFilter,
  handleSearch,
  selectedColumns,
  handleColumnChange,
  setGlobalFilter,
  isJobListGlobalFilter,
  isAddressBook,
  selectedFlatRows,
  hancleDeleteRecords
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  
  const options = [
    { value: 10, label: '10 Rows' },
    { value: 15, label: '15 Rows' },
    { value: 20, label: '20 Rows' },
    { value: 25, label: '25 Rows' },
    { value: 50, label: '50 Rows' },
  ];

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
          {isAddressBook && (
            <button
              className="btn btn-primary bx bx-trash btn-sm px-2 px-2 me-lg-2 me-md-2 mb-2 mt-lg-1 mt-md-1 w-25 h-50"
              disabled={selectedFlatRows.length < 1}
              onClick={() => hancleDeleteRecords(selectedFlatRows)}
            ></button>
          )}
          <div className="search-box">
            <div className="position-relative">
              <label htmlFor="search-bar-0 " className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control border border-light-subtle rounded-3 py-2 px-5"
                  placeholder={`Search ..`}
                  value={value || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          <div className="search-box ps-lg-2 ps-md-2">
          <Select
            options={options}
            value={options.find((option) => option.value === selectedColumns)}
            onChange={handleColumnChange}
          />
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const  DriverTableContainer = ({
  columns,
  data,
  ispopup,
  isGlobalFilter,
  isJobListGlobalFilter,
  handleSearch,
  handleColumnChange,
  selectedColumns,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  isPDFGenerate,
  isAddressBook,
  hancleDeleteRecords,
  handleSelect,
  selectedID,
  isABusiness,
  currentPage,
  handlePageChange,
  pagelimit
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    hasPagination,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
        hiddenColumns: [!isABusiness ? "pickUpAddress.companyName" : ""]
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      isAddressBook && hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
    }
  );
  const [orderId, setOrderId] = useState();
  const [isClicked, setIsClicked] = useState(false);


  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const toggleOrderModalInput = (id) => {
    setOrderId(id);
    setIsClicked(isClicked => {
      if (!isClicked) {
        handleModal();
      }
      return !isClicked;
    });
  };

  // const getAddressCity = async (address) => { 
  //   let city
  //   const res = await Geocode.fromAddress(address);
  //   const add = res.results[0].address_components;
  //   for (let i = 0; i < add.length; i++) {
  //     for (let j = 0; j < add[i].types.length; j++) {
  //       if (add[i].types[j] === "locality") {
  //         city = add[i].long_name
  //       }
  //     }
  //   }
  //   return city
  // }

  // function GetCity({cityAddress}) {
  //   const [value, setValue] = React.useState("");
  //   getAddressCity(cityAddress).then((city) => {setValue(city)})
  //   return value
  // }
  return (
    <Fragment>
      <Row className="d-flex flex-row justify-content-end p-1">
        <HeaderFields
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          handleSearch ={handleSearch}
          handleColumnChange={handleColumnChange}
          selectedColumns={selectedColumns}
          setGlobalFilter={setGlobalFilter}
          isJobListGlobalFilter={isJobListGlobalFilter}
          isAddressBook={isAddressBook}
          selectedFlatRows={selectedFlatRows}
          hancleDeleteRecords={hancleDeleteRecords}
        />

        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleOrderClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded mb-2 me-2" onClick={handleCustomerClick}>
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <table bordered hover {...getTableProps()} className="newTable">
          <thead className="newThead">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.render("Header") === 'Weight (lbs)' ? "text-wrap" : "text-nowrap"}>
                    <div className="d-flex flex-row justify-content-center" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                  {row.cells.map((cell) => {
                    const dataLabel = cell.column.Header?.props?.children ? cell.column.Header?.props?.children : cell.column.Header; 
                      if (ispopup) {
                        return (
                          <td
                            onClick={() => (cell.column.Header === "Actions" ? "" : toggleOrderModalInput(row.original._id))}
                            key={cell.id}
                            {...cell.getCellProps()}
                            style={{ cursor: "pointer" }}
                            data-label={dataLabel}
                          >
                            <div data-tooltip-id={cell.column.Header + row.original._id}>
                              {cell.render("Cell")}
                            </div>
                            {(cell.column.Header === "Address") && (
                              <Tooltip
                                id={cell.column.Header + row.original._id}
                                content={row.original.address.city}
                                style={{ backgroundColor: "#FFEDE5", color: "#495057", width: 200 }}
                              />
                            )}
                          </td>
                        );
                      } else {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            <div>{cell.render("Cell")}</div>
                          </td>
                        );
                      }
                    })}
             
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
     
        {pagelimit > 1 &&
        <ReactPaginate
        pageCount={pagelimit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination justify-content-end align-items-end rounded-pill px-3"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
      } 
  
      </div>

    </Fragment>
    
  )
}

DriverTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DriverTableContainer
