import { AuthData, useAuth } from "context/AuthProvider"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardBody, Row } from "reactstrap"
import failureImg from "../../assets/images/payments/failure.png"

const PaymentFailed: React.FC = () => {
  const navigate = useNavigate()
  const [user] = useAuth() as AuthData

  return (
    <React.Fragment>
      <div className="payment-card-wrapper">
        <div className="p-3">
          <Card className="payment-card">
            <div className="card-header">
              <Row>
                <div className="image-container">
                  <img src={failureImg} className="image" />
                </div>
              </Row>
            </div>
            <CardBody className="px-5 py-3">
              <Row className="mb-3 mt-5">
                <h4 className="text-muted text-center">Payment Failed</h4>
                <p className="text-muted text-center font-size-16">Your payment was not successful.</p>
              </Row>
              <Row className="mb-3">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#34C38F", maxWidth: "50%" }}
                    onClick={() => navigate(`/${user?.role}/place-orders`)}
                  >
                    {" "}
                    Go back
                  </button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="d-flex justify-content-center">© 2023 ZuluCourier.</div>
      </div>
    </React.Fragment>
  )
}

export default PaymentFailed
