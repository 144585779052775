/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Util } from "Util";
import axios from "axios";
import { Notification, NotificationResponse } from "models/Notification";
import { AppResponse } from "models/Response";

export class NotificationService {
  public static async getNotifications(limit?: number, offset?: number): Promise<AppResponse<NotificationResponse[]>> {
    const ep = Util.apiAuthUrl(`getNotifications/${limit}/${offset}`);
    const withoutDoubleSlash = ep.replace("/auth//", "/auth/"); // Replace // with /
    const response = await axios.get<AppResponse<NotificationResponse[]>>(withoutDoubleSlash);

    // Return the received response directly
    return response.data;
  }

  public static async updateNotification(notificationId: string): Promise<AppResponse<Notification>> {
    const ep = Util.apiAuthUrl(`updateNotification/${notificationId}`);
    const res = await axios.post<void, AppResponse<Notification>>(ep);
    return res;
  }

  public static async getNotificationsByDisplayOrder(data: any): Promise<AppResponse<Notification[]>> {
    const url = Util.apiAuthUrl(`getNotifications/display-order`);
    return await axios.post<void, AppResponse<Notification[]>>(url, data);
  }

  public static async deleteNotification(notificationId: string): Promise<AppResponse<Notification>> {
    const ep = Util.apiAuthUrl(`deleteNotification/` + notificationId);
    const res = await axios.delete<void, AppResponse<Notification>>(ep);
    return res;
  }
}
