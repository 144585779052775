import { Select } from "antd";
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { Radio } from "antd";

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  handleEditUpdate: () => void;
  handleAmount: (value: string) => void;
  handleDiscount: (value: string) => void;
  handlePayment: (itemName: string) => void;
  handleStatus: (itemName: string) => void;
}

function FinanceUpdateStatus(props: ModalProps) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Select Payment method");
  const [selectedStatusMethod, setSelectedStatus] = useState("Select Status");
  const [isDiscount, setIsDiscount] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handlePaymentItemClick = (itemName: string) => {
    props.handlePayment(itemName);
    setSelectedPaymentMethod(itemName);
  };

  const handleStatusItemClick = (itemName: string) => {
    props.handleStatus(itemName);
    setSelectedStatus(itemName);
  };

  const handleInputChange = (value: string) => {
   
    setInputValue(value);
  };

  const handleBlur = () => {
     
    const valueToHandle = inputValue.trim() !== "" ? inputValue : "0";
    isDiscount ? props.handleDiscount(valueToHandle) : props.handleAmount(valueToHandle);
  };

  const handleClick = () => {
   
    setInputValue("");
    setSelectedPaymentMethod("Select Payment method");
    setSelectedStatus("Select Status");
    props.handleEditUpdate();
    
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          <Row>
            <Col>
              <p className="text-left">Update Details</p>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Row className="pt-2 my-2">
              <div className="row mt-2">
                <div className="col-lg-12" style={{ marginLeft: "4%" }}>
                  <label className="place-order-title me-4">Select to update?</label>
                  <Radio.Group
                    value={isDiscount ? "discount" : "amount"}
                    onChange={e => setIsDiscount(e.target.value === "discount")}
                    name="isBusiness"
                  >
                    <Radio value="amount">New amount</Radio>
                    <Radio value="discount">Discount</Radio>
                  </Radio.Group>
                </div>
                <div
                  className="mt-3"
                  style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}
                >
                  <Col lg="3">{isDiscount ? "Discount Amount" : "New Amount"}</Col>
                  <Col lg="7">
                    <input
                      onChange={e => handleInputChange(e.target.value)}
                      onBlur={handleBlur}
                      id="search-bar-0"
                      type="number"
                      className="border border-light-subtle rounded-3 py-2 px-3"
                      placeholder={isDiscount ? "Enter discount amount" : "Enter new amount"}
                      style={{ marginLeft: "4%" }}
                      value={inputValue}
                    />
                  </Col>
                </div>
              </div>
            </Row>

            <Row
              className="pt-2 my-2"
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}
            >
              <Col lg="3">Payment Method</Col>
              <Col lg="7">
                <Select
                  value={selectedPaymentMethod}
                  className="form-select"
                  placeholder="Select Card Type"
                  defaultValue={""}
                  onChange={handlePaymentItemClick}
                >
                  <option value={"cheque"} className="place-order-title">
                    Cheque
                  </option>
                  <option value={"transfer"}>Transfer</option>
                  <option value={"card"}>Card</option>
                </Select>
              </Col>
            </Row>

            <Row
              className="pt-2 my-2"
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}
            >
              <Col lg="3">Status</Col>
              <Col lg="7">
                <Select
                  value={selectedStatusMethod}
                  className="form-select"
                  placeholder="Select status"
                  defaultValue={""}
                  onChange={handleStatusItemClick}
                >
                  <option value={"paid"} className="place-order-title">
                    Paid
                  </option>
                  <option value={"overdue"}>Overdue</option>
                  <option value={"pending"}>Pending</option>
                </Select>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handleClick} size="sm">
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FinanceUpdateStatus;
