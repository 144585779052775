import { DriverCommission } from "models/DriverCommission";
import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { format } from "date-fns";
import { GeneratePDF } from "./GeneratePDF";

export interface Props {
  isOpen: boolean;
  handlePopup: () => void;
  driverBill?: DriverCommission;
  dates: any;
}

const DriverInvoiceView: React.FC<Props> = ({ isOpen, handlePopup, driverBill, dates }: Props) => {
  const handleDownload = () => {
    const biilData: DriverCommission = {
      _id: driverBill?._id,
      totalOrders: driverBill?.totalOrders,
      orderDetails: driverBill?.orderDetails,
      driverDetails: driverBill?.driverDetails,
    };
    GeneratePDF(biilData);
  };

  const handleClose = () => {
    handlePopup();
  };
  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="lg" backdrop="static" style={{ maxWidth: "1200px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}></ModalHeader>
        <ModalBody className="px-lg-5">
          <h4 className="bg-primary bg-opacity-25 popover-header py-2 text-center">Invoice</h4>
          <div className="mx-lg-3 px-lg-5 small">
            <Row className="pt-4">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Invoice Number
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{driverBill?.zuluId}</Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Date Range
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">:</Col>
              <Col xs="5" sm="5" lg="7">
                {dates.date1 !== undefined ? format(new Date(dates.date1), "MMMM do") : ""} to{" "}
                {dates.date2 !== undefined ? format(new Date(dates.date2), "MMMM do") : ""}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Driver ID
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{driverBill?.driverDetails?.customerId}</Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Driver Name
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">
                {driverBill?.driverDetails?.firstName} {driverBill?.driverDetails?.lastName}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Total No of Orders
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{driverBill?.totalOrders?.toString().padStart(2, "0")}</Col>
            </Row>
          </div>

          <div className="table-responsive react-table mx-lg-3 px-lg-5 small mt-5">
            <table className="newTable">
              <thead className="newThead">
                <tr>
                  <th>Order Id</th>
                  <th>Pick-up</th>
                  <th>Delivery</th>
                  <th>No of Items</th>
                  <th className="text-center">Order value</th>
                  <th className="text-center">Commision Earned</th>
                </tr>
              </thead>
              <tbody>
                {driverBill?.orderDetails?.map((ord, index) => {
                  return (
                    <tr key={ord._id}>
                      <td data-label="Order Id">{ord.uniqueId}</td>
                      <td data-label="Pick-up">{ord.pickUpAddress?.city}</td>
                      <td data-label="Delivery">{ord.deliveryAddress?.city}</td>
                      <td data-label="No of Items">
                        {ord.totalItems} {ord.totalItems === "1" ? "Pc" : "Pcs"}
                      </td>
                      <td data-label="Order value" className="text-end">${ord.subTotal?.toFixed(2)}</td>
                      <td data-label="Commision Earned" className="text-end">
                        ${driverBill?.driverDetails?.percentage !== undefined && ord?.subTotal !== undefined ? 
                          ((parseFloat(driverBill?.driverDetails?.percentage) / 100) * ord?.subTotal).toFixed(2) :
                          ""}
                      </td>
                    </tr>
                  );
                })}
                <tr className="large_screen_visibilty">
                  <th colSpan={4} className="bg-primary bg-opacity-25">
                    Total Value
                  </th>
                  <th className="text-end bg-primary bg-opacity-25">${driverBill?.totalOrderAmount?.toFixed(2)}</th>
                  <th className="text-end bg-primary bg-opacity-25">${driverBill?.totalCommision?.toFixed(2)}</th>
                </tr>

                <tr className="small_screen_visibilty">
                  <th colSpan={4} className="bg-primary bg-opacity-25">
                    Total Value
                  </th>
                  <th className="text-end bg-primary bg-opacity-25">
                    <tr data-label="Order Value" className="d-flex justify-content-between align-items-center"><span>Order Value</span> <span> ${driverBill?.totalOrderAmount?.toFixed(2)}</span></tr>
                    <tr data-label="Commision Earned" className="d-flex justify-content-between align-items-center"><span>Commision Earned</span> <span> ${driverBill?.totalCommision?.toFixed(2)}</span></tr>
                   </th>
                </tr>
              </tbody>
            </table>
            {/* {driverBill?.orderDetails?.map((ord, index) => {
                return (
                  <Row key={ord._id} className="pt-2 border-bottom">
                    <Col lg="6" md="6" sm="6">
                      <div>{ord.uniqueId} ({ord.totalItems} {ord.totalItems === "1" ? "Pc" : "Pcs"})</div>
                    </Col>
                    <Col lg="2" md="6" sm="6">
                      <div className="text-center">${(ord.totalFare)?.toFixed(2)}</div>
                    </Col>
                  </Row>
                )
              })}
              <Row className="my-5">
                <Col lg="6" md="6" sm="6" className="bg-primary bg-opacity-25 py-2 fw-semibold"><div>Total Order Value</div></Col>
                <Col lg="2" md="6" sm="6" className="bg-primary bg-opacity-25 py-2 text-center">${driverBill?.totalOrderAmount?.toFixed(2)}</Col>
                <Col lg="4" className="mt-2 mt-lg-0">
                  
                </Col>
              </Row> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button type="submit" onClick={handleDownload} className="btn btn-primary btn-sm px-3 ms-lg-4">
              Download PDF
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DriverInvoiceView;
