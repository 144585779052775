import * as yup from "yup"

const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;



export const placeOrderSchema = yup.object().shape({
  pickupContactPhone: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number"),
  pickupContactName: yup.string().required("Required"),
  deliverContactPhone: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number"),
  deliverContactName: yup.string().required("Required"),
  vehicleType: yup.string().required("Required"),
  totalWeight: yup.number().required("Required").positive("Value cannot be negative"),
  deliveryOption: yup.string().required("Required"),
  companyName: yup.string().required("Required"),
  totalItems: yup.number().required("Required").positive("Value cannot be negative"),
})

export const personalPlaceOrderSchema = yup.object().shape({
  pickupContactPhone: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number"),
  pickupContactName: yup.string().required("Required"),
  deliverContactPhone: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number"),
  deliverContactName: yup.string().required("Required"),
  vehicleType: yup.string().required("Required"),
  totalWeight: yup.number().required("Required").positive("Value cannot be negative"),
  deliveryOption: yup.string().required("Required"),
  totalItems: yup.number().required("Required").positive("Value cannot be negative"),
})