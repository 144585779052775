import { AllDispachCount } from "models/AllDispachCount";
import React, { useContext, useState } from "react";

export type DispachCountData = [AllDispachCount | undefined, (dispachCount: AllDispachCount) => void];

const DispachCountContext = React.createContext<DispachCountData>([undefined, () => {}]);

export const DispachCountProvider = ({ children }: any) => {
  const [dispachCount, setDispachCount] = useState<AllDispachCount | undefined>(undefined);
  return <DispachCountContext.Provider value={[dispachCount, setDispachCount]}>{children}</DispachCountContext.Provider>;
};

export const useDispachCount = () => {
  return useContext(DispachCountContext);
};
