import React from "react";

const TermsOfService: React.FC = () => {

    document.title = "Terms of Service | Zulu Courier ";
    return(
        <>
            <div className="container">
                <section className="my-4">
                    <div className="position-relative">
                        <div className="row g-5 w-100 mt-5">
                            <div className="col-lg-12">
                                {/* <a href="/signup">Back to Sign Up</a> */}
                            </div>
                        </div>
                        <div className="row g-5 w-100 mb-4 mt-2">
                            <div className="col-lg-12">
                                <h2>Terms and Conditions At  ZuluCourier: </h2>
                            </div>
                        </div>
                        <div className="row g-5 w-100">
                            <div className="col-lg-12">
                                <p><b>Acceptance of Terms:</b> By availing the services provided by our courier company, you agree to be bound by these Terms and Conditions. These terms govern the relationship between the courier company (referred to as "we," "our," or "us") and the customer ("you" or "your") regarding the delivery of packages and any related services.</p>
                                <p><b>Service Description:</b> We provide courier services for the pickup, transportation, and delivery of packages within the designated areas. Our services may include same-day delivery, express delivery, and other options as specified during the booking process. The specific terms and limitations of each service will be communicated to you at the time of booking.</p>
                                <p><b>Booking and Delivery:</b> You may book our services through our website, or by contacting our customer service. It is your responsibility to provide accurate and complete information regarding the pickup and delivery details, including addresses, contact information, and package dimensions. Any changes or modifications to the booking should be communicated to us promptly.</p>
                                <p><b>Packaging and Labeling:</b> It is your responsibility to ensure that packages are properly packaged, labeled, and secured. We are not liable for any damage or loss caused by improper packaging or labeling. It is recommended to follow industry standards and guidelines for packaging fragile or sensitive items.</p>
                                <p><b>Delivery Timeframes:</b> While we make every effort to meet the estimated delivery timeframes provided, these timeframes are not guaranteed. Factors such as weather conditions, traffic, or other unforeseen circumstances may cause delays in the delivery process. We will not be held responsible for any losses or damages arising from such delays.</p>
                                <p><b>Liability and Insurance:</b> We take reasonable care in handling and transporting packages. However, our liability for loss, damage, or delay of packages is limited unless additional insurance is purchased. It is your responsibility to inquire about insurance options and coverage limits. We recommend securing appropriate insurance coverage for valuable or high-risk items.</p>
                                <p><b>Prohibited Items:</b> Certain items are prohibited from being shipped through our services. It is your responsibility to ensure that the packages do not contain any illegal, dangerous, or prohibited items as per local, national, and international laws and regulations. We reserve the right to refuse the transportation of any such items.</p>
                                <p><b>Payment and Charges:</b> Payment for our services is due at the time of booking, unless otherwise agreed upon. The charges for our services may vary based on factors such as package weight, size, distance, and service level. Additional charges may apply for services such as special handling, insurance, or customs clearance. Any unpaid fees may result in the delay or suspension of services.</p>
                                <p><b>Privacy and Data Protection:</b> We respect your privacy and handle personal information in accordance with applicable data protection laws. We collect and process personal data necessary for the provision of our services and may use it for communication, record-keeping, and service improvement purposes. </p>
                                <p><b>Termination of Services:</b> We reserve the right to refuse or terminate services to any customer at our discretion, without prior notice, if we believe that the customer's actions or requests may violate these Terms and Conditions, pose a risk to our staff, or breach applicable laws and regulations.</p>
                                <p><b>Governing Law and Jurisdiction:</b> These Terms and Conditions shall be governed by and construed in accordance with the laws of Ontario. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
                                <p><b>Amendments:</b> We reserve the right to modify or amend these Terms and Conditions at any time. Any changes will be effective upon posting on our website or other communication channels. It is your responsibility to review these terms periodically for updates.</p>
                                <p>By engaging our courier services, you acknowledge that you agree with the above terms and conditions.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default TermsOfService;