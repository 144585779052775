import React, { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import emailImg from "../../assets/images/email.png";

const EmailVerificationCard: React.FC<{
    email: string;
    onSubmit: (otp: string, isClick: boolean) => void;
    onResendOtp: () => void;
}> = ({ email, onSubmit, onResendOtp }) => {
    const [otp, setOtp] = useState("");
    const [isClick] = useState(false);

 
    return (
        <React.Fragment>
            <div className="payment-card-wrapper">
                <div className="p-3">
                    <Card className="payment-card">
                        <div className="card-header">
                            <Row>
                                <div className="image-container">
                                    <img src={emailImg} className="image" alt="Email Verification" />
                                </div>
                            </Row>
                        </div>
                        <CardBody className="px-5 py-3">
                            <Row className="mb-3 mt-5">
                                <h4 className="text-muted text-center">Verify your email</h4>
                                <p className="text-muted text-center font-size-16">We have sent you a verification email to {email}. Please check your inbox.</p>
                                <div className="form-group mt-4">
                                    <input
                                        type="text"
                                        name="otp"
                                        id="otp"
                                        className="form-control"
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={(e)=>setOtp(e.target.value)}
                                    />
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary"
                                        style={{ backgroundColor: "#34C38F", maxWidth: "50%" }}
                                        onClick={()=>onSubmit(otp, isClick)}
                                    >
                                        {" "}
                                        Verify Email
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <label>
                    Didn't receive an email?{" "}
                    <button
                        className="btn btn-link p-0"
                        style={{ backgroundColor: "transparent", border: "none", color: "#34C38F", maxWidth: "50%", textDecoration: "none", cursor: "pointer" }}
                        onClick={()=>onResendOtp()}
                    >
                        Resend
                    </button>
                </label>



            </div>
        </React.Fragment>
    );
};

export default EmailVerificationCard;
