import React, { useState } from "react";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { AdminService } from "services/AdminService";
import { OrderRequest } from "models/Admin";
import { OrderStatus } from "enum/OrderStatus";
import { useDispachCount } from "context/OrderCountProvider";

export type InputValueType = { uniqueId?: string; _id?: string };

export interface Props {
  isOpen: boolean;
  handlePopup: () => void;
  value?: InputValueType;
  setValue: (value: InputValueType) => void;
  setOptions: (value: InputValueType[]) => void;
}

const CancelOrder: React.FC<Props> = ({ isOpen, handlePopup, value, setValue, setOptions }: Props) => {

  const [reason, setReason] = useState<string>();
  const [reasonErr, setReasonErr] = useState<string>();
  const [dispachCount, setDispachCount] = useDispachCount();

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "reason":
        if (value == "" || value == null || value == undefined) {
          setReasonErr("Required");
        } else {
          setReasonErr(undefined);
        }
        setReason(value);
        break;
      default:
        break;
    }
  }

  const handleCancel = () => {
    if (reason == "" || reason == undefined) {
      toast.error("Reason is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: {marginBottom: "4rem"},
      })
    } else {
      const orderData: OrderRequest = {
        orderId: value?._id,
        status: OrderStatus.CANCELED,
        reason: reason
      }

      AdminService.cancelOrder(orderData).then(res => {
        if (res.success) {
          toast.success("Successfully order cancelled.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          })
          setReason(undefined);
          setValue({uniqueId: "", _id:""});
          setOptions([]);
          AdminService.getalldispachcount().then(res => {
            if (res.success) {
              setDispachCount(res.data);
            } else {
            }
          });
          handlePopup();
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
    }
  }

  const handleClose = () => {
    setReason(undefined);
    setValue({uniqueId: "", _id:""});
    setOptions([]);
    handlePopup();
  }
  const closeBtn = (
    <button onClick={handleClose} type="button" className="btn-close"/>
  );

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="lg" backdrop="static">
        <ModalHeader toggle={handlePopup} close={closeBtn}>Cancel Order</ModalHeader>
        <ModalBody>
        <div className="content clearfix">
            <div className="mb-3 form-group">
              <Label>Order ID</Label>
              <input
                type="text"
                name="orderId"
                value={value?.uniqueId}
                readOnly
                className="form-control"
              />
            </div>
            <div className="mb-3 form-group">
              <Label>Reason</Label>
              <textarea name="reason" onChange={handleChange} className={reasonErr ? "input-error form-control" : "form-control"}>
                {reason}
              </textarea>
              {reasonErr && (<p className="error">{reasonErr}</p>)}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary btn-sm px-5 py-2 mt-2 rounded-1 w-100" onClick={handleCancel}>
            Cancel Order
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default CancelOrder;