import React from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import { toast } from "react-toastify"
toast.configure()
import "react-toastify/dist/ReactToastify.css"
import Copyright from "components/Common/copyright"
import confirmImage from "../../assets/images/forget password/conform.svg"


const PasswordReset: React.FC = () => {

    const handlePasswordReset = () => {
        navigate('/signin');
    };
    const navigate = useNavigate()

    return (
        <div className="container">
            <section className="my-4">
                <div className="position-relative">
                    <div className="row g-5 justify-content-center sign-form">
                        <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                            <div className="px-4 py-5 w-100">
                                <div className="sign-logo mb-3">
                                    <a href="/#">
                                        <img src={logo} alt="" />
                                        <img src={confirmImage} alt="" />
                                    </a>
                                </div>
                                <h1 className="title mb-1">Password  Reset</h1>
                                <p className="mb-4">
                                    Your password  has been successfully  reset. Click below to login.
                                </p>
                                <div className="form-group">
                                    <button
                                        onClick={handlePasswordReset}
                                        className="btn btn-md bg-orange text-white w-100 mt-4 button-bg">
                                        Continue
                                    </button>
                                </div>
                                <div className="w-100 text-center mt-5">
                                    <Copyright />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PasswordReset;