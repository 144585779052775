import { AddressBook } from "models/AddressBook"
import React, { useMemo, useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { AddressService } from "services/AddressService"
import "../../assets/css/custom.css"
import CreateAddress from "./CreateAddress"
import Swal from "sweetalert2"
import TableContainer from "components/Common/TableContainer"
import { NotificationData, useNotification } from "context/NotificationProvider"
import { NotificationService } from "services/NotificationService"

const TableContent: React.FC = () => {
  const [addressBooks, setAddressBooks] = useState([] as AddressBook[])
  const [modal, setModal] = useState(false)
  const [isModified, setIsModified] = useState<boolean>(false);
  const [notification, setNotification] = useNotification() as NotificationData;

  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Company Address",
        accessor: "address.city",
      },
      {
        Header: "Contact Name",
        accessor: "address.contactName",
      },
      {
        Header: "Contact Phone",
        accessor: "address.contactPhone",
        Cell: ({ value }: any) => value !== null && value !== undefined && value !== "" ? value.split("+").pop() : "",
      },
      {
        Header: "Notes",
        accessor: "deliveryNote",
      },
    ],
    []
  )

  useEffect(() => {
    AddressService.getAddressBookList(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setAddressBooks(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    })
  }, [isModified]);

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AddressService.getAddressBookList(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setAddressBooks(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AddressService.getAddressBookList(selectedOption.value, 1).then(res => {
      if (res.success) {
        setAddressBooks(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      searchableString: serchText,
    };
    AddressService.getAddressBooksBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setAddressBooks(res.data);
        } else {
        }
      }
    });
  };

  const hancleDeleteRecords = (recordList: any[]) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F75D21",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {

        addressBooks.filter((address) => {
          recordList.some(record => {
            if (record.original._id == address?._id) {
              AddressService.deleteAddress(address?._id).then(res => {
                if (res.success) {
                  
                  isOffset_p_c - 1 !== 0 && addressBooks.length === 1 ? setOffset_p_c((isOffset_p_c - 1)) : "";

                  setIsModified(!isModified);
                  NotificationService.getNotifications(5, 0).then(res => {
                    if (res.count !== undefined) {
                      setNotification(res.count);
                    }
                  });
                  Swal.fire({
                    icon: "success",
                    text: "Address Removed",
                    confirmButtonColor: "#F75D21",
                  })
                } else {
                  Swal.fire({
                    icon: "error",
                    title: res.error,
                    confirmButtonColor: "#F75D21",
                  })
                }
              })
            }
          })
        })
      }
    })
  }

  const handlePopup = () => {
    setModal(!modal)
  }

  return (
    <Col lg={12}>
      <Card>
        <CardBody>
          <div className="clearfix">
            <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
              <button className="btn btn-primary btn-sm px-5 me-lg-2 me-md-2 mb-2 ms-1" onClick={handlePopup}>
                Add Address
              </button>
            </div>
          </div>

          {addressBooks.length > 0 ? (
            <TableContainer
              columns={columns}
              data={addressBooks}
              currentPage={undefined}
              pagelimit={pageCount}
              handlePageChange={SeeMore}
              handleColumnChange={handleColumnChange}
              selectedColumns={LIMIT_P_C}
              showColumnChange={true}
              handleSearch={searchTable}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={50}
              className="custom-header-css"
              isJobListGlobalFilter={undefined}
              isAddUserList={undefined}
              handleOrderClicks={undefined}
              handleUserClick={undefined}
              handleCustomerClick={undefined}
              isAddCustList={undefined}
              isPDFGenerate={false}
              ispopup={false}
              isAddressBook={true}
              hancleDeleteRecords={hancleDeleteRecords}
              handleSelect={undefined}
              selectedID={undefined}
              hasCheckBox={false}
              handleSendEmail={undefined}
            />
          ): (
            <Row className="justify-content-md-center justify-content-center align-items-center">
              <Col className="col-md-auto">
                <div>No Address Books</div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      <CreateAddress isOpen={modal} handlePopup={handlePopup} isModified={isModified} setIsModified={setIsModified} />
    </Col>
  )
}

export default TableContent
