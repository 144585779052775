import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row } from "reactstrap";
import Breadcrumb from "components/Common/Breadcrumb";
import profileImg from "../../assets/images/profile-img.png";
import OperatingHoursView from "./OperatingHours";
import { AuthData, useAuth } from "context/AuthProvider";
import { OrderService } from "services/OrderService";
import { Radio } from "antd";
import { Notes } from "models/Notes";
import { NoteService } from "services/NoteService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "services/AuthService";
import { UserTypes } from "enum/UserTypes";
import { User } from "models/User";
import { AddressModel } from "models/City";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { environment } from "environment/environment";
import { AddressType } from "enum/AddressType";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { ImageData, useImage } from "context/ProfileImageProvider";
import { useNavigate } from "react-router-dom";
import { PaymentOption } from "enum/PaymentOption";
import Swal from "sweetalert2";

const getInitials = (name: any) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

const createImageFromInitials = (size: number, name: any, color: any) => {
  if (name == null) return;
  name = getInitials(name);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context!.fillStyle = color;
  context!.fillRect(0, 0, size, size);

  context!.fillStyle = "#ffffff";
  context!.textBaseline = "middle";
  context!.textAlign = "center";
  context!.font = `${size / 2}px Roboto`;
  context!.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

const Profile: React.FC = () => {
  const [user, setUser] = useAuth() as AuthData;
  const [orderCount, setOrderCount] = useState<number>();
  const [file, setFile] = useState<any>();
  const [image, setImage] = useImage() as ImageData;
  const [message, setMessage] = useState<string>();
  const [notes, setNotes] = useState([] as Notes[]);

  const [error, setError] = useState<User>();
  const [address, setAddress] = useState<AddressModel | undefined>(user?.address);
  const [addressError, setAddressError] = useState<AddressModel>();
  const [geoAddress, setGeoAddress] = useState(false);

  const [firstName, setFirstName] = useState<string | undefined>(user?.firstName);
  const [lastName, setLastName] = useState<string | undefined>(user?.lastName);
  const [isABusiness, setIsABusiness] = useState<boolean | undefined>(user?.isABusiness);
  const [role, setRole] = useState<UserTypes | undefined>(user?.role);
  const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(user?.paymentOption);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(user?.phoneNumber);
  const [companyName, setCompanyName] = useState<string | undefined>(user?.address?.companyName);
  const [city, setCity] = useState<string | undefined>(user?.address?.city);
  const reader = new FileReader();
  const navigate = useNavigate();
  const [isModified, setIsModified] = useState(false);

  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };

  useEffect(() => {
    OrderService.getOrderCountByUserId(user?._id).then(res => {
      setOrderCount(res.data.count);
    });

    getNoteList();
  }, [isModified]);

  const handleImage = (event: any) => {
    setFile(event.target.files[0]);

    reader.onload = e => {
      const { result }: any = e.target;
      if (result) {
        setImage({ ...image, dataURL: result });
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "firstName":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, firstName: "Required" });
          toast.error("First Name is Required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setError({ ...error, firstName: "Only letters are allowed!" });
        } else {
          setError({ ...error, firstName: undefined });
        }
        setFirstName(value);
        break;
      case "lastName":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, lastName: "Required" });
          toast.error("Last Name is Required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setError({ ...error, lastName: "Only letters are allowed!" });
        } else {
          setError({ ...error, lastName: undefined });
        }
        setLastName(value);
        break;
      case "address":
        if (value != "" || value == undefined) {
          setGeoAddress(true);
        }
        break;
      case "phoneNumber":
        if (value == "" || value == undefined || value == null) {
          setError({ ...error, phoneNumber: "Required" });
          toast.error("Phone Number is Required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else if (!/^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/.test(value)) {
          setError({ ...error, phoneNumber: "Invalid Canadian phone number" });
        } else {
          setError({ ...error, phoneNumber: undefined });
        }
        setPhoneNumber(value);
        break;
      case "isBusiness":
        OrderService.getPendingStatusOfOrders(user?._id).then(res => {
          if (res.success && res.data === false) {
            setIsABusiness(value);
            if (value == true) {
              setRole(UserTypes.BUSINESS_CUSTOMER);
              setPaymentOption(PaymentOption.MONTHLY);
            } else {
              setRole(UserTypes.PERSONAL_CUSTOMER);
              setPaymentOption(PaymentOption.SINGLE);
            }
          } else {
            toast.error("There are some pending payments, Please try again after settle it down.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        });
        break;
      case "companyName":
        if (value == "" || value == undefined || value == null) {
          setAddressError({ ...error, companyName: "Required" });
          toast.error("Company Name is Required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        } else {
          setAddressError({ ...error, companyName: undefined });
        }
        setCompanyName(value);
        setAddress({ ...address, companyName: value });
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleSubmit = () => {
    if (city == "" || city == undefined) {
      setAddressError({ ...addressError, city: "Address is Required" });
      toast.error("Address is Required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (isABusiness && (companyName == "" || companyName == undefined)) {
      setAddressError({ ...addressError, companyName: "Company Name is Required" });
      toast.error("Company Name is Required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      if (message !== "" && message !== undefined && message !== null) {
        const noteData: Notes = {
          note: message,
          userId: user?._id,
        };

        NoteService.createNote(noteData)
          .then(res => {
            if (res.success) {
              setMessage("");
              getNoteList();
            }
          })
          .catch(error => {
            console.error(error);
          });
      }

      const formData = new FormData();
      formData.append("profileImage", file);
      firstName !== undefined && formData.append("firstName", firstName);
      lastName !== undefined && formData.append("lastName", lastName);
      phoneNumber !== undefined && formData.append("phoneNumber", phoneNumber);
      isABusiness !== undefined && formData.append("isABusiness", isABusiness.toString());
      role !== undefined && formData.append("role", role);
      paymentOption != undefined && formData.append("paymentOption", paymentOption);
      address?.city !== undefined && formData.append("city", address.city);
      address?.lat !== undefined && formData.append("lat", address.lat.toString());
      address?.lng !== undefined && formData.append("lng", address.lng.toString());
      address?.companyName !== undefined && formData.append("companyName", address.companyName);
      formData.append("contactName", firstName + " " + lastName);
      phoneNumber !== undefined && formData.append("contactPhone", phoneNumber);
      address?.postalCode !== undefined && formData.append("postalCode", address.postalCode);
      address?.unit !== undefined && formData.append("unit", address.unit);

      AuthService.uploadImage(formData)
        .then(res => {
          if (res.success) {
            setUser({
              ...user,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phoneNumber: res.data.phoneNumber,
              isABusiness: res.data.isABusiness,
              role: res.data.role,
              paymentOption: res.data.paymentOption,
              address: res.data.address,
              photoId: res.data.photoId,
            });
            toast.success("Successfully updated.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
            navigate(`/${res.data.role}/profile-settings`);
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const getNoteList = () => {
    NoteService.getNoteList().then(res => {
      if (res.success) {
        setNotes(res.data);
      }
    });
  };

  const handleDeleteNote = (nodeId?: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F75D21",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        NoteService.deleteNote(nodeId).then(res => {
          if (res.success) {
            setIsModified(!isModified);
          } else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#F75D21",
            });
          }
        });
      }
    });
  }

  const clearAddress = () => {
    setGeoAddress(true);
    setCity("");
  };

  document.title = "Profile | Zulu Courier ";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profile" breadcrumbItem="Profile" />
          <Row>
            <Col xl={4}>
              <Row>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="font-size-14">ZuluCourier Dashboard</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="6">
                        <Row>
                          <Col sm="10">
                            <div className="profile-user-wid mb-4">
                              {image ? (
                                <img
                                  src={image.dataURL}
                                  alt="Profile Image"
                                  className="border-3 rounded-circle"
                                  style={{ width: 170, height: 170 }}
                                ></img>
                              ) : (
                                <img
                                  id="preview"
                                  src={createImageFromInitials(200, user?.firstName, "#ff115a")}
                                  alt="profile-pic"
                                  className="img-fluid rounded-circle"
                                />
                              )}
                            </div>
                          </Col>
                          <Col sm="2" className="d-flex justify-content-end pt-lg-5 pt-md-5">
                            <label htmlFor="file">
                              <span className="fa fa-edit edit-icon pt-lg-5 pt-md-5"> </span>
                            </label>
                            <input type="file" id="file" onChange={handleImage} className="d-none" accept="image/*" />
                          </Col>
                        </Row>
                        <p className="font-size-17 fw-medium text-Poppins">
                          {user?.firstName} {user?.lastName}
                        </p>
                        <p className="font-size-15 fw-normal text-Poppins">
                          {user?.isABusiness === true ? user?.address?.companyName : ""}
                        </p>
                      </Col>

                      <Col sm="6" className="d-flex justify-content-center mt-lg-5 mt-md-5">
                        <div className="pt-4">
                          <h5 className="font-size-15 text-center">{orderCount}</h5>
                          <p className="text-muted mb-0 text-center">Orders</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>

              <Row>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Personal Details</CardTitle>

                    <Form>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">First Name</Label>
                        <Input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={handleChange}
                          className={error?.firstName ? "input-error form-control" : "form-control"}
                        />
                        {error?.firstName !== undefined && <p className="error col-lg-11">{error.firstName}</p>}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-lastname-Input">Last Name</Label>
                        <Input
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={handleChange}
                          className={error?.lastName ? "input-error form-control" : "form-control"}
                        />
                        {error?.lastName !== undefined && <p className="error col-lg-11">{error.lastName}</p>}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Email</Label>
                        <Input type="text" className="form-control" value={user?.email} readOnly />
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-address-Input">Permanent Address</Label>
                        {geoAddress || city == undefined ? (
                          <CustomGooglePlacesAutocomplete
                            apiKey={environment.google_maps_key}
                            autocompletionRequest={mapprops}
                            addressType={AddressType.pickup}
                            selectProps={{
                              name: "address",
                            }}
                            handleAddress={handleAddress}
                          />
                        ) : (
                          <textarea
                            name="address"
                            value={city}
                            //onMouseEnter={handleChange}
                            onSelect={() => clearAddress()}
                            className={addressError?.city ? "input-error form-control" : "form-control"}
                          >
                            {user?.address?.city}
                          </textarea>
                        )}
                        {addressError?.city && <p className="error col-lg-11 place-order-title">{addressError.city}</p>}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-phonenumber-Input">Phone Number</Label>
                        <Input
                          type="text"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={handleChange}
                          className={error?.phoneNumber ? "input-error form-control" : "form-control"}
                        />
                        {error?.phoneNumber !== undefined && <p className="error col-lg-11">{error.phoneNumber}</p>}
                      </div>

                      {/* <div className="mb-3">
                        <Label htmlFor="formrow-isabusiness-Input" className="me-2">
                          Are You A Business?
                        </Label>
                        <Radio.Group value={isABusiness} onChange={handleChange} name="isBusiness">
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </div>

                      {isABusiness && (
                        <div className="mb-3">
                          <Label htmlFor="formrow-companyname-Input">Business Name</Label>
                          <Input
                            type="text"
                            name="companyName"
                            value={companyName}
                            onChange={handleChange}
                            className={addressError?.companyName ? "input-error form-control" : "form-control"}
                          />
                          {addressError?.companyName !== undefined && <p className="error col-lg-11">{addressError?.companyName}</p>}
                        </div>
                      )} */}
                    </Form>
                  </CardBody>
                </Card>
              </Row>
            </Col>

            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Notes</CardTitle>
                  <Col lg={12} className="mb-3">
                    <div className="mb-3">
                      <label htmlFor="note">Create Note</label>
                      <textarea
                        name="message"
                        value={message}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter Your Note"
                      ></textarea>
                    </div>
                    <div className="d-flex flex-wrap flex-row-reverse border-bottom mb-3">
                      <button className="btn btn-primary btn-sm mb-3 px-5" onClick={handleSubmit}>
                        Save
                      </button>
                    </div>

                    {notes.length > 0 ? (
                      <div className="mb-3 font-16 text-muted">
                        <h6>Notes</h6>
                          {notes.map(note => (
                            
                            <Row key={note?._id}>
                              <Col lg={11} md={11} sm={11}>
                                <ul>
                                  <li className="custom-li-2">{note?.note}</li>
                                </ul>
                              </Col>
                              <Col lg={1} md={1} sm={1} className="text-end">
                                <div
                                className="bx bx-trash bx-xs"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteNote(note?._id)}
                              ></div>
                              </Col>
                            </Row>
                          ))}
                      </div>
                    ) : (
                      <div className="text-center mb-3 font-16 text-muted">
                        <div className="bx bxs-file fs-1 text-primary text-opacity-25"></div>
                        <h5>There are no notes yet to display</h5>
                      </div>
                    )}
                  </Col>
                </CardBody>
              </Card>
              {user?.isABusiness === true && <OperatingHoursView />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
