import { User } from "models/User";
import { OrderResponse } from "models/orderResponse";
import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { AdminService } from "services/AdminService";
import { format } from "date-fns";
import { Puff } from "react-loader-spinner";

export interface OrderStatusModalProps {
  isOpen: boolean;
  handleIsOpen: () => void;
  getPDF: (value: string) => void;
  orderId: string;
  isGloble?: boolean;
}

const AdminOrderStatusModal: React.FC<OrderStatusModalProps> = ({
  isOpen,
  handleIsOpen,
  orderId,
  getPDF,
  isGloble,
}: OrderStatusModalProps) => {
  const [order, setOrder] = useState<OrderResponse>();
  const [customer, setCustomer] = useState<User>({} as User);
  const [isloading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    setIsloading(true);
    setCustomer({} as User);
    if (isGloble) {
      AdminService.getOrderByZuluId(orderId)
        .then(res => {
          if (res.success) {
            setOrder(res.data);
            AdminService.getCustomerById(res.data.userId).then(r => {
              if (r.success) {
                setCustomer(r.data);
              }
            });
            setIsloading(false);
          } else {
            setOrder(undefined);
            setIsloading(false);
          }
        })
        .catch(err => {
          setIsloading(false);
          console.error(err);
        });
    } else {
      AdminService.getOrderById(orderId)
        .then(res => {
          if (res.success) {
            setOrder(res.data);
            AdminService.getCustomerById(res.data.userId).then(r => {
              if (r.success) {
                setCustomer(r.data);
              }
            });
            setIsloading(false);
          } else {
            setOrder(undefined);
            setIsloading(false);
          }
        })
        .catch(err => {
          setIsloading(false);
          console.error(err);
        });
    }
  }, [isOpen]);

  interface TextWithLineBreaksProps {
    text: string;
  }

  const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({ text }) => {
    const maxCharactersPerRow = 30;
    const lines: string[] = [];

    for (let i = 0; i < text.length; i += maxCharactersPerRow) {
      lines.push(text.slice(i, i + maxCharactersPerRow));
    }

    return (
      <div>
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handleIsOpen} size="xl" style={{ maxWidth: "1400px" }}>
        <ModalHeader className="text-center" toggle={handleIsOpen}>
          <div className="text-center">Order Details : {order?.uniqueId}</div>
        </ModalHeader>
        {isloading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
            <Puff color="#FFD0B8" height={50} width={50} />
          </div>
        ) : (
          <>
            {Object.keys(customer).length > 0 ? (
              <div>
                <ModalBody className="overflow-auto">
                  <div className="d-flex justify-content-start px-3">
                    <div className="address-wrapper">
                      <div className="row justify-content-center m-3">
                        <Row>
                          <Col lg="6">
                            <Label className="fs-6 text-primary fw-bold">Customer Details</Label>
                            <div>
                              <Row>
                                <Col lg="5" md="5" sm="4" xs="4">
                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                    Company Name
                                  </Label>
                                </Col>
                                <Col>
                                  <Label> : </Label>
                                </Col>
                                <Col lg="5" md="6" sm="6" xs="6">
                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                    {customer?.role === "business_customer"
                                      ? customer?.address?.companyName
                                      : customer?.firstName + " " + customer?.lastName}
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Col lg="5" md="5" sm="4" xs="4">
                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                    Contact Name
                                  </Label>
                                </Col>
                                <Col>
                                  <Label> : </Label>
                                </Col>
                                <Col lg="5" md="6" sm="6" xs="6">
                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                    {customer?.firstName} {customer?.lastName}
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Col lg="5" md="5" sm="4" xs="4">
                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                    Contact Phone
                                  </Label>
                                </Col>
                                <Col>
                                  <Label> : </Label>
                                </Col>
                                <Col lg="5" md="6" sm="6" xs="6">
                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                    {customer?.phoneNumber}
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Col lg="5" md="5" sm="4" xs="4">
                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                    Email Address
                                  </Label>
                                </Col>
                                <Col>
                                  <Label> : </Label>
                                </Col>
                                <Col lg="5" md="6" sm="6" xs="6">
                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                    {customer?.email}
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Col lg="5" md="5" sm="4" xs="4">
                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                    Profile Notes
                                  </Label>
                                </Col>
                                <Col>
                                  <Label> : </Label>
                                </Col>
                                <Col lg="5" md="6" sm="6" xs="6">
                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                    {customer?.notes?.map((note, index) => (
                                      <div key={note._id}>
                                        {note.note}
                                        {customer?.notes?.length && index < customer?.notes?.length - 1 ? "," : ""}
                                      </div>
                                    ))}
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3 form-group">
                              <Label className="fs-6 text-primary fw-bold">Pick-Up Details</Label>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Pickup address
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.pickUpAddress?.city}<br />
                                      {order?.pickUpAddress?.postalCode}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Company Name
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.pickUpAddress?.companyName}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Contact Name
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.pickUpAddress?.contactName}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Contact Phone
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.pickUpAddress?.contactPhone}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>

                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Pick - Up date and Time
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {/* {order?.packageReadyTime?.slice(0, -8)} */}
                                      {order?.packageReadyTime && order?.deliveryTime
                                        ? `${format(new Date(order?.packageReadyTime), "MM/dd/yyyy hh:mma")} to ${format(
                                            new Date(order?.deliveryTime),
                                            "MM/dd/yyyy hh:mma"
                                          )}`
                                        : "N/A"}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Unit No
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.pickUpAddress?.unit}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Created time
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.createdAt ? format(new Date(order.createdAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Notes about the package
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      <TextWithLineBreaks text={order?.notes == undefined ? "-" : order?.notes} />
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-0">
                          <Col lg="6">
                            <div className="mb-3 form-group">
                              <Label className="fs-6 text-primary fw-bold">Delivery Details</Label>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Delivery address
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryAddress?.city}<br/>
                                      {order?.deliveryAddress?.postalCode}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Company Name
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryAddress?.companyName}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Contact Name
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryAddress?.contactName}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Contact Phone
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryAddress?.contactPhone}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Total Number of Items
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.totalItems}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Total Weight of all Items
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.totalWeight}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Unit No
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryAddress?.unit}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Delivery Option
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.deliveryOption === "delivery_verification_pin" && order?.deliveryPin
                                        ? order.deliveryPin
                                        : order?.deliveryOption
                                        ? order.deliveryOption.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
                                        : "-"}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Choose Vehicle
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.vehicleType}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              {order?.notes && (
                                <div>
                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Notes
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        <TextWithLineBreaks text={order?.description == undefined ? "-" : order?.description} />
                                      </Label>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {order?.sentBy && (
                                <div>
                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Sent By
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        <TextWithLineBreaks text={order?.sentBy == undefined ? "-" : order?.sentBy} />
                                      </Label>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3 form-group">
                              <Label className="fs-6 text-primary fw-bold">Quote Details</Label>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Total distance
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.totalDistance}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Subtotal
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {"$" + order?.subTotal?.toString()}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      HST 13%
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {"$" + order?.hst?.toString()}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Total Fare
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {"$" + order?.totalFare?.toString()}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="mb-3 form-group">
                              <Label className="fs-6 text-primary fw-bold">Order Status</Label>
                              <div>
                                <Row>
                                  <Col lg="5" md="5" sm="4" xs="4">
                                    <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                      Status
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Label> : </Label>
                                  </Col>
                                  <Col lg="5" md="6" sm="6" xs="6">
                                    <Label for="basicpill-companyuin-input9" className="text-muted">
                                      {order?.orderStatus
                                        ? order?.orderStatus.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
                                        : "Pending"}
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              {(order?.statusDetails?.status === "picked" || order?.statusDetails?.status === "pick_up_failed") && (
                                <div>
                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Name of shipper
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        {order?.statusDetails?.shipperName}
                                      </Label>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {order?.statusDetails?.status === "delivered" && (
                                <div>
                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Name of receiver
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        {order?.statusDetails?.receiverName}
                                      </Label>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {(order?.statusDetails?.status === "picked" ||
                                order?.statusDetails?.status === "pick_up_failed" ||
                                order?.statusDetails?.status === "delivered" ||
                                order?.statusDetails?.status === "dropped" ||
                                order?.statusDetails?.status === "return" ||
                                order?.statusDetails?.status === "returned" ||
                                order?.statusDetails?.status === "redelivered" ||
                                order?.statusDetails?.status === "reattempt") && (
                                <div>
                                  {order?.statusDetails?.notes && (
                                    <Row>
                                      <Col lg="5" md="5" sm="4" xs="4">
                                        <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                          Notes
                                        </Label>
                                      </Col>
                                      <Col>
                                        <Label> : </Label>
                                      </Col>
                                      <Col lg="5" md="6" sm="6" xs="6">
                                        <Label for="basicpill-companyuin-input9" className="text-muted">
                                          {order?.statusDetails?.notes}
                                        </Label>
                                      </Col>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Date
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        {order?.statusDetails?.date}
                                      </Label>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="5" md="5" sm="4" xs="4">
                                      <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                        Time
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label> : </Label>
                                    </Col>
                                    <Col lg="5" md="6" sm="6" xs="6">
                                      <Label for="basicpill-companyuin-input9" className="text-muted">
                                        {order?.statusDetails?.time}
                                      </Label>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                              {(order?.statusDetails?.status === "pick_up_failed" ||
                                order?.statusDetails?.status === "dropped" ||
                                order?.statusDetails?.status === "return" ||
                                order?.statusDetails?.status === "reattempt") && (
                                <div>
                                  <Row>
                                    {/* <Col lg="5" md="5" sm="4" xs="4">
                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                Notes
                              </Label>
                            </Col>
                            <Col>
                              <Label> : </Label>
                            </Col> */}
                                    {order?.statusDetails?.imageData && (
                                      <Col lg="6">
                                        <img className="mt-2" alt="Card cap" src={order?.statusDetails?.imageData} width="100%" />
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex flex-row flex-wrap justify-content-end">
                    <button className="btn btn-primary btn-sm px-3" onClick={() => getPDF(order?._id != undefined ? order._id : "")}>
                      Download PDF
                    </button>
                  </div>
                </ModalFooter>
              </div>
            ) : (
              <ModalBody>
                <Row className="justify-content-md-center justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div>No data to Show</div>
                  </Col>
                </Row>
              </ModalBody>
            )}
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default AdminOrderStatusModal;
