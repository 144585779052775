/* eslint-disable  @typescript-eslint/no-explicit-any */
// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Card, CardBody, Row } from "reactstrap";
import { Invoice } from "models/Invoice";
import { InvoiceService } from "services/InvoiceService";
import TableContainer from "components/Common/TableContainer";
import PaymentLogsModal from "modals/PaymentLogsModal";
import { Puff } from "react-loader-spinner";

const PaymentLogs: React.FC = () => {
  const [invoices, setInvoices] = useState([] as Invoice[]);
  const [receiptId, setReceiptId] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isloading, setIsloading] = useState<boolean>(true);
  const LIMIT_P_C = 10;

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  useEffect(() => {
    getInvoices();

  }, []);

  const getInvoices = () => {
    setIsloading(true);
    InvoiceService.getPaymentLogList(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setInvoices(res.data);

        setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }
    
    setIsloading(false); });
  };

  const columns = useMemo(
    () => [
      {
        id: "invoiceNumber",
        Header: "Invoice Number",
        accessor: "zuluId",
        Cell: ({ value }: any) => {
          return value;
        },
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => `$${value}`,
      },
      {
        id: "receiptButton",
        Header: "Receipt",
        accessor: "_id",
        Cell: ({ value }: any) => {
          return (
            <div className="col-md-12 text-center">
              <button className="btn btn-primary btn-sm" onClick={() => handleReceipt(value)}>
                Receipt
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const searchTable = (serchText: string) => {
    const searchdata = {
      searchableString: serchText,
    };
    InvoiceService.getPaymentLogListBySearch(searchdata, LIMIT_P_C).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setInvoices(res.data);
          setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
        }
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  const SeeMore = () => {
    const updatedSkip = isOffset_p_c + 1;
    setOffset_p_c(updatedSkip);
    InvoiceService.getPaymentLogList(LIMIT_P_C, updatedSkip).then(res => {
      if (res.success) {
        setInvoices(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_p_c(!(res.data.length > 0 && res.data.length == LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  const handleReceipt = (value: any) => {
    setReceiptId(value);
    handleIsOpen();
  };

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  document.title = "Payment Log | Zulu Courier ";
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Payment Logs" breadcrumbItem="All Payments" />
          <Col lg={12}>
            <Card>
              <CardBody>
              {isloading? <div className="d-flex justify-content-center align-items-center"  style={{ height: '500px' }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>:
              <>{invoices.length > 0 ? (
                  <TableContainer
                    columns={columns}
                    data={invoices}
                    currentPage={undefined}
                    pagelimit={undefined}
                    handlePageChange={undefined}
                    handleColumnChange={undefined}
                    selectedColumns={undefined}
                    showColumnChange={false}
                    handleSearch={searchTable}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={100}
                    className="custom-header-css"
                    isJobListGlobalFilter={undefined}
                    isAddUserList={undefined}
                    handleOrderClicks={undefined}
                    handleUserClick={undefined}
                    handleCustomerClick={undefined}
                    isAddCustList={undefined}
                    handleSendEmail={undefined}
                    isPDFGenerate={false}
                    isAddressBook={false}
                    ispopup={false}
                    hancleDeleteRecords={undefined}
                    handleSelect={undefined}
                    selectedID={undefined}
                    hasCheckBox={false}
                  />
                ) : (
                  <Row className="justify-content-md-center justify-content-center align-items-center">
                    <Col className="col-md-auto">
                      <div>No Payment Logs</div>
                    </Col>
                  </Row>
                )}</>
              }

                {invoices.length > 10 && (
                  <Row className="justify-content-end">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <button
                          className="btn btn-primary btn-sm px-2 me-lg-2 me-md-2 mb-2"
                          disabled={seeMore_p_c}
                          onClick={() => SeeMore()}
                        >
                          See More
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <PaymentLogsModal isOpen={isOpen} handleIsOpen={handleIsOpen} receiptId={receiptId} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentLogs;
