import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useSortBy, useFilters, useExpanded, usePagination } from "react-table";
import { Table, Row, Col, Input } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/custom.css";
import ReactPaginate from "react-paginate";
import { DefaultColumnFilter } from "components/Common/filters";

const DriverTableContainer = ({
  columns,
  data,
  pagelimit,
  handlePageChange,
  handleSearch,
  customPageSize,
  className,
  hasCheckBox,
  handleSendEmail,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const [value, setValue] = React.useState(state.globalFilter);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedOtherIds, setSelectedOtherIds] = useState([]);
  const [checkboxCounter, setCheckboxCounter] = useState(0);
  const [isTick, setIsTick] = useState(false);

  const handleCheckboxCheck = values => {
    if (selectedValues.includes(values)) {
      setSelectedValues(selectedValues.filter(value => value !== values));
      setSelectedIds(selectedIds.filter(id => id !== values._id));
      values.driverDetails._id ?
        setSelectedOtherIds(selectedOtherIds.filter(id => id !== values.driverDetails._id)) :
        setSelectedOtherIds([...selectedOtherIds, ""]);
    } else {
      setSelectedValues([...selectedValues, values]);
      setSelectedIds([...selectedIds, values._id]);

      values.driverDetails._id ?
        setSelectedOtherIds([...selectedOtherIds, values.driverDetails._id]) :
        setSelectedOtherIds([...selectedOtherIds, ""]);
    }
    setCheckboxCounter(prevCounter => prevCounter + 1);
    setIsTick(true);
  };
  const handleCheckboxUnCheck = values => {
    setSelectedValues(selectedValues.filter(value => value !== values));
    const index = selectedValues.indexOf(values);

    setSelectedIds(selectedIds => selectedIds.filter((id, i) => (i != index)))
    setSelectedOtherIds(selectedOtherIds => selectedOtherIds.filter((id, i) => (i !== index)))
    setCheckboxCounter(prevCounter => prevCounter - 1);
  };

  React.useEffect(() => {
    setIsTick(checkboxCounter > 0);
  }, [checkboxCounter]);

  const generateSortingIndicator = column => {
    return column.isSortedDesc ? " ⇩" : " ⇧";
  };

  return (
    <Fragment>
      <Row className="d-flex flex-row justify-content-end p-1">
        <Col md={12}>
          <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
            {hasCheckBox && (
              <div>
                <button
                  type="submit"
                  disabled={!isTick}
                  onClick={() => handleSendEmail(selectedIds, selectedOtherIds)}
                  className="btn btn-primary btn-sm py-2 me-2 mb-2">
                  Email Invoices
                </button>
              </div>
            )}
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0 " className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => {
                      setValue(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control border border-light-subtle rounded-3 py-2 px-5"
                    placeholder={`Search ..`}
                    value={value || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <table bordered hover {...getTableProps()} className="newTable">
          <thead className="newThead">
            {headerGroups?.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {hasCheckBox && <th className="text-center pb-4"></th>}
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.render("Header") === "Weight (lbs)" ? "text-wrap" : "text-nowrap"}>
                    <div className="d-flex flex-row justify-content-center" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.accessor !== undefined ? generateSortingIndicator(column) : ""}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.reverse().map((row, i) => {
              prepareRow(row);
              const isRowSelected = selectedValues.includes(row.original);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps()}>
                    {hasCheckBox && (
                      <td>
                        <Input
                          type="checkbox"
                          checked={isRowSelected}
                          onChange={event => {
                            if (event.target.checked) {
                              handleCheckboxCheck(row.original);
                            } else {
                              handleCheckboxUnCheck(row.original);
                            }
                          }}
                        />
                      </td>
                    )}
                    {row.cells.map(cell => {
                      const dataLabel = cell.column.Header?.props?.children || cell.column.Header;
                      return (
                        <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                          <div>{cell.render("Cell")}</div>
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagelimit > 1 && (
        <ReactPaginate
          pageCount={pagelimit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination justify-content-lg-end align-items-lg-end rounded-pill px-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </Fragment>
  );
};

DriverTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default DriverTableContainer;