import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface DeliveryVerificationModalProps {
  showModal: boolean;
  handleClosePinModal: () => void;
  setDeliveryPin: (pin: string) => void;
  // setDeliveryPin: string;
}

const DeliveryVerificationModal: React.FC<DeliveryVerificationModalProps> = ({
  showModal,
  handleClosePinModal: handleClosePinModal,
  setDeliveryPin,
}) => {
  const [pin, setPin] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isNumeric = /^\d*$/.test(value); // Check if the value is numeric

    if (isNumeric && value.length <= 5) {
      // deliveryPin=value
      setPin(value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSave = () => {
    if (pin.length === 5) {
      // Handle save logic here
      setDeliveryPin(pin); // Save the pin to parent component's state
      // setDeliveryPin=pin;
      console.log("Pin saved:", pin);
      handleClosePinModal();
    } else {
      setIsValid(false);
    }
  };
  return (
    <Modal isOpen={showModal} toggle={handleClosePinModal}>
      <ModalHeader toggle={handleClosePinModal}>Delivery Verification Pin</ModalHeader>
      <ModalBody>
        {/* Add your modal content here */}
        <p>Please enter your delivery verification pin.</p>
        <input
          type="text"
          value={pin}
          onChange={handlePinChange}
          placeholder="Enter 5-digit PIN"
          //   invalid={!isValid}
        />
        {/* <FormFeedback>Please enter a valid 5-digit numeric pin.</FormFeedback> */}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClosePinModal}>
          Close
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeliveryVerificationModal;
