import React, { useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import emailImg from "../../assets/images/email.png";

const ResetVerificationCard: React.FC<{
    onSubmit: (password: string) => void;
}> = ({ onSubmit }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const handlePasswordChange = (e: { target: { value: string } }) => {
        setPassword(e.target.value);
        setPasswordError("");
    };

    const handleConfirmPasswordChange = (e: { target: { value: string } }) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError("");
    };

    const handleSubmit = () => {

        if (password.trim() === "") {
            setPasswordError("Password is required");
            return;
        }

        if (confirmPassword.trim() === "") {
            setConfirmPasswordError("Confirm Password is required");
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            return;
        }


        onSubmit(password);
    };

    return (
        <React.Fragment>
            <div className="payment-card-wrapper">
                <div className="p-3">
                    <Card className="payment-card">
                        <div className="card-header">
                            <Row>
                                <div className="image-container">
                                    <img src={emailImg} className="image" alt="Email Verification" />
                                </div>
                            </Row>
                        </div>
                        <CardBody className="px-5 py-3">
                            <Row className="mb-3 mt-5">
                                <h4 className="text-muted text-center">Enter your new password</h4>
                                <div className="form-group mt-4">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {passwordError && <p className="text-danger">{passwordError}</p>}
                                </div>
                                <div className="form-group mt-4">
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    {confirmPasswordError && <p className="text-danger">{confirmPasswordError}</p>}
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary"
                                        style={{ backgroundColor: "#34C38F", maxWidth: "50%" }}
                                        onClick={handleSubmit}
                                    >
                                        {" "}
                                        Reset password
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetVerificationCard;
