import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// import theme from "../../assets/images/signup.png";
import theme from "../../assets/images/login_img_new.png";
import logo from "../../assets/images/logo.png";
import { AuthService, UserSignUpData } from "services/AuthService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { RequestState } from "enum/RequestState";
import { Radio } from "antd";
import Copyright from "components/Common/copyright";
import { RouteContext } from "context/RouteProvider";
import { useNavigate } from "react-router-dom";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AddressModel } from "models/City";
import { environment } from "environment/environment";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { AddressType } from "enum/AddressType";

const Signup: React.FC = () => {
  const [disabledBtn, setDesableBtn] = useState<boolean>(false);
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(RequestState.INITIAL);
  const [isProtected, setIsProtected] = useContext(RouteContext);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isABusiness, setIsABusiness] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressModel>({} as AddressModel);
  const [unitNo, setUnitNo] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [firstNameErr, setFirstNameErr] = useState<string>();
  const [lastNameErr, setLastNameErr] = useState<string>();
  const [addressErr, setAddressErr] = useState<string>();
  const [emailErr, setEmailErr] = useState<string>();
  const [phoneErr, setPhoneErr] = useState<string>();
  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmErr, setConfirmErr] = useState<string>();
  const [businessNameErr, setBusinessNameErr] = useState<string>();
  const [isCheckedErr, setIsCheckedErr] = useState<string>();
  const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;
  const navigate = useNavigate();
  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };
  const onSubmit = (actions: any) => {
    setAddress({ ...address, contactName: `${firstName} ${lastName}`, contactPhone: phone, unit: unitNo, companyName: businessName });

    const data: UserSignUpData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      businessName: businessName,
      isABusiness: isABusiness,
      address: address,
      phoneNumber: phone,
    };

    const isValid = validateForm(data);

    if (isValid) {
      AuthService.userSignup(data)
        .then(res => {
          if (res.success) {
            setLoginRequestState(RequestState.SUCCESS);
            setDesableBtn(false);
            setIsProtected(true);
            navigate("/email-verification", { state: { email: res.data } });
          } else {
            setLoginRequestState(RequestState.FAILED);
            setDesableBtn(false);
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: {marginBottom: "4rem"},
            });
          }
        })
        .catch(() => {
          setLoginRequestState(RequestState.FAILED);
        });
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "firstName":
        if (value == "" || value == undefined || value == null) {
          setFirstNameErr("Required");
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setFirstNameErr("Only letters are allowed!");
        } else {
          setFirstNameErr(undefined);
        }
        setFirstName(value);
        break;
      case "lastName":
        if (value == "" || value == undefined || value == null) {
          setLastNameErr("Required");
        } else if (!/^[a-zA-Z\s]*$/.test(value)) {
          setLastNameErr("Only letters are allowed!");
        } else {
          setLastNameErr(undefined);
        }
        setLastName(value);
        break;
      case "isBusiness":
        setIsABusiness(value === "yes" ? true : false);
        break;
      case "businessName":
        if (isABusiness) {
          if (value == "" || value == undefined || value == null) {
            setBusinessNameErr("Required");
          } else {
            setBusinessNameErr(undefined);
          }
          setBusinessName(value);
        }
        break;
      case "unitNo":
        setUnitNo(value);
        break;
      case "email":
        if (value == "" || value == undefined || value == null) {
          setEmailErr("Required");
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          setEmailErr("Invalid email");
        } else {
          setEmailErr(undefined);
        }
        setEmail(value);
        break;
      case "phone":
        if (value == "" || value == null || value == undefined) {
          setPhoneErr("Required");
        } else if (!canadianPhoneRegExp.test(value)) {
          setPhoneErr("Invalid Canadian phone number");
        } else {
          setPhoneErr(undefined);
        }
        setPhone(value);
        break;
      case "password":
        if (value == "" || value == null || value == undefined) {
          setPasswordErr("Required");
        } else if (value.length < 6) {
          setPasswordErr("Password should be atleast 6 characters long.");
        } else {
          setPasswordErr(undefined);
        }
        setPassword(value);
        break;
      case "confirm":
        if (value == "" || value == null || value == undefined) {
          setConfirmErr("Required");
        } else if (value.length < 6) {
          setConfirmErr("Password should be atleast 6 characters long.");
        } else if (password !== value) {
          setConfirmErr("Password should match.");
        } else {
          setConfirmErr(undefined);
        }
        setConfirm(value);
        break;
      case "isChecked":
        if (value == false) {
          setIsCheckedErr("Required");
        } else {
          setIsCheckedErr(undefined);
        }
        setAddress({ ...address, contactName: `${firstName} ${lastName}`, contactPhone: phone, unit: unitNo, companyName: businessName });
        setIsChecked(!isChecked);
        break;

      default:
        break;
    }
  };

  const validateForm = (data: UserSignUpData) => {
    let isValid = true;

    if (data.firstName == "" || data.firstName == undefined || data.firstName == null) {
      setFirstNameErr("Required");
      isValid = false;
    } else if (!/^[a-zA-Z\s]*$/.test(data.firstName)) {
      setFirstNameErr("Only letters are allowed!");
      isValid = false;
    }

    if (data.lastName == "" || data.lastName == undefined || data.lastName == null) {
      setLastNameErr("Required");
      isValid = false;
    } else if (!/^[a-zA-Z\s]*$/.test(data.lastName)) {
      setLastNameErr("Only letters are allowed!");
      isValid = false;
    }

    if (data.address.city == "" || data.address.city == undefined || data.address.city == null) {
      setAddressErr("Required");
      isValid = false;
    }

    if (data.email == "" || data.email == undefined || data.email == null) {
      setEmailErr("Required");
      isValid = false;
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(data.email)) {
      setEmailErr("Invalid email");
      isValid = false;
    }

    if (data.phoneNumber == "" || data.phoneNumber == null || data.phoneNumber == undefined) {
      setPhoneErr("Required");
    } else if (!canadianPhoneRegExp.test(data.phoneNumber)) {
      setPhoneErr("Invalid canadian phone number");
    }

    if (data.password == "" || data.password == null || data.password == undefined) {
      setPasswordErr("Required");
      isValid = false;
    } else if (data.password.length < 6) {
      setPasswordErr("Password should be atleast 6 characters long.");
      isValid = false;
    }

    if (confirm == "" || confirm == undefined || confirm == null) {
      setConfirmErr("Required");
      isValid = false;
    } else if (confirm.length < 6) {
      setConfirmErr("Password should be atleast 6 characters long.");
      isValid = false;
    } else if (data.password != confirm) {
      setConfirmErr("Password should match.");
      isValid = false;
    }

    if (isChecked == false) {
      setIsCheckedErr("Please read and agree to the terms and conditions.");
      isValid = false;
    }

    return isValid;
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
    const address = label;
    await geocodeByPlaceId(place_id).then(([place]: google.maps.GeocoderResult[]) => {
    const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      };
    setAddress({ city: address, lat:latLng.lat, lng:latLng.lng, postalCode: postalCode });
    }).catch(err => {
      console.log(err);
    });
    }
  };

  document.title = "Signup | Zulu Courier ";

  return (
    <div className="container">
      <section className="my-4">
        <div className="position-relative">
          <div className="row g-5 w-100">
            <div className="col-lg-7 col-md-12 col-sm-12 d-inline align-items-center justify-content-center">
              <img src={theme} alt="" className="w-100 position-relative" />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 d-inline pt-lg-5">
              <div className="px-4 py-5">
                <div className="sign-logo mb-3">
                  <a href={`${environment.app_url}/home`}>
                    <img src={logo} alt="" />
                  </a>
                </div>
                <h3 className="title mb-1 text-black">Customer Registration</h3>
                <p className="mb-4">
                  Already have an account? <Link to="/signin">Log in now</Link>
                </p>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label className="place-order-title">
                        First Name<label className="title-star">*</label>
                      </label>
                      <input
                        value={firstName}
                        onChange={handleChange}
                        name="firstName"
                        type="text"
                        placeholder=""
                        className={firstNameErr ? "input-error form-control" : "form-control"}
                      />
                      {firstNameErr !== undefined && <p className="error col-lg-5 place-order-title">{firstNameErr}</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <div className="row">
                        <label className="place-order-title">
                          Last Name<label className="title-star">*</label>
                        </label>
                      </div>
                      <input
                        value={lastName}
                        onChange={handleChange}
                        name="lastName"
                        type="text"
                        placeholder=""
                        className={lastName ? "input-error form-control" : "form-control"}
                      />
                      {lastNameErr && <p className="error  place-order-title">{lastNameErr}</p>}
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-12">
                    <label className="place-order-title me-4">Customer Category?</label>
                    <Radio.Group value={isABusiness ? "yes" : "no"} onChange={handleChange} name="isBusiness">
                      <Radio value="yes">Business</Radio>
                      <Radio value="no">Personal</Radio>
                    </Radio.Group>
                  </div>
                  {isABusiness == true && (
                    <div className="form-group">
                      <label className="place-order-title">
                        Business Name<label className="title-star">*</label>
                      </label>
                      <input
                        value={businessName}
                        onChange={handleChange}
                        name="businessName"
                        type="text"
                        placeholder=""
                        className={businessNameErr ? "input-error form-control" : "form-control"}
                      />
                      {businessNameErr && <p className="error place-order-title">{businessNameErr}</p>}
                    </div>
                  )}
                </div>

                <div className="form-group mt-2">
                  <label className="place-order-title">
                    Address<label className="title-star">*</label>
                  </label>
                  <CustomGooglePlacesAutocomplete
                    apiKey={environment.google_maps_key}
                    autocompletionRequest={mapprops}
                    addressType={AddressType.pickup}
                    selectProps={{
                      name: "address",
                    }}
                    handleAddress={handleAddress}
                  />
                  {addressErr && <p className="error col-lg-5 place-order-title">{addressErr}</p>}
                </div>

                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label className="place-order-title pb-2">
                        Unit No <label className="py-1"></label>
                      </label>
                      <input
                        value={unitNo}
                        onChange={handleChange}
                        name="unitNo"
                        type="text"
                        placeholder=""
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label className="place-order-title">
                        Phone Number<label className="title-star">*</label>
                      </label>
                      <input
                        value={phone}
                        onChange={handleChange}
                        name="phone"
                        type="text"
                        placeholder=""
                        className={phoneErr ? "input-error form-control" : "form-control"}
                      />
                      {phoneErr && <p className="error place-order-title">{phoneErr}</p>}
                    </div>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label className="place-order-title">
                    Email<label className="title-star">*</label>
                  </label>
                  <input
                    value={email}
                    onChange={handleChange}
                    name="email"
                    type="text"
                    placeholder=""
                    className={emailErr ? "input-error form-control" : "form-control"}
                  />
                  {emailErr && <p className="error place-order-title">{emailErr}</p>}
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12 mt-2">
                      <label className="place-order-title">
                        Create Password<label className="title-star"></label>
                      </label>
                      <input
                        value={password}
                        onChange={handleChange}
                        name="password"
                        type="password"
                        placeholder=""
                        className={passwordErr ? "input-error form-control" : "form-control"}
                      />
                      {passwordErr && <p className="error  place-order-title">{passwordErr}</p>}
                      <span className="password-show">
                        <i className="icon-76"></i>
                      </span>
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12 mt-2">
                      <label className="place-order-title">
                        Confirm Password<label className="title-star"></label>
                      </label>
                      <input
                        value={confirm}
                        onChange={handleChange}
                        name="confirm"
                        type="password"
                        placeholder=""
                        className={confirmErr ? "input-error form-control" : "form-control"}
                      />
                      {confirmErr && <p className="error place-order-title">{confirmErr}</p>}
                    </div>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-lg-12">
                      <input checked={isChecked} name="isChecked" type="checkbox" onChange={handleChange} />
                      <label className="place-order-title mx-2">
                        By Clicking Sign up, you agree to our <a href="/terms-of-service" target="_blank">terms and conditions</a>
                      </label>
                      {isCheckedErr && <p className="error place-order-title">{isCheckedErr}</p>}
                    </div>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <button type="submit" className="btn btn-md bg-orange text-white w-100 button-bg" onClick={onSubmit}>
                    Register
                  </button>
                </div>

                <div className="w-100 text-center mt-5">
                  <Copyright />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
