import * as yup from "yup"

const nameRegExp = /^[a-zA-Z\s]*$/;
const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;

export const userSchema = yup.object().shape({
  firstName: yup.string().matches(nameRegExp, "Only letters are allowed").required("Required"),
  lastName: yup.string().matches(nameRegExp, "Only letters are allowed").required("Required"),
  customerType: yup.string().required("Required"),
  email: yup.string().matches(emailRegExp, "Invalid email").required("Required"),
  phoneNumber: yup.string().matches(canadianPhoneRegExp, "Invalid Canadian phone number"),
  createPassword: yup.string().min(6, "Password should be atleast 6 characters long").required("Required"),
  confirmPassword: yup.string().oneOf([yup.ref('createPassword'), null], "Password should match.").min(6, "Password should be atleast 6 characters long").required("Required"),
})