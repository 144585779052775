import TableContainer from "components/Common/TableContainer";
import { AddressBook } from "models/AddressBook";
import React, { useEffect, useMemo, useState } from "react";
import { Puff } from "react-loader-spinner";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AddressService } from "services/AddressService";
import "../assets/css/custom.css"

export interface AddressBookModalProps {
  isOpen: boolean;
  handleIsOpen: () => void;
  getAddress: (value: AddressBook) => void;
  setIsChecked: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const AddressBookModal: React.FC<AddressBookModalProps> = ({ isOpen, handleIsOpen, getAddress, setIsChecked }: AddressBookModalProps) => {
  const [addressList, setAddressList] = useState<AddressBook[]>([]);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<string>("");

  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Company Address",
        accessor: "address.city",
      },
      {
        Header: "Contact Name",
        accessor: "address.contactName",
      },
      {
        Header: "Contact Phone",
        accessor: "address.contactPhone",
      },
      {
        Header: "Notes",
        accessor: "deliveryNote",
      },
    ],
    []
  )

  useEffect(() => {
    setIsloading(true);
    getAddresList();
  }, []);

  const getAddresList = () => {
    AddressService.getAddressBookList(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setAddressList(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      } else {
        setAddressList([]);
      }
      setIsloading(false);
    }).catch(err => {
      setIsloading(false);
      console.error(err);
    });
  }

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AddressService.getAddressBookList(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setAddressList(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AddressService.getAddressBookList(selectedOption.value, 1).then(res => {
      if (res.success) {
        setAddressList(res.data.aBookList);
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      searchableString: serchText,
    };
    AddressService.getAddressBooksBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setAddressList(res.data);
        } else {
        }
      }
    });
  };

  const handleCheck = (value: string) => {
    setSelectedId(value);
  };

  const handleClose = () => {
    setIsChecked(false)
    setSelectedId("")
    setOffset_p_c(1);
    getAddresList();
    handleIsOpen()
  }

  const submitAddress = () => {
    if(addressList.length>0){
      const aBook = addressList.filter((a: AddressBook) => {
        return selectedId === a._id;
      });
      getAddress(aBook[0]);
    }
    setSelectedId("")
    setOffset_p_c(1);
    getAddresList();
    handleIsOpen();
  };

  const closeBtn = (
    <button onClick={handleClose} type="button" className="btn-close"/>
  );
  
  return (
    <React.Fragment>
      <Modal className="modal-content" isOpen={isOpen} toggle={handleIsOpen} size="xl">
        <ModalHeader className="text-center" toggle={handleIsOpen} close={closeBtn}>
          My Saved Addresses
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <div className="d-flex flex-wrap px-lg-5">
            {addressList.length > 0 ?  (
              <TableContainer
                columns={columns}
                data={addressList}
                currentPage={undefined}
                pagelimit={pageCount}
                handlePageChange={SeeMore}
                handleColumnChange={handleColumnChange}
                selectedColumns={LIMIT_P_C}
                showColumnChange={true}
                handleSearch ={searchTable}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={50}
                className="custom-table"
                isJobListGlobalFilter={undefined}
                isAddUserList={undefined}
                handleOrderClicks={undefined}
                handleUserClick={undefined}
                handleCustomerClick={undefined}
                isAddCustList={undefined}
                handleSendEmail={undefined}
                isPDFGenerate={false}
                ispopup={false}
                isAddressBook={false}
                hancleDeleteRecords={undefined}
                handleSelect={handleCheck}
                selectedID={selectedId}
                hasCheckBox={false}
              />
            ): (
              <div className="text-center text-muted">You have not added contacts as of yet.</div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
        {selectedId ==""?
          <div className="d-flex flex-row flex-wrap justify-content-end">
            <button className="btn btn-primary"  onClick={handleIsOpen}>
            Go Back
            </button>
          </div>:
          <div className="d-flex flex-row flex-wrap justify-content-end">
          <button className="btn btn-primary"  onClick={submitAddress}>
          Submit
          </button>
        </div>}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default AddressBookModal;
