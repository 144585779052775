import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
toast.configure()
import "react-toastify/dist/ReactToastify.css";
import { Driver } from "models/Driver";
import { AdminService } from "services/AdminService";
import { Puff } from "react-loader-spinner";

export interface DriverDetailsModalProps {
  isOpen: boolean;
  handleIsOpen: () => void;
  driverId: string;
}

const DriverDetailsModal: React.FC<DriverDetailsModalProps> = ({ isOpen, handleIsOpen, driverId}: DriverDetailsModalProps) => {
  const [driver, setDriver] = useState({} as Driver);
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    setIsloading(true);
    AdminService.getDriverById(driverId)
      .then(res => {
        if (res.success) {
            setDriver(res.data);
          setIsloading(false);
        } else {
          setDriver({});
          setIsloading(false);
        }
     
      })
      .catch(err => {
        setIsloading(false);
        console.error(err);
      });
  }, [isOpen]);


  return (
    <React.Fragment>
      {isLoading && <div className="d-flex justify-content-center align-items-center " style={{ height: "500px" }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>}
      <Modal className="modal-dialog modal-dialog-centered" isOpen={isOpen} toggle={handleIsOpen} size="xl">
        <ModalHeader className="text-center" toggle={handleIsOpen}>
          <div className="text-center">Driver Details</div>
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <div className="d-flex justify-content-start px-lg-5">
            <div className="address-wrapper">
            <div className="row justify-content-center m-lg-5">
              <Row>
                <Col lg="12">
                  <div className="mb-3 form-group">
                 
                    <div>
                      <Row>
                        <Col lg="5" md="5" sm="4" xs="4">
                          <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                            Name
                          </Label>
                        </Col>
                        <Col>
                          <Label> : </Label>
                        </Col>
                        <Col lg="5" md="6" sm="6" xs="6">
                          <Label for="basicpill-companyuin-input9" className="text-muted">
                            {driver?.firstName +" " + driver?.lastName}
                          </Label>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg="5" md="5" sm="4" xs="4">
                          <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                            Address
                          </Label>
                        </Col>
                        <Col>
                          <Label> : </Label>
                        </Col>
                        <Col lg="5" md="6" sm="6" xs="6">
                          <Label for="basicpill-companyuin-input9" className="text-muted">
                          {driver?.address?.city}
                          </Label>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg="5" md="5" sm="4" xs="4">
                          <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                            Email
                          </Label>
                        </Col>
                        <Col>
                          <Label> : </Label>
                        </Col>
                        <Col lg="5" md="6" sm="6" xs="6">
                          <Label for="basicpill-companyuin-input9" className="text-muted">
                          {driver?.email}
                          </Label>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg="5" md="5" sm="4" xs="4">
                          <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                            Contact Phone
                          </Label>
                        </Col>
                        <Col>
                          <Label> : </Label>
                        </Col>
                        <Col lg="5" md="6" sm="6" xs="6">
                          <Label for="basicpill-companyuin-input9" className="text-muted">
                          {driver?.phoneNumber}
                          </Label>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg="5" md="5" sm="4" xs="4">
                          <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                            Percentage
                          </Label>
                        </Col>
                        <Col>
                          <Label> : </Label>
                        </Col>
                        <Col lg="5" md="6" sm="6" xs="6">
                          <Label for="basicpill-companyuin-input9" className="text-muted">
                            {driver?.percentage}%
                          </Label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row flex-wrap justify-content-end">
            {/* <button className="btn btn-primary">
              Download PDF
            </button> */}
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DriverDetailsModal;
