import { PaymentType } from "models/Invoice";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { InvoiceService } from "services/InvoiceService";
import { format } from "date-fns";
const PaymentLogsModal = ({ isOpen, handleIsOpen, receiptId }) => {
  const [payment, setPayment] = useState({});
  const [invoice, setInvoice] = useState({});
  const [order, setOrder] = useState({});
  useEffect(() => {
    InvoiceService.getPaymentLog(receiptId).then(res => {
      if (res.success) {
        setPayment(res.data);
        setOrder(res.data.orderIds[0]);
        setInvoice(res.data.invoiceIds[0]);
      }
    });
  }, [isOpen]);

  const generatePdf = paymentId => {

    InvoiceService.genaratePaymentPDF(paymentId).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch (error) {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Modal className="modal-dialog modal-dialog-centered" isOpen={isOpen} toggle={handleIsOpen} size="lg">
        <ModalHeader className="text-center" toggle={handleIsOpen}>
          Payment Details
        </ModalHeader>
        <ModalBody>
          {/* <h4 className="bg-primary bg-opacity-25 popover-header py-2 text-center">Invoice</h4> */}
          <div className="mx-lg-3 px-lg-5 small">
            <Row className="pt-4">
              <Col lg="4" className="fw-semibold">
                Payment ID :
              </Col>
              <Col lg="1" className="d-lg-flex d-none">
                :
              </Col>
              <Col lg="7">{payment?.zuluId}</Col>
            </Row>
            { payment?.paymentType == PaymentType.ORDER ?
            <>
            <Row className="pt-2">
              <Col lg="4" className="fw-semibold">
                Order Id :
              </Col>
              <Col lg="1" className="d-lg-flex d-none">
                :
              </Col>
              <Col lg="7">{order?.uniqueId}</Col>
            </Row>
            <Row className="pt-2">
              <Col lg="4" className="fw-semibold">
                Pickup Address :
              </Col>
              <Col lg="1" className="d-lg-flex d-none">
                :
              </Col>
              <Col lg="7">{order?.pickUpAddress?.city}</Col>
            </Row>
            <Row className="pt-2">
              <Col lg="4" className="fw-semibold">
                Delivery Address :
              </Col>
              <Col lg="1" className="d-lg-flex d-none">
                :
              </Col>
              <Col lg="7">{order?.deliveryAddress?.city}</Col>
            </Row>
            <Row className="pt-2">
              <Col lg="4" className="fw-semibold">
                Amount :
              </Col>
              <Col lg="1" className="d-lg-flex d-none">
                :
              </Col>
              <Col lg="7">${payment?.amount}</Col>
            </Row>
            </>:
             <>
             <Row className="pt-2">
               <Col lg="4" className="fw-semibold">
                 Invoice Id :
               </Col>
               <Col lg="1" className="d-lg-flex d-none">
                 :
               </Col>
               <Col lg="7">{invoice?.invoiceId}</Col>
             </Row>
             <Row className="pt-2">
               <Col lg="4" className="fw-semibold">
               Billing period :
               </Col>
               <Col lg="1" className="d-lg-flex d-none">
                 :
               </Col>
               <Col lg="7">from {invoice?.createdAt !== undefined ? format(new Date(invoice?.createdAt), "MMMM do, yyyy") : ""} to{" "}
                {invoice?.updatedAt !== undefined ? format(new Date(invoice.updatedAt), "MMMM do, yyyy") : ""}</Col>
             </Row>
             <Row className="pt-2">
               <Col lg="4" className="fw-semibold">
               Invoic Type :
               </Col>
               <Col lg="1" className="d-lg-flex d-none">
                 :
               </Col>
               <Col lg="7">{invoice?.invoiceType}</Col>
             </Row>
             <Row className="pt-2">
               <Col lg="4" className="fw-semibold">
                 Amount Due:
               </Col>
               <Col lg="1" className="d-lg-flex d-none">
                 :
               </Col>
               <Col lg="7">${payment?.amount}</Col>
             </Row>
             </>}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row flex-wrap justify-content-end">
            <button className="btn btn-primary" onClick={() => generatePdf(payment?._id)}>
              Download PDF
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentLogsModal;
