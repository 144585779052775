import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import DatePicker from "react-datepicker";
// import { Input, DatePicker, Calendar } from 'rsuite';
import "react-datepicker/dist/react-datepicker.css";
import { Invoice } from "models/Invoice";
import { AdminService } from "services/AdminService";
import TableContainer from "components/Common/TableContainer";
import InvoiceView from "./InvoiceView";
import { Puff } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useDispachCount } from "context/OrderCountProvider";

const CustomerBilling: React.FC = () => {
  const [startDate, setStartDate] = useState(moment.tz("America/Toronto").startOf("month"));
  const [endDate, setEndDate] = useState(moment.tz("America/Toronto"));
  const [invoices, setInvoices] = useState([] as Invoice[]);
  const [showTable, setShowTable] = useState(false);
  const [invoice, setInvoice] = useState<any>();
  const [isloading, setIsloading] = useState<boolean>(true);
  const [modal, setModal] = useState(false);

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [LIMIT_P_C, setLIMIT_P_C] = useState(10);
  const [pageCount, setPageCount] = useState<number>(1);

  const data = {
    startDate: startDate,
    endDate: endDate,
  };

  useEffect(() => {
    setIsloading(true);
    AdminService.getBusinessCustomerBilling(data)
      .then(res => {
        if (res.success) {
          //setInvoices(res.data);
          setInvoices(res.data.billingUsers);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
      })
      .catch(error => {
        console.error(error);
      });
    setIsloading(false);
  }, [showTable]);

  const SeeMore = (page:any) => {
    setOffset_p_c(page.selected+1);
    AdminService.getBusinessCustomerBilling(data,LIMIT_P_C, page.selected+1).then(res => {
      if (res.success) {
        setInvoices(res.data.billingUsers);
      }
    });
  };

  const handleColumnChange = (selectedOption:any) => {
    setLIMIT_P_C(selectedOption.value);
    AdminService.getBusinessCustomerBilling(data,selectedOption.value,1).then(res => {
      if (res.success) {
        setInvoices(res.data.billingUsers);
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      } 
    });
  };




  const handleStartDate = (date: Date | null) => {
    const sDate = moment(date);
    setStartDate(sDate);
  };

  const handletableData = async () => {
    await AdminService.getBusinessCustomerBilling(data)
      .then(res => {
        if (res.success) {
          //setInvoices(res.data);
          setInvoices(res.data.billingUsers);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleEndDate = (date: Date | null) => {
    const eDate = moment(date);
    setEndDate(eDate);
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      startDate: startDate,
      endDate: endDate,
      searchableString: serchText,
    };

    AdminService.getCustomerBillingBySearch(searchdata).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setInvoices(res.data);
        }
      }
    });
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleViewInvoice = (values: any) => {
    setInvoice(values.original);
    handlePopup();
  };

  const [dispachCount, setDispachCount] = useDispachCount();

  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  const generateInvoice = () => {
    AdminService.sendBusinessCustomerInvoices(data)
      .then(res => {
        setShowTable(!showTable);
        handletableData();
        toast.success("Invoices have been sent Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        statuscount();
      })
      .catch(error => {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        statuscount();
        console.error(error);
      });
  };

  const generateSingleInvoice = (id: string | undefined) => {
    const data = {
      id,
      startDate,
      endDate,
    };
    AdminService.sendBusinessCustomerInvoices(data)
      .then(res => {
        setShowTable(!showTable);
        handletableData();
        toast.success("Invoice has been sent Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        statuscount();
      })
      .catch(error => {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        statuscount();
        console.error(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        id: "userId",
        Header: "Customer ID",
        accessor: "customerId",
      },
      {
        id: "companyName",
        Header: "Company Name",
        accessor: "companyName",
        Cell: ({ row }: any) => {
          return row.original.companyName;
        },
      },
      {
        id: "totalOrders",
        Header: "Total Number of Orders",
        accessor: "totalOrders",
      },
      {
        id: "totalAmount",
        Header: "Total Amount Placed",
        accessor: "totalAmount",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button
                className="btn btn-primary btn-sm px-3"
                onClick={() => {
                  handleViewInvoice(row);
                  setShowTable(!showTable);
                }}
              >
                View Invoice
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Customer Billing | Zulu Courier";
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Customer Billing" breadcrumbItem="Create Invoice" />
          <Row>
            <Col lg="12">
              <Card className="bg-m-none">
                <CardBody>
                  <div className="wizard clearfix mb-5">
                    <Label>Date Selection</Label>
                    <Row>
                      <Col lg="4" className="h-100">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={startDate ? startDate.toDate() : null}
                          onChange={handleStartDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />

                       
                      </Col>
                      <Col lg="1" className="text-center">
                        <Label className="mt-lg-2">To</Label>
                      </Col>
                      <Col lg="4">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={endDate ? endDate.toDate() : null}
                          onChange={handleEndDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />

                      
                      </Col>
                      <Col lg="3">
                        <button type="submit" onClick={() => setShowTable(!showTable)} className="btn btn-primary btn-sm px-3 me-2 mt-lg-2">
                          Filter
                        </button>
                        <button
                          type="submit"
                          onClick={generateInvoice}
                          disabled={invoices.length === 0}
                          className="btn btn-primary btn-sm mt-lg-2"
                        >
                          Send All Invoices
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div className="wizard clearfix">
                    <Label>Billing Table</Label>
                    <Row>
                      <Col lg="12">
                        {isloading ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                            <Puff color="#FFD0B8" height={50} width={50} />
                          </div>
                        ) : (
                          <>
                            {invoices.length > 0 ? (
                              <TableContainer
                                columns={columns}
                                data={invoices}
                                // currentPage={undefined}
                                currentPage={isOffset_p_c}
                                // pagelimit={undefined}
                                pagelimit={pageCount}
                                handlePageChange={SeeMore}
                                // handleColumnChange={undefined}
                                handleColumnChange={handleColumnChange}
                                // selectedColumns={undefined}
                                selectedColumns={LIMIT_P_C}
                                // showColumnChange={false}
                                showColumnChange={true}
                                handleSearch={searchTable}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                // customPageSize={10}
                                customPageSize={50}
                                className="custom-header-css"
                                isJobListGlobalFilter={undefined}
                                isAddUserList={undefined}
                                handleOrderClicks={undefined}
                                handleUserClick={undefined}
                                handleCustomerClick={undefined}
                                isAddCustList={undefined}
                                handleSendEmail={undefined}
                                isPDFGenerate={false}
                                isAddressBook={false}
                                ispopup={false}
                                hancleDeleteRecords={undefined}
                                handleSelect={undefined}
                                selectedID={undefined}
                                hasCheckBox={false}
                              />
                            ) : (
                              <Row className="justify-content-md-center justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div style={{ height: "100px" }}>No Billings</div>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <InvoiceView
            isOpen={modal}
            handlePopup={handlePopup}
            invoice={invoice}
            handleGenerateInvoice={generateSingleInvoice}
            datedata={data}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerBilling;
