import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import { AuthProvider } from "context/AuthProvider";
import { RouteProvider } from "context/RouteProvider";
import { ProfileImageProvider } from "context/ProfileImageProvider";
import { DispachCountProvider } from "context/OrderCountProvider";
import { NotificationProvider } from "context/NotificationProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <AuthProvider>
          <DispachCountProvider>
            <ProfileImageProvider>
              <NotificationProvider>
                <RouteProvider>
                  <App />
                </RouteProvider>
              </NotificationProvider>
            </ProfileImageProvider>
          </DispachCountProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

serviceWorker.unregister();
