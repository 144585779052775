import { Radio, RadioChangeEvent } from "antd";
import Breadcrumb from "components/Common/Breadcrumb";
import { UserTypes } from "enum/UserTypes";
import { environment } from "environment/environment";
import { useFormik } from "formik";
import { AddressModel } from "models/City";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { userSchema } from "schemas/userSchema";
import { User } from "models/User";
import { PaymentOption } from "enum/PaymentOption";
import { AdminService } from "services/AdminService";
import CustomerView from "./CustomerView";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { AddressType } from "enum/AddressType";
import ManageCustomerTableContainer from "components/Common/ManageCustomerTable";
import { GooglePlacesAutocompleteHandle } from "components/Common/auto-complete/types";

const ManageCustomers: React.FC = () => {
  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [address, setAddress] = useState<AddressModel>();
  const [customers, setCustomers] = useState([] as User[]);
  const [isABusiness, setIsABusiness] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyNameErr, setCompanyNameErr] = useState<string>();
  const [paymentOption, setPaymentOption] = useState(PaymentOption.SINGLE);
  const [personalCount, setPersonalCount] = useState<any>(1);
  const [bussinesCount, setBussinesCount] = useState<any>(1);
  const [monthlyCount, setMonthlyCount] = useState<any>(1);
  const [modal, setModal] = useState(false);
  const [customer, setCustomer] = useState<User>();
  const ref = useRef<GooglePlacesAutocompleteHandle>(null);

  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };
  useEffect(() => {
    getAllCustomers();
  }, []);

  const initialValues = {
    firstName: "",
    lastName: "",
    customerType: UserTypes.PERSONAL_CUSTOMER,
    unit: "",
    email: "",
    phoneNumber: "",
    createPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values: typeof initialValues, actions: any) => {

    if (address?.city == "" || address?.city == undefined) {
      toast.error("Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (isABusiness === true && (companyName == "" || companyName == undefined)) {
      toast.error("Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      const customerData: User = {
        password: values.createPassword,
        firstName: values.firstName,
        lastName: values.lastName,
        isABusiness: isABusiness,
        role: values.customerType,
        email: values.email,
        phoneNumber: values.phoneNumber,
        paymentOption: paymentOption,
        address: address,
      };

      AdminService.createCustomer(customerData)
        .then(res => {
          if (res.success) {
            actions.resetForm();
            setIsABusiness(false);
            setPaymentOption(PaymentOption.SINGLE);
            setAddress(undefined);
            setCompanyName("");
            ref.current != null ? ref.current.clearField() : "";

            getAllCustomers();
            toast.success("Successfully added Customer Details.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const getAllCustomers = () => {
    AdminService.getCustomerList(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setCustomers(res.data.userList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        setBussinesCount(res.data.businesscount);
        setPersonalCount(res.data.personalcount);
        setMonthlyCount(res.data.monthlycount);
      }
    });
  };
  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AdminService.getCustomerList(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setCustomers(res.data.userList);
      }
    });
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      customerType: UserTypes.PERSONAL_CUSTOMER,
      unit: "",
      email: "",
      phoneNumber: "",
      createPassword: "",
      confirmPassword: "",
    },
    validationSchema: userSchema,
    onSubmit,
  });

  const handleValidation = () => {
    if (values.firstName == "") {
      toast.error("First Name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.lastName == "") {
      toast.error("Last Name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.customerType == UserTypes.BUSINESS_CUSTOMER && companyName == "") {
      toast.error("Company Name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    }else if (!/^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/.test(values.phoneNumber) && values.phoneNumber !="" ) {
      toast.error("Invalid Canadian phone number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.email == "") {
      toast.error("Email Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
      toast.error("Invalid email address", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.createPassword == "") {
      toast.error("Password is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.createPassword.length < 6) {
      toast.error("Password should be atleast 6 characters long", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.confirmPassword == "") {
      toast.error("Confirm Password is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (!values.confirmPassword.match(values.createPassword)) {
      toast.error("Password should match", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      if (values.customerType === UserTypes.BUSINESS_CUSTOMER) {
        setIsABusiness(true);
        setPaymentOption(PaymentOption.MONTHLY);
      }

      setAddress({
        ...address,
        unit: values.unit,
        contactName: values.firstName + " " + values.lastName,
        contactPhone: values.phoneNumber,
        companyName: companyName,
      });
    }
  };

  const handleRadioChange = (event: RadioChangeEvent) => {
    const selectedValue = event.target.value;
    setFieldValue("customerType", selectedValue);
  };

  const handleCompanyName = (event: any) => {
    const value = event.target.value;
    if (values.customerType == UserTypes.BUSINESS_CUSTOMER) {
      if (value == "" || value == undefined || value == null) {
        setCompanyNameErr("Required");
      } else {
        setCompanyNameErr(undefined);
      }
      setCompanyName(value);
    }
  }

  const searchTable = (serchText: string) => {
    const searchdata = {
      searchableString: serchText,
    };
    AdminService.getUsersBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setCustomers(res.data);
        }
      }
    });
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
    const address = label;
    await geocodeByPlaceId(place_id).then(([place]: google.maps.GeocoderResult[]) => {
    const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      };
    setAddress({ city: address, lat:latLng.lat, lng:latLng.lng, postalCode: postalCode });
    }).catch(err => {
      console.log(err);
    });
    }
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AdminService.getCustomerList(selectedOption.value, 1).then(res => {
      if (res.success) {
        setCustomers(res.data.userList);
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      }
    });
  };

  const handleEdit = (values: any) => {
    AdminService.getCustomerById(values.original?._id).then(res => {
      if (res.success) {
        setCustomer(res.data);
      }
    });
    handlePopup();
  };

  const columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Customer ID",
        accessor: "customerId",
      },
      {
        id: "firstName",
        Header: <div style={{ whiteSpace: "pre-line", width: "150px" }}>Customer / Company Name</div>,
        accessor: "firstName",
        Cell: ({ row }: any) => {
          return (
            row.original.address !== undefined &&
            row.original.address.companyName !== "" &&
            row.original.address.companyName !== null &&
            row.original.address.companyName !== "null" ?
              row.original.address.companyName : row.original.firstName + " " + row.original.lastName
          )
        },
      },
      {
        id: "userRole",
        Header: "Customer Type",
        accessor: "role",
        Cell: ({ value }: any) => {
          return value === UserTypes.BUSINESS_CUSTOMER ? "Business" : "Personal/Individual";
          //<CustomerTypeDropdown values={row} />
        },
      },
      {
        id: "paymentOption",
        Header: <div style={{ whiteSpace: "pre-line", width: "150px" }}>Approve for Monthly Invoice</div>,
        accessor: "paymentOption",
        // Cell: ({ row }: any) => {
        //   return <OnOffToggle values={row} getAllCustomers={getAllCustomers} />;
        // },
      },
      {
        id: "email",
        Header: "Invoice Email",
        accessor: "email",
      },
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              {/* <button
                className="btn btn-outline-success btn-sm px-3 me-2"
                onClick={() => handleView(row)}
              >
                View
              </button>  */}
              <button className="btn btn-primary btn-sm px-3" onClick={() => handleEdit(row)}>
                Edit
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Manage Customers | Zulu Courier";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Manage Customers" breadcrumbItem="Create" />

          <form onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <Row>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                First Name <Label className="title-star">*</Label>
                              </Label>
                              <input
                                type="text"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.firstName && touched.firstName ? "input-error form-control" : "form-control"}
                              />
                              {errors.firstName && touched.firstName && <p className="error">{errors.firstName}</p>}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Last Name <Label className="title-star">*</Label>
                              </Label>
                              <input
                                type="text"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.lastName && touched.lastName ? "input-error form-control" : "form-control"}
                              />
                              {errors.lastName && touched.lastName && <p className="error">{errors.lastName}</p>}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Customer Type <Label className="title-star">*</Label>
                              </Label>
                              <div>
                                <Radio.Group value={values.customerType} onChange={handleRadioChange} onBlur={handleBlur}>
                                  <Radio value={UserTypes.PERSONAL_CUSTOMER}>Personal</Radio>
                                  <Radio value={UserTypes.BUSINESS_CUSTOMER}>Business</Radio>
                                </Radio.Group>
                              </div>
                            </div>
                          </Col>

                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Company Name {values.customerType == UserTypes.BUSINESS_CUSTOMER && (<Label className="title-star">*</Label>)}
                              </Label>
                              <input
                                type="text"
                                id="companyName"
                                value={companyName}
                                onChange={handleCompanyName}
                                onBlur={handleBlur}
                                className={
                                  values.customerType == UserTypes.BUSINESS_CUSTOMER && companyNameErr
                                    ? "input-error form-control"
                                    : "form-control"
                                }
                              />
                              {values.customerType == UserTypes.BUSINESS_CUSTOMER && companyNameErr && (
                                <p className="error">{companyNameErr}</p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3 form-group">
                              <Label>
                                Address <Label className="title-star">*</Label>
                              </Label>
                              <div>
                                <CustomGooglePlacesAutocomplete
                                  apiKey={environment.google_maps_key}
                                  autocompletionRequest={mapprops}
                                  addressType={AddressType.pickup}
                                  ref={ref}
                                  selectProps={{
                                    name: "address",
                                  }}
                                  handleAddress={handleAddress}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Unit No <Label className="title-star"></Label>
                              </Label>
                              <input
                                type="text"
                                id="unit"
                                value={values.unit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.unit && touched.unit ? "input-error form-control" : "form-control"}
                              />
                              {errors.unit && touched.unit && <p className="error">{errors.unit}</p>}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Phone Number <Label className="title-star"></Label>
                              </Label>
                              <input
                                type="text"
                                id="phoneNumber"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.phoneNumber && touched.phoneNumber ? "input-error form-control" : "form-control"}
                              />
                              {errors.phoneNumber && touched.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3 form-group">
                              <Label>
                                Email Address <Label className="title-star">*</Label>
                              </Label>
                              <input
                                type="text"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.email && touched.email ? "input-error form-control" : "form-control"}
                              />
                              {errors.email && touched.email && <p className="error">{errors.email}</p>}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Create Password <Label className="title-star">*</Label>
                              </Label>
                              <input
                                type="password"
                                id="createPassword"
                                value={values.createPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.createPassword && touched.createPassword ? "input-error form-control" : "form-control"}
                              />
                              {errors.createPassword && touched.createPassword && <p className="error">{errors.createPassword}</p>}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3 form-group">
                              <Label>
                                Confirm Password <Label className="title-star">*</Label>
                              </Label>
                              <input
                                type="password"
                                id="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.confirmPassword && touched.confirmPassword ? "input-error form-control" : "form-control"}
                              />
                              {errors.confirmPassword && touched.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="actions clearfix">
                        <button
                          //disabled={isSubmitting}
                          type="submit"
                          onClick={handleValidation}
                          className="btn btn-primary btn-sm px-3 me-3"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {customers.length > 0 ? (
                    <ManageCustomerTableContainer
                      columns={columns}
                      data={customers}
                      bussinesCount={bussinesCount}
                      personalCount={personalCount}
                      monthlyCount={monthlyCount}
                      pagelimit={pageCount}
                      handlePageChange={SeeMore}
                      handleSearch={searchTable}
                      handleColumnChange={handleColumnChange}
                      selectedColumns={LIMIT_P_C}
                      showColumnChange={true}
                      customPageSize={50}
                      className="custom-header-css"
                      getAllCustomers={getAllCustomers}
                    />
                  ) : (
                    <Row className="justify-content-md-center justify-content-center align-items-center">
                      <Col className="col-md-auto">
                        <div>No Customers</div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CustomerView isOpen={modal} handlePopup={handlePopup} customer={customer} getAllCustomers={getAllCustomers} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageCustomers;
