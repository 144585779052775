import Breadcrumb from "components/Common/Breadcrumb";
import { DisplayOrder } from "enum/NotificationType";
import { Notification } from "models/Notification";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { NotificationService } from "services/NotificationService";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { NotificationData, useNotification } from "context/NotificationProvider";

const ViewAllNotification: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState(DisplayOrder.LAST_5);
  const currentYear = new Date().getFullYear();
  const [notifications, setNotifications] = useState([] as Notification[]);
  const [isModified, setIsModified] = useState(false);
  const [notification, setNotification] = useNotification() as NotificationData;

  const handleDropdown = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const date = {
    displayOrder: selectedValue,
  };

  useEffect(() => {
    NotificationService.getNotificationsByDisplayOrder(date).then(res => {
      if (res.success) {
        setNotifications(res.data);
      }
    });
    NotificationService.getNotifications(5, 0).then(res => {
      if (res.count !== undefined) {
        setNotification(res.count);
      }
    });
  }, [selectedValue, isModified]);

  const handleDeleteNotification = (_id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F75D21",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        NotificationService.deleteNotification(_id).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              text: "Notification Removed",
              confirmButtonColor: "#F75D21",
            });
            NotificationService.getNotifications(5, 0).then(res => {
              if (res.count !== undefined) {
                setNotification(res.count);
              }
            });
            setIsModified(!isModified);
          } else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#F75D21",
            });
          }
        });
      }
    });
  };

  document.title = "Notification | Zulu Courier";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Notification" breadcrumbItem="Notification" />
          <Card className="overflow-hidden mx-5">
            <CardBody>
              <Row>
                <Col lg={6}>
                  <div className="mx-lg-3 mx-md-3 mt-2">
                    <label className="font-size-15 fw-bold text-muted">Show : </label>
                    <select
                      onChange={e => {
                        handleDropdown(e);
                      }}
                      className="form-select font-size-15 fw-bold bg-transparent border-2"
                      value={selectedValue}
                    >
                      <option value={DisplayOrder.LAST_5}>{DisplayOrder.LAST_5}</option>
                      <option value={DisplayOrder.LAST_10_DAYS}>{DisplayOrder.LAST_10_DAYS}</option>
                      <option value={DisplayOrder.LAST_30_DAYS}>{DisplayOrder.LAST_30_DAYS}</option>
                      <option value={DisplayOrder.LAST_6_MONTHS}>{DisplayOrder.LAST_6_MONTHS}</option>
                      <option value={DisplayOrder.THIS_YEAR}>
                        {DisplayOrder.THIS_YEAR} {currentYear}
                      </option>
                      <option value={DisplayOrder.ALL}>{DisplayOrder.ALL}</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {notifications.map((notification, i) => {
            return (
              <div key={i} className="mt-3">
                <Card className="overflow-hidden mx-5">
                  <CardBody>
                    {notification?.event === "NEW_ADDERSS_ADDED" ? (
                      <div>
                        <Row>
                          <Col lg={6} md={6}>
                            <div className="font-size-18 fw-bold text-muted">New address added</div>
                          </Col>
                          <Col lg={6} md={6} className="text-end">
                            <div
                              className="bx bx-trash bx-sm"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteNotification(notification._id)}
                            ></div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="font-size-12 text-muted border-bottom pb-2">
                            <p className="mb-0">Update on {format(new Date(notification.updatedAt), "do MMMM yyyy h:mm:ss a")}</p>
                          </div>
                          <div className="flex-grow-1 mt-4">
                            <div className="font-size-13 fw-medium text-muted">
                              Address Id: <label className="text-primary ms-1 font-size-13">{notification.indexId}</label>
                            </div>
                            <div className="font-size-13 fw-medium text-muted">{notification?.content}</div>
                          </div>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row>
                          <Col lg={6} md={6}>
                            <div className="font-size-18 fw-bold text-muted">{notification.content}</div>
                          </Col>
                          <Col lg={6} md={6} className="text-end">
                            <div
                              className="bx bx-trash bx-sm"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteNotification(notification._id)}
                            ></div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="font-size-12 text-muted border-bottom pb-2">
                            <p className="mb-0">Update on {format(new Date(notification.updatedAt), "do MMMM yyyy h:mm:ss a")}</p>
                          </div>
                          <div className="flex-grow-1 mt-4">
                            <div className="font-size-13 fw-medium text-muted">
                              Order Id: <label className="text-primary ms-1 font-size-13">{notification.orderId}</label>
                            </div>
                            <div className="font-size-13 fw-medium text-muted">{notification?.message}</div>
                          </div>
                        </Row>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewAllNotification;
