import { Radio } from "antd";
import { Driver } from "models/Driver";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Table } from "reactstrap";
import { AdminService } from "services/AdminService";

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSelectedDriverChange: (driver: string | undefined) => void;
  handleDriverAssign: () => void;
}

function AssignDriver(props: ModalProps) {
  const [drivers, setDrivers] = useState([] as Driver[]);
  const { onSelectedDriverChange } = props;
  const [selectedDriver, setSelectedDriver] = useState<string | undefined>(undefined);

  const handleRadioChange = (driverId: string | undefined) => {
    setSelectedDriver(driverId);
    onSelectedDriverChange(driverId); // Call the parent callback function
  };

  const LIMIT_P_C = 8;
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const getDrivers = async () => {
    try {
      const res = await AdminService.getDrivers(LIMIT_P_C, isOffset_p_c);
      if (res.success) {
        setDrivers(res.data.driverList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);

  const searchDrivers = (searchText: string) => {
    const searchdata = {
      searchableString: searchText,
    };

    if (searchText === "") {
      getDrivers();
    } else {
      AdminService.getDriversSearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
        if (res.success) {
          setDrivers(res.data.driverList);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
      });
    }
  };

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AdminService.getDrivers(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setDrivers(res.data.driverList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          <Row>
            <Col>
              <p className="text-left">Assign Driver</p>
            </Col>
            <Col className="d-flex justify-content-end">
              <input
                type="search"
                id="password"
                className="form-control"
                placeholder="Search"
                style={{ height: "30px" }}
                onChange={e => {
                  searchDrivers(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Table>
              <thead>
                <tr>
                  <th>Drivers</th>
                </tr>
              </thead>
              <tbody>
                {drivers.map(driver => (
                  <tr key={driver._id}>
                    <td className="px-2">
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <Radio
                          value={driver._id}
                          checked={selectedDriver === driver._id}
                          onChange={() => handleRadioChange(driver._id)}
                          style={{ marginRight: "30px" }}
                        />
                        {driver.firstName} {driver.lastName}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {pageCount > 1 && (
            <ReactPaginate
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={SeeMore}
              containerClassName="pagination justify-content-end align-items-end rounded-pill px-3"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.handleDriverAssign} size="sm">
            Assign
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AssignDriver;
