import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import { Table, Row, Col, Button, Input, CardBody, Tooltip, Badge } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { OrderService } from "services/OrderService";
import { environment } from "environment/environment";
import Geocode from "react-geocode";
import "../../assets/css/custom.css";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import download from "../../assets/images/download.svg";
import { AdminService } from "services/AdminService";
import SetDate from "admin/Finance/SetDate";
import "../../assets/css/table.css";

Geocode.setApiKey(environment.google_maps_key);
Geocode.setLanguage("en");
Geocode.setRegion("ca");

function HeaderFields({ handleSearch, selectedColumns, handleColumnChange, popupDate, handleFianceDate,tickValue,selectedIds }) {
  const [value, setValue] = React.useState();

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const options = [
    { value: 10, label: "10 Rows" },
    { value: 15, label: "15 Rows" },
    { value: 20, label: "20 Rows" },
    { value: 25, label: "25 Rows" },
    { value: 50, label: "50 Rows" },
  ];

  const toggleSetDate = () => {
    setIsClicked(!isClicked);
  };

  const sendPaymentReminderEmailHandler = async () => {

    const payload = {
      customerIds: selectedIds,
    };


    const res = await AdminService.sendSelectedPaymentReminderEmail(payload);
    if (res.success) {
      toast.success('Payment Reminder has been sent successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
        style: { marginBottom: '4rem' },
      });
    } else {
      toast.error('Connection error. Please try again later.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
        style: { marginBottom: '4rem' },
      });
    }

  };

  const [isClicked, setIsClicked] = useState(false);

  const handleDateChange = date => {
    popupDate(date);
  };

  return (
    <React.Fragment>
      <Col md={12} className="">
        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end pb-2 pb-lg-0">
          <Button  disabled={!tickValue} color="primary" size="sm" onClick={sendPaymentReminderEmailHandler} style={{ maxHeight: "40px" }} className="mb-2 mb-sm-0 me-2">
          Payment Reminder
          </Button>

          <Button color="primary" size="sm" onClick={toggleSetDate} style={{ maxHeight: "40px" }} className="mb-2 mb-sm-0">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.41602 10.0832H8.24935V11.9165H6.41602V10.0832ZM6.41602 13.7502H8.24935V15.5835H6.41602V13.7502ZM10.084 10.0833H11.9173V11.9166H10.084V10.0833ZM10.084 13.7502H11.9173V15.5835H10.084V13.7502ZM13.748 10.0833H15.5814V11.9166H13.748V10.0833ZM13.748 13.7502H15.5814V15.5835H13.748V13.7502Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.58333 20.1665H17.4167C18.4278 20.1665 19.25 19.3442 19.25 18.3332V7.33316V5.49983C19.25 4.48874 18.4278 3.66649 17.4167 3.66649H15.5833V1.83316H13.75V3.66649H8.25V1.83316H6.41667V3.66649H4.58333C3.57225 3.66649 2.75 4.48874 2.75 5.49983V7.33316V18.3332C2.75 19.3442 3.57225 20.1665 4.58333 20.1665ZM17.4154 7.33331L17.4163 18.3333H4.58203V7.33331H17.4154Z"
                fill="white"
              />
            </svg>
          </Button>
          <div className="search-box ps-lg-2 ps-md-2">
            <div className="position-relative d-flex justify-content-center align-items-center">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control border border-light-subtle rounded-3 py-2 px-lg-5"
                  placeholder="Search .."
                  value={value || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          <div className="search-box ps-lg-2 ps-md-2 ">
            <Select options={options} value={options.find(option => option.value === selectedColumns)} onChange={handleColumnChange} />
          </div>
        </div>
      </Col>
      <SetDate isOpen={isClicked} toggle={toggleSetDate} onHandleDateChange={handleDateChange} handleDateclick={handleFianceDate} />
    </React.Fragment>
  );
}

const FinanceTable = ({
  columns,
  data,
  ispopup,
  handleSearch,
  selectedColumns,
  showColumnChange,
  customPageSize,
  className,
  isPDFGenerate,
  hancleDeleteRecords,
  selectedID,
  handlePageChange,
  handleColumnChange,
  openModal,
  pastListData,
  pastListNameData,
  pastListIdData,
  pagelimit,
  popUpDate,
  handLeFianceDate,
  editId,
  openEdit,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, selectedFlatRows } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },

    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const [pastListId, setPastListId] = useState();
  const [pastListNameId, setPastListNameId] = useState();
  const [pastListName, setPastListName] = useState();

  const [selectedIds, setSelectedIds] = useState([]);
  const [checkboxCounter, setCheckboxCounter] = useState(0);
  const [isTick, setIsTick] = useState(false);

  const handleCheckboxCheck = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }

    setCheckboxCounter(prevCounter => prevCounter + 1);
    setIsTick(true);
  };

  const handleCheckboxUnCheck = id => {

    setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    setCheckboxCounter(prevCounter => prevCounter - 1);
  };

  useEffect(() => {
    setIsTick(checkboxCounter > 0);
  }, [checkboxCounter]);

  const generateFinancePdf = invoiceId => {
    AdminService.genarateInvoicePDF(invoiceId).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const generateSortingIndicator = column => {
    //return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
    return column.isSortedDesc ? " ⥯" : " ⥮";
  };

  const handlePastList = id => {
    setPastListId(id);
  };
  const handlePastName = name => {
    setPastListName(name);
  };
  const handlePastId = id => {
    setPastListNameId(id);
  };

  useEffect(() => {
    pastListData(pastListId);
  }, [pastListId]);

  useEffect(() => {
    pastListNameData(pastListName);
  }, [pastListName]);

  useEffect(() => {
    pastListIdData(pastListNameId);
  }, [pastListNameId]);

  function getStatusColor(status) {
    switch (status) {
      case "paid":
        return "success";
      case "overdue":
        return "danger";
      default:
        return "warning";
    }
  }
  return (
    <Fragment>
      <div className="row align-items-center justify-content-end">
        <div className="col-md-12">
          <HeaderFields
            handleSearch={handleSearch}
            handleColumnChange={handleColumnChange}
            selectedColumns={selectedColumns}
            showColumnChange={showColumnChange}
            selectedFlatRows={selectedFlatRows}
            hancleDeleteRecords={hancleDeleteRecords}
            popupDate={popUpDate}
            handleFianceDate={handLeFianceDate}
            tickValue={isTick}
            selectedIds={selectedIds}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table bordered hover {...getTableProps()} className="newTable">
          <thead
            className="newThead"
            style={{
              cursor: ispopup || selectedID !== undefined ? "pointer" : "default",
              hover: "#F75D21",
            }}
          >
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.render("Header")} {...column.getHeaderProps()}>
                    <div className="d-flex flex-row justify-content-center">
                      <span
                        {...column.getSortByToggleProps()}
                        style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                      >
                        {column.render("Header")}
                        {column.accessor !== undefined ? generateSortingIndicator(column) : ""}
                      </span>
                    </div>
                  </th>
                ))}
                {isPDFGenerate && <th style={{ textAlign: "center" }}>PDF</th>}
                <th style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>View old bills</th>
                <th style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Action</th>
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.reverse().map((row, i) => {
              prepareRow(row);
              const isRowSelected = selectedIds.includes(row.original.latestInvoice.customerDetails._id);

              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps()}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={isRowSelected}
                        onChange={event => {
                          if (event.target.checked) {
                            handleCheckboxCheck(row.original.latestInvoice.customerDetails._id);
                          } else {
                            handleCheckboxUnCheck(row.original.latestInvoice.customerDetails._id);
                          }
                        }}
                      />
                    </td>
                    {row.cells.map(cell => {
                      const dataLabel = cell.column.Header?.props?.children ? cell.column.Header?.props?.children : cell.column.Header;

                      if (cell.column.id === "paymentOption") {
                        return (
                          <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                            <div className="app" style={{ textAlign: "center" }}>
                              <Switch
                                checked={
                                  row.original.paymentOption === PaymentOption.MONTHLY
                                    ? true
                                    : row.original.paymentOption === PaymentOption.SINGLE
                                    ? false
                                    : false
                                }
                                value={
                                  row.original.paymentOption === PaymentOption.MONTHLY
                                    ? true
                                    : row.original.paymentOption === PaymentOption.SINGLE
                                    ? false
                                    : false
                                }
                                onChange={event => handleChange(event, row.original._id)}
                                offColor="#F75D59"
                                onColor="#50C878"
                                width={60}
                                height={25}
                                handleDiameter={20}
                                checkedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      fontSize: 14,
                                      color: "#FFF",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    On
                                  </div>
                                }
                                uncheckedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      fontSize: 14,
                                      color: "#FFF",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    Off
                                  </div>
                                }
                              />
                            </div>
                          </td>
                        );
                      } else if (cell.column.id === "payStatus") {
                        return (
                          <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                            <Badge color={getStatusColor(cell.value)}>{cell.value}</Badge>
                          </td>
                        );
                      } else {
                        return (
                          <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                            <div>{cell.render("Cell")}</div>
                          </td>
                        );
                      }
                    })}
                    {isPDFGenerate && (
                      <td
                        // className="px-5"
                        style={{
                          cursor: ispopup || selectedID !== undefined ? "pointer" : "default",
                          hover: "#F75D21",
                        }}
                        data-label={'PDF'}
                      >
                        <div className="download_btn_container">
                          <img className="large_screen_download" src={download} alt="" onClick={() => generateFinancePdf(row.original.latestInvoice._id)} />
                          <button className="btn btn-primary small_screen_download sm-custom-button" onClick={() => generateOrderPdf(row.original.latestInvoice._id)}>Download</button>
                        </div>
                      </td>
                    )}
                    <td
                      data-label={'View old bills'}
                      // className="px-5"
                      onClick={() => {
                        handlePastList(row.original.latestInvoice.customerDetails._id);
                        handlePastName(
                          `${row.original.latestInvoice.customerDetails.firstName} ${row.original.latestInvoice.customerDetails.lastName}`
                        );
                        handlePastId(row.original.latestInvoice.customerDetails.customerId);
                        openModal();
                      }}
                      style={{
                        cursor: ispopup || selectedID !== undefined ? "pointer" : "default",
                        hover: "#F75D21",
                      }}
                    >
                      <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                            stroke="#74788D"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            stroke="#74788D"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </td>
                    <td data-label={'Action'}>
                      <button
                        className="btn btn-outline-success btn-sm w-full-view"
                        onClick={() => {
                          editId(row.original.latestInvoice._id);
                          openEdit();
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagelimit > 1 && (
        <ReactPaginate
          pageCount={pagelimit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination justify-content-end align-items-end rounded-pill px-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </Fragment>
  );
};

export default FinanceTable;
