/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Util } from "Util"
import axios from "axios"
import { QuoteModel } from "models/Quote"
import { AppResponse } from "models/Response"

export class QuoteService {
  /* PUBLIC ROUTE */
  public static async generateQuote(data: any): Promise<AppResponse<QuoteModel>> {
    const ep = Util.apiPublicUrl("quote/create")
    const res = await axios.post<void, AppResponse<QuoteModel>>(ep, data)
    return res
  }

  public static async generateQuotePDF(data: any): Promise<AppResponse<ArrayBuffer>> {
    const ep = Util.apiPublicUrl("quote/pdf");
    const res = await axios.post<QuoteModel, AppResponse<ArrayBuffer>>(ep, data);
    return res;
  }
}