// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, CardBody, Card, Label } from "reactstrap";
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AdminService } from "services/AdminService";
import { useFormik } from "formik";
import { DriverSchema } from "schemas/driverSchema";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { environment } from "environment/environment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AddressModel } from "models/City";
import { Driver } from "models/Driver";
import DriverTableContainer from "components/Common/DriverTableContainer";
import DriverDetailsModal from "modals/DriverDetailsModal";
import UpdateDriver from "./updateDriver";
import Swal from "sweetalert2";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { AddressType } from "enum/AddressType";
import "../../assets/css/custom.css";
import { Puff } from "react-loader-spinner";

const ManageDrivers: React.FC = () => {
  const [drivers, setDrivers] = useState([] as Driver[]);
  const [driverId, setDriverId] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [LIMIT_P_C, setLIMIT_P_C] = useState(10);

  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Driver Id",
        accessor: "customerId",
      },
      {
        Header: "Driver Name",
        accessor: "firstName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Address",
        accessor: "address.city",
        Cell: ({ row }: any) => {
          const value = row.original.address.city.split(",");
          const count = value.length;
          const city = count - 3 >= 0 ? value[count - 3] : "";
          return city;
        },
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "verified Status",
        accessor: "verifiedStatus",
      },
      {
        id: "action",
        Header: "Action",
        accessor: "_id",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button className="btn btn-primary btn-sm me-lg-2 mb-2" onClick={() => handleAction(row)}>
                View
              </button>
              <button className="btn btn-outline-success btn-sm me-lg-2  mb-2" onClick={() => handleEditAction(row)}>
                Edit
              </button>
              <button className="btn btn-outline-danger btn-sm  mb-2" onClick={() => deleteDriver(row)}>
                Delete
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);
  const [address, setAddress] = useState<AddressModel>();
  const [pageCount, setPageCount] = useState<number>(1);
  const [isloading, setIsloading] = useState<boolean>(true);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    typeOfPhone: "",
    phoneNumber: "",
    percentage: "",
  };

  useEffect(() => {
    setIsloading(true);
    AdminService.getDrivers(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setDrivers(res.data.driverList);
        setSeeMore_p_c(!(res.data.driverList.length > 0 && res.data.driverList.length == LIMIT_P_C));
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }
    });
    setIsloading(false);
  }, [isEditClicked]);

  const SeeMore = (page: any) => {
    setOffset_p_c(page.selected + 1);
    AdminService.getDrivers(LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setDrivers(res.data.driverList);
        setSeeMore_p_c(!(res.data.driverList.length > 0 && res.data.driverList.length == LIMIT_P_C));
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  const onSubmit = async (values: typeof initialValues, actions: any) => {
    const submitData: Driver = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      typeOfPhone: values.typeOfPhone,
      phoneNumber: values.phoneNumber,
      address: address,
      percentage: values.percentage,
    };
    if (address?.city == "" || address?.city == undefined) {
      toast.error("Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      await AdminService.createDriver(submitData).then((res: { success: any; data: any }) => {
        if (res.success) {
          actions.resetForm();
          toast.success("Successfully Added New Driver!.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
          AdminService.getDrivers(LIMIT_P_C, isOffset_p_c).then(res => {
            if (res.success) {
              setDrivers(res.data.driverList);
              setSeeMore_p_c(!(res.data.driverList.length > 0 && res.data.driverList.length == LIMIT_P_C));
            } else {
              setSeeMore_p_c(true);
            }
          });
        } else {
          toast.error("error occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      });
    }
  };

  const deleteDriver = (values: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteDriverById(values.original?._id).then(res => {
          if (res.success) {
            SeeMore(isOffset_p_c);
            // setDrivers(drivers.filter((Driver) => Driver._id !== driver._id));
            Swal.fire("Driver Removed");
          } else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AdminService.getDrivers(selectedOption.value, 1).then(res => {
      if (res.success) {
        setDrivers(res.data.driverList);
        setSeeMore_p_c(!(res.data.driverList.length > 0 && res.data.driverList.length == LIMIT_P_C));
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      } else {
        setSeeMore_p_c(true);
      }
    });
  };

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      typeOfPhone: "Mobile",
      phoneNumber: "",
      percentage: "",
    },
    validationSchema: DriverSchema,
    onSubmit, // Fix the attribute name here
  });

  const handleAction = (values: any) => {
    toggleOrderModalInput(values.original?._id);
  };

  const handleEditAction = (values: any) => {
    toggleEdiyModalInput(values.original?._id);
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const toggleOrderModalInput = (id: any) => {
    setDriverId(id);
    setIsClicked(isClicked => {
      if (!isClicked) {
        handleModal();
      }
      return !isClicked;
    });
  };

  const handleEditModal = () => {
    setIsEditClicked(!isEditClicked);
  };

  const toggleEdiyModalInput = (id: any) => {
    setDriverId(id);
    setIsEditClicked(isEditClicked => {
      if (!isEditClicked) {
        handleEditModal();
      }
      return !isEditClicked;
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      searchableString: serchText,
    };
    AdminService.getDriverBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        if (res.data.length !== 0) {
          setDrivers(res.data);
        }
      }
    });
  };

  //meta title
  document.title = "Manage Drivers";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Manage Drivers" breadcrumbItem={"Create"} />
        <Col lg={12}>
          <Card>
            <CardBody>
              <DriverDetailsModal isOpen={isClicked} handleIsOpen={handleModal} driverId={driverId} />
              <UpdateDriver isOpen={isEditClicked} handlePopup={handleEditModal} driverId={driverId} />
              <div>Create Driver</div>
              <form onSubmit={handleSubmit} autoComplete="off">
                <React.Fragment>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-phoneno-input3" className="place-order-title">
                          First Name <Label className="title-star">*</Label>
                        </Label>
                        <input
                          value={values.firstName}
                          onChange={handleChange}
                          id="firstName"
                          type="text"
                          placeholder=""
                          onBlur={handleBlur}
                          className={errors.firstName && touched.firstName ? "input-error form-control" : "form-control"}
                        />
                        {errors.firstName && touched.firstName && <p className="error place-order-title">{errors.firstName}</p>}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-email-input4" className="place-order-title">
                          Last Name <Label className="title-star">*</Label>
                        </Label>
                        <input
                          value={values.lastName}
                          onChange={handleChange}
                          id="lastName"
                          type="text"
                          placeholder=""
                          onBlur={handleBlur}
                          className={errors.lastName && touched.lastName ? "input-error form-control" : "form-control"}
                        />
                        {errors.lastName && touched.lastName && <p className="error place-order-title">{errors.lastName}</p>}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-phoneno-input3" className="place-order-title">
                          Address <Label className="title-star">*</Label>
                        </Label>
                        <div>
                          <CustomGooglePlacesAutocomplete
                            apiKey={environment.google_maps_key}
                            autocompletionRequest={mapprops}
                            addressType={AddressType.pickup}
                            selectProps={{
                              name: "address",
                            }}
                            handleAddress={handleAddress}
                          />
                        </div>
                        {address?.city === undefined ||
                          address?.city === "" ||
                          (address?.city === null && <p className="text-danger">You have to select a address.</p>)}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-email-input4" className="place-order-title">
                          Email <Label className="title-star">*</Label>
                        </Label>
                        <input
                          value={values.email}
                          onChange={handleChange}
                          id="email"
                          type="text"
                          placeholder=""
                          onBlur={handleBlur}
                          className={errors.email && touched.email ? "input-error form-control" : "form-control"}
                        />
                        {errors.email && touched.email && <p className="error place-order-title">{errors.email}</p>}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-phoneno-input3" className="place-order-title">
                          Phone Number <Label className="title-star">*</Label>
                        </Label>
                        <input
                          value={values.phoneNumber}
                          onChange={handleChange}
                          id="phoneNumber"
                          type="text"
                          placeholder=""
                          onBlur={handleBlur}
                          className={errors.phoneNumber && touched.phoneNumber ? "input-error form-control" : "form-control"}
                        />
                        {errors.phoneNumber && touched.phoneNumber && <p className="error place-order-title">{errors.phoneNumber}</p>}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3 form-group">
                        <Label for="basicpill-email-input4" className="place-order-title">
                          Percentage <Label className="title-star">*</Label>
                        </Label>
                        <input
                          value={values.percentage}
                          onChange={handleChange}
                          id="percentage"
                          type="number"
                          placeholder=""
                          onBlur={handleBlur}
                          className={errors.percentage && touched.percentage ? "input-error form-control" : "form-control"}
                        />
                        {errors.percentage && touched.percentage && <p className="error place-order-title">{errors.percentage}</p>}
                      </div>
                    </Col>
                  </Row>
                  <div className="clearfix">
                    <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-sm px-5 me-lg-2 me-md-2 mb-2 ms-1">
                        Create
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div>Driver List</div>
              {isloading? <div className="d-flex justify-content-center align-items-center"  style={{ height: '500px' }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>:
              <>{drivers.length > 0 ? (
                <DriverTableContainer
                  columns={columns}
                  data={drivers}
                  currentPage={isOffset_p_c}
                  pagelimit={pageCount}
                  handlePageChange={SeeMore}
                  handleSearch={searchTable}
                  handleColumnChange={handleColumnChange}
                  selectedColumns={LIMIT_P_C}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={50}
                  className="custom-header-css"
                  isJobListGlobalFilter={undefined}
                  isAddUserList={undefined}
                  handleOrderClicks={undefined}
                  handleUserClick={undefined}
                  handleCustomerClick={undefined}
                  isAddCustList={undefined}
                  customPageSizeOptions={undefined}
                  isPDFGenerate={true}
                  ispopup={true}
                  isAddressBook={false}
                  hancleDeleteRecords={undefined}
                  handleSelect={undefined}
                  selectedID={undefined}
                  isABusiness={true}
                />
              ) : (
                <Row className="justify-content-md-center justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div>No Drivers to show</div>
                  </Col>
                </Row>
              )}</>}
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default ManageDrivers;
