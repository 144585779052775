import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { DefaultColumnFilter } from "components/Common/filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import download from "../../assets/images/download.svg";
import { OrderService } from "services/OrderService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { environment } from "environment/environment";
import Geocode from "react-geocode";
import "../../assets/css/custom.css";
import SetDateAndTime from "./SetDate&Time";
import AssignDriver from "./AssignDriver";
import AdminOrderStatusModal from "./AdminOrderDetailsModal";
import ReactPaginate from "react-paginate";
import { AdminService } from "services/AdminService";
import UpdateStatus from "./UpdateStatus";
import { OrderStatus } from "enum/OrderStatus";
import Select from "react-select";
import { useDispachCount } from "context/OrderCountProvider";
import "../../assets/css/table.css";

// Geocode.setApiKey(environment.google_maps_key);
// Geocode.setLanguage("en");
// Geocode.setRegion("ca");

function HeaderFields({
  globalFilter,
  handleSearch,
  selectedColumns,
  handleColumnChange,
  showColumnChange,
  setGlobalFilter,
  isJobListGlobalFilter,
  isAddressBook,
  selectedFlatRows,
  hancleDeleteRecords,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const options = [
    { value: 10, label: "10 Rows" },
    { value: 15, label: "15 Rows" },
    { value: 20, label: "20 Rows" },
    { value: 25, label: "25 Rows" },
    { value: 50, label: "50 Rows" },
  ];

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
          {isAddressBook && (
            <button
              className="btn btn-primary bx bx-trash btn-sm px-2 px-2 me-lg-2 me-md-2 mb-2 mt-lg-1 mt-md-1 w-25 h-50"
              disabled={selectedFlatRows.length < 1}
              onClick={() => hancleDeleteRecords(selectedFlatRows)}
            ></button>
          )}
          <div className="search-box">
            <div className="position-relative">
              <label htmlFor="search-bar-0 " className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="border border-light-subtle rounded-3 py-2 px-5 search-max-width"
                  placeholder={`Search ..`}
                  value={value || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          {showColumnChange && (
            <div className="search-box px-md-2  min-w-select-option">
              <Select options={options} value={options.find(option => option.value === selectedColumns)} onChange={handleColumnChange} />
            </div>
          )}
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  );
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const AdminTableContainer = ({
  columns,
  data,
  pagelimit,
  handlePageChange,
  handleSearch,
  handleColumnChange,
  selectedColumns,
  showColumnChange,
  ispopup,
  isJobListGlobalFilter,
  isChangeDateList,
  isChangeDriverList,
  isModified,
  setIsModified,
  customPageSize,
  className,
  isPDFGenerate,
  isAddressBook,
  hancleDeleteRecords,
  handleSelect,
  selectedID,
  getChangedStatusData,
  currentStatus,
  isChangeMultipleStatus,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      isAddressBook &&
        hooks.visibleColumns.push(columns => [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),

            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const [orderId, setOrderId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [isTick, setIsTick] = useState(false);
  const [DateModalOpen, setDateModalOpen] = useState(false);
  const [DriverModalOpen, setDriverModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [checkboxCounter, setCheckboxCounter] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [dispachCount, setDispachCount] = useDispachCount();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const handleSelectedDriverChange = driverId => {
    setSelectedDriver(driverId);
    // You can perform any other actions you need here
  };

  const handleCheckboxCheck = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }

    setCheckboxCounter(prevCounter => prevCounter + 1);
    setIsTick(true);
  };
  const handleCheckboxUnCheck = id => {
    setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    setCheckboxCounter(prevCounter => prevCounter - 1);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleStartTimeChange = time => {
    setSelectedStartTime(time);
  };

  const handleEndTimeChange = time => {
    setSelectedEndTime(time);
  };

  useEffect(() => {
    setIsTick(checkboxCounter > 0);
  }, [checkboxCounter]);

  const toggleSetDateAndTime = () => {
    setDateModalOpen(!DateModalOpen);
  };

  const toggleAssignDriver = () => {
    setDriverModalOpen(!DriverModalOpen);
  };

  const handleDateClick = () => {
    toggleSetDateAndTime(); // Call the toggle function to open/close the modal
  };

  const handleDriverClick = () => {
    toggleAssignDriver(); // Call the toggle function to open/close the modal
  };

  const handleDriverAssign = async () => {
    // if (selectedDriver && selectedIds.length > 0) {
    //   const requestData = selectedIds.map(orderId => ({
    //     orderId: orderId,
    //     driverId: selectedDriver,
    //   }));
    const requestData = {
      orderIds: selectedIds,
      driverId: selectedDriver,
    };
    try {
      const response = await AdminService.postDriverByOrder(requestData);
      if (response.success) {
        // Handle success response
        setSelectedIds([]);
        setSelectedDriver([]);
        setIsModified(!isModified);
        toast.success("Driver updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        toggleAssignDriver();
        statuscount();
        getChangedStatusData();
      } else {
        toast.error("Please select driver to continue", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        // Handle error response
        console.error("Failed to update orders");
      }
    } catch (error) {
      toast.error("Please select driver to continue", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
      // Handle API error
      console.error("API error:", error);
    }
    // } else {
    //   console.error("Invalid selection");
    // }

  };

  const generateOrderPdf = orderid => {
    OrderService.genarateOrderPDF(orderid).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const generateShippingLabelPdf = orderid => {
    OrderService.generateShippingLabelPDF(orderid).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const generateSortingIndicator = column => {
    //return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
    return column.isSortedDesc ? " ⥯" : " ⥮";
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const toggleOrderModalInput = id => {
    setOrderId(id);
    setIsClicked(isClicked => {
      if (!isClicked) {
        handleModal();
      }
      return !isClicked;
    });
  };

  const handleStatusModalClose = () => {
    setShowStatusUpdateModal(!showStatusUpdateModal);
  };

  const handleUpdateStatus = data => {
    if (selectedIds.length != []) {
      if (selectedIds.length <= 0) {
        toast.error("Please select order status", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        handleStatusModalClose();
      } else {
        const formData = new FormData();
        // formData.append("orderId", selectedOrderId);
        formData.append("orderIds", selectedIds);
        formData.append("status", data.status);
        // if (orderStatus === OrderStatus.DROPPED && data.imageFile) {
        // Create a FormData object to send the data as form data
        formData.append("image", data.imageFile); // Append the file
        formData.append("notes", data.notes);
        formData.append("receiverName", data.receiverName);
        formData.append("shipperName", data.shipperName);
        formData.append("returnName", data.returnName);
        formData.append("redeliverName", data.redeliverName);
        formData.append("date", data.date);
        formData.append("time", data.time);
        // Send the form data using axios
        AdminService.updateMultipleOrderStatus(formData)
          .then(res => {
            if (res.success) {
              statuscount();
              getChangedStatusData();
              toast.success("Order updated Successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
                style: { marginBottom: "4rem" },
              });
              setIsModified(!isModified);
              // Other logic here...
            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
                style: { marginBottom: "4rem" },
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
        // }
        handleStatusModalClose();
      }
    }
    // if (selectedOrderId == "" || selectedOrderId == undefined || orderStatus === undefined) {
    else {
      if (selectedOrderId == "" || selectedOrderId == undefined || orderStatus === undefined) {
        toast.error("Please select order status", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
        handleStatusModalClose();
      } else {
        const formData = new FormData();
        // formData.append("orderId", selectedOrderId);
        formData.append("orderId", selectedOrderId);
        formData.append("status", data.status);
        // if (orderStatus === OrderStatus.DROPPED && data.imageFile) {
        // Create a FormData object to send the data as form data
        formData.append("image", data.imageFile); // Append the file
        formData.append("notes", data.notes);
        formData.append("receiverName", data.receiverName);
        formData.append("shipperName", data.shipperName);
        formData.append("returnName", data.returnName);
        formData.append("redeliverName", data.redeliverName);
        formData.append("date", data.date);
        formData.append("time", data.time);
        // Send the form data using axios
        AdminService.updateOrderStatus(formData)
          .then(res => {
            if (res.success) {
              statuscount();
              getChangedStatusData();
              toast.success("Order updated Successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
                style: { marginBottom: "4rem" },
              });
              setIsModified(!isModified);
              // Other logic here...
            } else {
              toast.error(res.error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
                style: { marginBottom: "4rem" },
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
        // }
        handleStatusModalClose();
      }
    }
  };

  const statusChangeModalHandler = id => {
    setShowStatusUpdateModal(!showStatusUpdateModal);
    setSelectedOrderId(id);
  };

  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  const handleMultipleStatusChange = () => {
    setShowStatusUpdateModal(!showStatusUpdateModal);
  };

  // const handleSelectedOrders = (id, event) => {
  //   const isSelected = event.target.checked;

  //   if (isSelected) {
  //     // When selecting, add the ID to the selectedInvoices state
  //     setSelectedOrders(previousSelectedOrders => [...previousSelectedOrders, id]);
  //   } else {
  //     // When deselecting, remove the ID from the selectedInvoices state
  //     setSelectedOrders(previousSelectedOrders => previousSelectedOrders.filter(selectedId => selectedId !== id));
  //   }
  // };

  return (
    <Fragment>
      <AdminOrderStatusModal isOpen={isClicked} handleIsOpen={handleModal} orderId={orderId} getPDF={generateOrderPdf} />
      <SetDateAndTime
        isOpen={DateModalOpen}
        toggle={toggleSetDateAndTime}
        onHandleDateChange={handleDateChange}
        onHandleStartTimeChange={handleStartTimeChange}
        onHandleEndTimeChange={handleEndTimeChange}
      />

      <AssignDriver
        onSelectedDriverChange={handleSelectedDriverChange}
        isOpen={DriverModalOpen}
        toggle={toggleAssignDriver}
        handleDriverAssign={handleDriverAssign}
      />
      <UpdateStatus
        onSelectedStatusChange={orderStatus => {
          if (orderStatus !== undefined) {
            setOrderStatus(orderStatus);
          }
        }}
        isOpen={showStatusUpdateModal}
        toggle={handleStatusModalClose}
        handleStatusAssign={handleUpdateStatus}
        currentStatus={currentStatus}
      />
      <Row className="d-flex flex-row justify-content-end pb-2 pb-md-1">
        <Col sm="4" md="6" className="d-inline-flex">
          {isChangeMultipleStatus && (
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                size="sm"
                className="btn mb-2 me-2"
                disabled={!isTick}
                onClick={handleMultipleStatusChange}
              >
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_920_19217)">
                    <path d="M21.084 18.3333V12.8333H15.584" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M0.916016 3.66667V9.16667H6.41602"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.7818 8.25C18.3169 6.93622 17.5268 5.76162 16.4852 4.8358C15.4435 3.90998 14.1843 3.26312 12.8251 2.95557C11.4658 2.64802 10.0508 2.68981 8.71209 3.07704C7.37336 3.46427 6.15453 4.18432 5.16935 5.17L0.916016 9.16666M21.0827 12.8333L16.8293 16.83C15.8442 17.8157 14.6253 18.5357 13.2866 18.923C11.9479 19.3102 10.5329 19.352 9.17361 19.0444C7.81436 18.7369 6.55517 18.09 5.51353 17.1642C4.47189 16.2384 3.68175 15.0638 3.21685 13.75"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_920_19217">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </div>
          )}
          {isChangeDateList && isChangeDriverList && (
            <>
              <div className="text-sm-end">
                <Button type="button" color="primary" size="sm" className="btn mb-2 me-2" disabled={!isTick} onClick={handleDateClick}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.41602 10.0832H8.24935V11.9165H6.41602V10.0832ZM6.41602 13.7502H8.24935V15.5835H6.41602V13.7502ZM10.084 10.0833H11.9173V11.9166H10.084V10.0833ZM10.084 13.7502H11.9173V15.5835H10.084V13.7502ZM13.748 10.0833H15.5814V11.9166H13.748V10.0833ZM13.748 13.7502H15.5814V15.5835H13.748V13.7502Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.58333 20.1665H17.4167C18.4278 20.1665 19.25 19.3442 19.25 18.3332V7.33316V5.49983C19.25 4.48874 18.4278 3.66649 17.4167 3.66649H15.5833V1.83316H13.75V3.66649H8.25V1.83316H6.41667V3.66649H4.58333C3.57225 3.66649 2.75 4.48874 2.75 5.49983V7.33316V18.3332C2.75 19.3442 3.57225 20.1665 4.58333 20.1665ZM17.4154 7.33331L17.4163 18.3333H4.58203V7.33331H17.4154Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              </div>
              <div className="text-sm-end">
                <Button type="button" color="primary" size="sm" className="btn mb-2 me-2" disabled={!isTick} onClick={handleDriverClick}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_411_31905)">
                      <path
                        d="M14.666 19.25V17.4167C14.666 16.4442 14.2797 15.5116 13.5921 14.8239C12.9044 14.1363 11.9718 13.75 10.9993 13.75H4.58268C3.61022 13.75 2.67759 14.1363 1.98996 14.8239C1.30232 15.5116 0.916016 16.4442 0.916016 17.4167V19.25"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M18.334 7.33333V12.8333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M21.084 10.0833H15.584" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M7.79167 10.0833C9.81671 10.0833 11.4583 8.44171 11.4583 6.41667C11.4583 4.39162 9.81671 2.75 7.79167 2.75C5.76662 2.75 4.125 4.39162 4.125 6.41667C4.125 8.44171 5.76662 10.0833 7.79167 10.0833Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_411_31905">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
              </div>
            </>
          )}
        </Col>

        <Col sm="4" md="6">

          <HeaderFields
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            handleSearch={handleSearch}
            handleColumnChange={handleColumnChange}
            selectedColumns={selectedColumns}
            showColumnChange={showColumnChange}
            isJobListGlobalFilter={isJobListGlobalFilter}
            isAddressBook={isAddressBook}
            selectedFlatRows={selectedFlatRows}
            hancleDeleteRecords={hancleDeleteRecords}
          />
        </Col>
      </Row>

      <div className="table-responsive">
        <table bordered hover {...getTableProps()} className="newTable">
          <thead className="newThead">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    className={column.render("Header") === "Weight (lbs)" ? "text-wrap" : "text-nowrap"}
                    {...column.getHeaderProps()}
                  >
                    <div className="d-flex flex-row justify-content-center">
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {column.accessor !== undefined ? generateSortingIndicator(column) : ""}
                      </span>
                      {/* <Filter column={column} /> */}
                    </div>
                  </th>
                ))}
                {isPDFGenerate && <th></th>}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.reverse().map((row, i) => {
              prepareRow(row);
              const isRowSelected = selectedIds.includes(row.original._id);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps()}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={isRowSelected}
                        onChange={event => {
                          if (event.target.checked) {
                            handleCheckboxCheck(row.original._id);
                          } else {
                            handleCheckboxUnCheck(row.original._id);
                          }
                        }}
                      />
                    </td>
                    {row.cells.map(cell => {
                      const dataLabel = cell.column.Header; // Extract the header name for the data-label attribute
                      return (
                        <td
                          key={cell.id}
                          data-label={dataLabel}
                          onClick={() => {
                            if (cell.column.id !== "orderStatus") {
                              ispopup
                                ? toggleOrderModalInput(row.original._id)
                                : selectedID !== undefined
                                  ? handleSelect(row.original._id)
                                  : undefined;
                            } else {
                              if (
                                currentStatus == OrderStatus.DELIVERED ||
                                currentStatus == OrderStatus.DROPPED ||
                                currentStatus == OrderStatus.DELIVERED_TODAY
                              ) {
                                return;
                              }
                              statusChangeModalHandler(row.original._id);
                            }
                          }}
                          {...cell.getCellProps()}
                          style={{
                            cursor: ispopup || selectedID !== undefined ? "pointer" : "default",
                            hover: "#F75D21",
                          }}
                        >
                          {/* {ispopup && (cell.column.Header === "Pickup" || cell.column.Header === "Delivery") && (
                    <div>
                      <div id={cell.column.Header + row.original._id} className="div-style">
                        <GetCity cityAddress={cell.value} />
                      </div>
                    </div>
                  )} */}
                          <div data-tooltip-id={cell.column.Header + row.original._id}>{cell.render("Cell")}</div>
                          {(cell.column.Header === "Pickup" || cell.column.Header === "Delivery") && (
                            <Tooltip
                              id={cell.column.Header + row.original._id}
                              content={cell.column.Header === "Pickup" ? row.original.pickUpAddress.city : row.original.deliveryAddress.city}
                              style={{ backgroundColor: "#FFEDE5", color: "#495057", width: 200 }}
                            />
                          )}
                        </td>
                      );
                    })}
                    {isPDFGenerate && (
                      <td className="px-1 " data-label='' >
                        <span className="download_btn_container">
                          <img className="large_screen_download" src={download} alt="" onClick={() => generateShippingLabelPdf(row.original._id)} />
                          <button className="btn btn-primary  small_screen_download sm-custom-button" onClick={() => generateShippingLabelPdf(row.original._id)}>Download</button>
                        </span>
                      </td>
                    )}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagelimit > 1 && (
        <ReactPaginate
          pageCount={pagelimit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination justify-content-end align-items-end rounded-pill px-md-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </Fragment>
  );
};

AdminTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default AdminTableContainer;
