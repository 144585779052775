/* eslint-disable  @typescript-eslint/no-explicit-any */
import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import DatePicker from "react-datepicker";
// import { Input, DatePicker, Calendar } from 'rsuite';
import "react-datepicker/dist/react-datepicker.css";
import { Invoice } from "models/Invoice";
import { AdminService } from "services/AdminService";
import TableContainer from "components/Common/TableContainer";
import InvoiceView from "./InvoiceView";
import { OrderResponse } from "models/orderResponse";
import { toast } from "react-toastify";
import { Puff } from "react-loader-spinner";

const ViewCustomerBilling: React.FC = () => {
  const [startDate, setStartDate] = useState(moment.tz("America/Toronto").startOf("month"));
  const [endDate, setEndDate] = useState(moment.tz("America/Toronto"));
  const [invoices, setInvoices] = useState([] as Invoice[]);

  const [showTable, setShowTable] = useState(false);
  const [invoice, setInvoice] = useState<Invoice>();
  const [invoiceData, setInvoiceData] = useState({
    index: "",
    customerName: "",
    totalOrders: 0,
    totalAmount: 0,
    totalhst: 0,
    totalsubTotal: 0,
    discount: 0,
    outstandingBalance: 0,
  });
  const [orders, setOrders] = useState([] as OrderResponse[]);

  const [modal, setModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");

  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [isModified] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const data = {
    startDate: startDate,
    endDate: endDate,
  };

  useEffect(() => {
    setIsloading(true);
    getCustomerInvoice();
  }, [isModified]);

  const handleStartDate = (date: Date|null) => {
    const sDate = moment(date);
    setStartDate(sDate);
  };

  const handleEndDate = (date: Date |null) => {
    const eDate = moment(date);
    setEndDate(eDate);
  };

  const handleFilter = () => {
    getCustomerInvoice();
  };

  const getCustomerInvoice = () => {
    AdminService.getBusinessCustomerInvoices(data, LIMIT_P_C, isOffset_p_c)
      .then(res => {
        if (res.success) {
          setInvoices(res.data.billingUsers);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
          setShowTable(true);
        }
        setIsloading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const SeeMore = (page: any) => {
    const count = page.selected + 1;
    setOffset_p_c(page.selected + 1);
    AdminService.getBusinessCustomerInvoices(data, LIMIT_P_C, count).then(res => {
      if (res.success) {
        setInvoices(res.data.billingUsers);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      startDate: startDate,
      endDate: endDate,
      searchableString: serchText,
    };

    AdminService.getBusinessCustomerInvoicesBySearch(searchdata).then(res => {
      if (res.success) {
        if (res.data.billingUsers.length !== 0) {
          setInvoices(res.data.billingUsers);
        }
      }
    });
  };

  const handlePopup = () => {
    setModal(!modal);
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AdminService.getBusinessCustomerInvoices(data, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setInvoices(res.data.billingUsers);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const handleViewInvoice = (values: any) => {
    setSelectedCustomerId(values.original?.customerId);
    setSelectedInvoiceId(values.original?.invoiceId);
    // setSelectedInvoiceId(values.original?.)
    AdminService.getInvoiceById(values.original?.invoiceId).then(res => {
      if (res.success) {
        setInvoice(res.data);
      }
    });

    setInvoiceData({
      index: values.index,
      customerName: values.original?.firstName + " " + values.original?.lastName,
      totalOrders: values.original?.totalOrders,
      totalAmount: values.original?.totalAmount,
      // totalAmount: values.original?.outstandingBalance,
      totalhst: values.original?.totalhst,
      totalsubTotal: values.original?.totalsubTotal,
      discount: values.original?.discount,
      outstandingBalance: values.original?.outstandingBalance,
    });
    if (values) {
      const ordersArray = values.original?.orders;
      if (Array.isArray(ordersArray)) {
        setOrders(ordersArray);
      }
    }
    handlePopup();
  };

  // const generateInvoice = () => {
  //   AdminService.sendBusinessCustomerInvoices(data)
  //     .then(res => {
  //       if (res.success) {
  //         setInvoices(res.data);
  //         setShowTable(false);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // };

  // const generateSingleInvoice = (id: string | undefined) => {
  //   const data = {
  //     id,
  //     startDate,
  //     endDate,
  //   };
  //   AdminService.sendBusinessCustomerInvoices(data)
  //     .then(res => {
  //       if (res.success) {
  //         setInvoices(res.data);
  //         setShowTable(false);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });

  //   generateInvoice();
  // };

  const handleSelectedInvoices = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;
    if (isSelected) {
      // When selecting, add the ID to the selectedInvoices state
      setSelectedInvoices(previousSelectedInvoices => [...previousSelectedInvoices, id]);
    } else {
      // When deselecting, remove the ID from the selectedInvoices state
      setSelectedInvoices(previousSelectedInvoices => previousSelectedInvoices.filter(selectedId => selectedId !== id));
    }
  };

  const sendSelectedInvoicesEmailHandler = () => {
    const invoicedata = { selectedInvoices, dates: data };
    AdminService.sendSelectedInvoicesEmail(invoicedata).then(res => {
      if (res.success) {
        toast.success("Invoices have been sent Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      } else {
        toast.error("Something went wrong. while sending selected invoices.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const sendInvoiceEmailHandler = () => {
    AdminService.sendInvoiceEmail(selectedInvoiceId, data).then(res => {
      if (res.success) {
        toast.success("Invoice has been sent Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "select",
        Header: "",
        Cell: ({ row }: any) => {
          return (
            <div className="form-check d-flex justify-content-center">
              <input
                className="form-check-input"
                type="checkbox"
                name={row.original._id}
                value=""
                id={`checkbox-${row.id}`}
                onChange={event => handleSelectedInvoices(row.original.invoiceId, event)}
              />
            </div>
          );
        },
      },
      {
        id: "userId",
        Header: "Customer ID",
        accessor: "customerId",
      },
      {
        id: "companyName",
        Header: "Company Name",
        accessor: "companyName",
        Cell: ({ row }: any) => {
          return row.original.companyName;
        },
      },
      {
        id: "totalOrders",
        Header: "Total Number of Orders",
        accessor: "totalOrders",
      },
      // {
      //   id: "totalAmount",
      //   Header: "Total Amount Placed",
      //   accessor: "outstandingBalance",
      //   Cell: ({ value }) => `$${value.toFixed(2)}`,
      // },
      {
        id: "totalAmount",
        Header: "Total Amount Placed",
        accessor: "totalAmount",
        // Cell: ({ value }) => `$${value.toFixed(2)}`,
        Cell: ({ row }: any) => {
          const amount = row.original.isAdminUpdated ? row.original.outstandingBalance : row.original.totalAmount;
          return `$${amount.toFixed(2)}`;
        },
      },
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }: any) => {
          return (
            <div className="text-center">
              <button className="btn btn-primary btn-sm px-3 me-2 mb-2" onClick={() => handleViewInvoice(row)}>
                View Invoice
              </button>
              {/* <button className="btn btn-primary btn-sm mb-2" onClick={() => sendInvoiceEmailHandler(row)}>
                Email Invoice
              </button> */}
            </div>
          );
        },
      },
    ],
    []
  );

  document.title = "Customer Billing | Zulu Courier";
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Customer Billing" breadcrumbItem="View Invoice" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix mb-5">
                    <Label>Date Selection</Label>
                    <Row>
                      <Col lg="4" className="h-100">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={startDate ? startDate.toDate() : null}
                          onChange={handleStartDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />
                     
                      </Col>
                      <Col lg="1" className="text-center">
                        <Label className="mt-lg-2">To</Label>
                      </Col>
                      <Col lg="4">
                        <DatePicker
                          onFocus={(e) => e.target.blur()}
                          selected={endDate ? endDate.toDate() : null}
                          onChange={handleEndDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeCaption="Time"
                          className="form-control mb-2"
                        />
                        
                      </Col>
                      <Col lg="3">
                        <div className="d-flex align-items-center mb-2 mt-lg-2">
                          <button type="submit" onClick={handleFilter} className="btn btn-primary btn-sm px-3">
                            Filter
                          </button>
                          <button
                            onClick={sendSelectedInvoicesEmailHandler}
                            className="btn btn-sm btn-primary mx-2"
                            disabled={selectedInvoices.length === 0}
                          >
                            Email invoices
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {showTable && (
                    <div className="wizard clearfix">
                      <Label>Billing Table</Label>
                      <Row>
                        <Col lg="12">
                          {isloading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                              <Puff color="#FFD0B8" height={50} width={50} />
                            </div>
                          ) : (
                            <>
                              {invoices.length > 0 ? (
                                <TableContainer
                                  columns={columns}
                                  data={invoices}
                                  currentPage={undefined}
                                  pagelimit={pageCount}
                                  handlePageChange={SeeMore}
                                  handleColumnChange={handleColumnChange}
                                  selectedColumns={LIMIT_P_C}
                                  showColumnChange={false}
                                  handleSearch={searchTable}
                                  isGlobalFilter={true}
                                  isAddOptions={false}
                                  customPageSize={50}
                                  className="custom-header-css"
                                  isJobListGlobalFilter={undefined}
                                  isAddUserList={undefined}
                                  handleOrderClicks={undefined}
                                  handleUserClick={undefined}
                                  handleCustomerClick={undefined}
                                  isAddCustList={undefined}
                                  handleSendEmail={undefined}
                                  isPDFGenerate={false}
                                  isAddressBook={false}
                                  ispopup={false}
                                  hancleDeleteRecords={undefined}
                                  handleSelect={undefined}
                                  selectedID={undefined}
                                  hasCheckBox={false}
                                />
                              ) : (
                                <Row className="justify-content-md-center justify-content-center align-items-center">
                                  <Col className="col-md-auto">
                                    <div style={{ height: "100px" }}>No Billings</div>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <InvoiceView
            isOpen={modal}
            handlePopup={handlePopup}
            invoice={invoice}
            invoiceDate={invoiceData}
            orders={orders}
            //handleGenerateInvoice={generateSingleInvoice}
            customerId={selectedCustomerId}
            sendSingleInvoiceEmail={sendInvoiceEmailHandler}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomerBilling;
