import { toast } from "react-toastify";
toast.configure()
import "react-toastify/dist/ReactToastify.css";
import { QuoteService } from "services/QuoteService";

export const GenerateQuotePDF = (quote) => {
  QuoteService.generateQuotePDF(quote).then(res => {
    if (res) {
      try {
        const pdfBlob = new Blob([res], { type: "application/pdf" })
        const pdfURL = URL.createObjectURL(pdfBlob)
        window.open(pdfURL, "_blank")
      } catch {
        toast.error("Something went wrong while downloading. Please try again later.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        })
      }
    }  else {
      toast.error("Connection error. Please try again later.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: {marginBottom: "4rem"},
      })
    }
  })
}