import Breadcrumb from "components/Common/Breadcrumb";
import { User } from "models/User";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AdminService } from "services/AdminService";
import { Puff } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


const PendingUsers: React.FC = () => {

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [customers, setCustomers] = useState<any[]>();
  const [LIMIT_P_C, setLIMIT_P_C] = useState(1000);
  const [isloading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    getPendingUsers('');
  }, []);

  const getPendingUsers = (searchText: string) => {
    const searchdata = {
      searchableString: searchText,
    };

    AdminService.getPendingSearchUsers(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        console.log('res=====>', res?.data)
        setCustomers(res?.data);
        //   setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        setIsloading(false)
      }
      setIsloading(false)
    });
  };

  const handleVerifiedUser = (_id: string, otp_id: string, otpCode: number) => {

    Swal.fire({
      title: "Do you want to verified this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F75D21",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: "Yes, verified it!",
    }).then(result => {
      if (result.isConfirmed) {

        const customerData = {
          _id: _id,
          otpId: otp_id,
          otpCode: otpCode,
        }

        AdminService.updateUserStatus(customerData).then(res => {
          if (res.success) {
            if (customers && customers?.length > 0) {
              const updatedCustomers = updateVerifiedStatus(customers, _id, "verified");
              setCustomers(updatedCustomers)
            }

            Swal.fire({
              icon: "success",
              text: "Customer verified",
              confirmButtonColor: "#F75D21",
            })
          } else {
            Swal.fire({
              icon: "error",
              title: res.error,
              confirmButtonColor: "#F75D21",
            })
          }
        })
          .catch(error => {
            console.error(error)
          })
      }
    })
  };


  document.title = "Pending Users | Zulu Courier"

  const handleCopy = (data: string) => {
    if (data) {
      navigator.clipboard.writeText(data)
        .then(() => {
          toast.success(`${data} copied to clipboard!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        })
        .catch((err) => {
          toast.error(`Failed to copy: ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        });
    }
  };

  const updateVerifiedStatus = (array: any[], id: string, newStatus: string) => {
    return array.map(obj => {
      if (obj._id === id) {
        return {
          ...obj,
          verifiedStatus: newStatus
        };
      }
      return obj;
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Users" breadcrumbItem="Non-Verified Users" />
          <Col lg={12}>
            <Card>
              <CardBody>
                {isloading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '500px' }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div> :
                  <>
                    {customers && customers?.length > 0 ? (
                      <div className="table-responsive react-table mx-lg-1 px-lg-2 small mt-2">
                        <table className="newTable">
                          <thead className="newThead">
                            <tr>
                              <th className="text-sm-nowrap">First Name</th>
                              <th className="text-sm-nowrap">Last Name</th>
                              <th className="text-sm-nowrap">Role</th>
                              <th className="text-sm-nowrap">Email Address</th>
                              <th className="text-sm-nowrap">Verification Code</th>
                              <th className="text-sm-nowrap">Status</th>
                              <th className="text-sm-nowrap">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customers?.map((user: any) => {
                              return (
                                <tr key={user._id}>
                                  <td data-label="First Name">{user?.firstName}</td>
                                  <td data-label="Last Name">{user?.lastName}</td>
                                  <td data-label="Role">{user?.role == 'business_customer' ? 'Business Customer' : user?.role == 'personal_customer' ? 'Personal Customer' : null}</td>
                                  <td data-label="Email Address">
                                    <span className="wrap text-wrap">
                                      <span className="d-flex" style={{ maxWidth: '200px' }}>
                                        {user?.email}
                                      </span>
                                      {user?.email && (
                                        <button onClick={() => handleCopy(user?.email)} className="ms-2">
                                          <i className="fa fa-copy " />
                                        </button>
                                      )}
                                    </span>
                                  </td>
                                  <td data-label="Verification Code">
                                    <span className="wrap">
                                      {user?.otpDetails[0]?.otpCode}
                                      {user?.otpDetails[0]?.otpCode && (
                                        <button onClick={() => handleCopy(user?.otpDetails[0]?.otpCode)} style={{ marginLeft: '10px' }}>
                                          <i className="fa fa-copy" />
                                        </button>
                                      )}
                                    </span>
                                  </td>
                                  <td data-label="Status">{user?.verifiedStatus ? user.verifiedStatus.charAt(0).toUpperCase() + user.verifiedStatus.slice(1) : ''}</td>
                                  <td data-label="Action">
                                    <div className="flex flex-md-row gap-2">
                                      {
                                        user?.verifiedStatus == 'pending' ? (
                                          <button className="btn btn-primary small_screen_btn ms-1 mb-1 text-nowrap" onClick={() => handleVerifiedUser(user._id, user?.otpDetails[0]?._id, user?.otpDetails[0]?.otpCode)}>
                                            Verify User
                                          </button>
                                        ) : (
                                          <button disabled={true} className="btn small_screen_btn btn-primary ms-1 mb-1 text-nowrap" >
                                            Verified
                                          </button>
                                        )
                                      }

                                    </div>
                                  </td>
                                </tr>

                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Row className="justify-content-md-center justify-content-center align-items-center">
                        <Col className="col-md-auto">
                          <div>No Non-Verified Users</div>
                        </Col>
                      </Row>
                    )}
                  </>}
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PendingUsers