import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import { Table, Row, Col } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { OrderService } from "services/OrderService";
import { AdminService } from "services/AdminService";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import OrderStatusModal from "modals/OderDetailsModal";
import "../../assets/css/custom.css";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { PaymentOption } from "enum/PaymentOption";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { UserTypes } from "enum/UserTypes";
import "../../assets/css/table.css";

function HeaderFields({
  preGlobalFilteredRows,
  globalFilter,
  handleSearch,
  selectedColumns,
  handleColumnChange,
  showColumnChange,
  bussinesCount,
  personalCount,
  monthlyCount,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const options = [
    { value: 10, label: "10 Rows" },
    { value: 15, label: "15 Rows" },
    { value: 20, label: "20 Rows" },
    { value: 25, label: "25 Rows" },
    { value: 50, label: "50 Rows" },
  ];
  return (
    <React.Fragment>
      <Col md={12}>
        <div className="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end">
          <div className="search-box">
            <div className="position-relative">
              <label htmlFor="search-bar-0 " className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => {
                    setValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control border border-light-subtle rounded-3 py-2 px-5"
                  placeholder={`Search ..`}
                  value={value || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          {showColumnChange && (
            <div className="search-box ps-lg-2 ps-md-2">
              <Select options={options} value={options.find(option => option.value === selectedColumns)} onChange={handleColumnChange} />
            </div>
          )}
        </div>

        <div className="d-lg-flex d-md-flex justify-content-lg-start justify-content-md-start mt-2">
          <div className="col-md-2 card-gap">
            <div className="card bg-secondary text-white">
              <div className="card-body text-center">
                <h5 className="card-title">Personal Customer Count</h5>
                <p className="card-text">{personalCount}</p>
              </div>
            </div>
          </div>

          <div className="col-md-2 card-gap">
            <div className="card bg-secondary text-white pr-3">
              <div className="card-body text-center">
                <h5 className="card-title">Business Customer Count</h5>
                <p className="card-text">{bussinesCount}</p>
              </div>
            </div>
          </div>

          <div className="col-md-2 card-gap">
            <div className="card bg-secondary text-white pr-3">
              <div className="card-body text-center">
                <h5 className="card-title">Approve for monthly invoice</h5>
                <p className="card-text">{monthlyCount}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const ManageCustomerTableContainer = ({
  columns,
  data,
  pagelimit,
  handlePageChange,
  handleSearch,
  handleColumnChange,
  selectedColumns,
  showColumnChange,
  customPageSize,
  className,
  bussinesCount,
  personalCount,
  monthlyCount,
  getAllCustomers,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [orderId, setOrderId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const generateOrderPdf = orderid => {
    OrderService.genarateOrderPDF(orderid).then(res => {
      if (res) {
        try {
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdfBlob);
          window.open(pdfURL, "_blank");
        } catch {
          toast.error("Something went wrong while downloading. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Connection error. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };
  const generateSortingIndicator = column => {
    // return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
    return column.isSortedDesc ? " ▼" : " ▲";
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const toggleOrderModalInput = id => {
    setOrderId(id);
    setIsClicked(isClicked => {
      if (!isClicked) {
        handleModal();
      }
      return !isClicked;
    });
  };

  const handleChange = (val, _id) => {
    Swal.fire({
      title: `Approve for ${val ? `Monthly` : `Single`} Invoice?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F75D21",
      cancelButtonColor: "#A9A9A9",
      confirmButtonText: "Approve it!",
    }).then(result => {
      if (result.isConfirmed) {
        const customerData = {
          _id: _id,
          // isABusiness: val,
          // role: val ? UserTypes.BUSINESS_CUSTOMER : UserTypes.PERSONAL_CUSTOMER,
          paymentOption: val ? PaymentOption.MONTHLY : PaymentOption.SINGLE,
        };

        // OrderService.getPendingStatusOfOrders(_id).then(result => {
        //   if (result.success && result.data === false) {
        AdminService.updateCustomerData(customerData)
          .then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                text: `Approved for ${val ? `Monthly` : `Single`} invoice`,
                confirmButtonColor: "#F75D21",
              });
              getAllCustomers();
            } else {
              Swal.fire({
                icon: "error",
                title: res.error,
                confirmButtonColor: "#F75D21",
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "There are some pending payments, Please try again after settle it down.",
        //     confirmButtonColor: "#F75D21",
        //   });
        // }
        // });
      }
    });
  };

  return (
    <Fragment>
      <OrderStatusModal isOpen={isClicked} handleIsOpen={handleModal} orderId={orderId} getPDF={generateOrderPdf} />
      <Row className="d-flex flex-row justify-content-end p-1">
        <HeaderFields
          bussinesCount={bussinesCount}
          personalCount={personalCount}
          monthlyCount={monthlyCount}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          handleColumnChange={handleColumnChange}
          selectedColumns={selectedColumns}
          showColumnChange={showColumnChange}
          handleSearch={handleSearch}
        />
      </Row>

      <div className="table-responsive react-table">
      <table bordered hover {...getTableProps()} className="newTable">
      <thead className="newThead">
        {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th key={column.id} className={column.render("Header") === "Weight (lbs)" ? "text-wrap" : "text-nowrap"}>
                <div className="d-flex flex-row justify-content-center" {...column.getSortByToggleProps()}>
                  {column.render("Header")}
                  {column.accessor !== undefined ? generateSortingIndicator(column) : ""}
                </div>
                {/* <Filter column={column} /> */}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.reverse().map((row, i) => {
          prepareRow(row);
          return (
            <Fragment key={row.getRowProps().key}>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const dataLabel = cell.column.Header?.props?.children ? cell.column.Header?.props?.children : cell.column.Header; 

                  if (cell.column.id === "paymentOption") {
                    return (
                      <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                        <div className="app" style={{ textAlign: "center" }}>
                          <Switch
                            checked={
                              row.original.paymentOption === PaymentOption.MONTHLY
                                ? true
                                : row.original.paymentOption === PaymentOption.SINGLE
                                ? false
                                : false
                            }
                            value={
                              row.original.paymentOption === PaymentOption.MONTHLY
                                ? true
                                : row.original.paymentOption === PaymentOption.SINGLE
                                ? false
                                : false
                            }
                            onChange={event => handleChange(event, row.original._id)}
                            offColor="#F75D59"
                            onColor="#50C878"
                            width={60}
                            height={25}
                            handleDiameter={20}
                            checkedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 14,
                                  color: "#FFF",
                                  paddingLeft: "10px",
                                }}
                              >
                                On
                              </div>
                            }
                            uncheckedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 14,
                                  color: "#FFF",
                                  paddingRight: "10px",
                                }}
                              >
                                Off
                              </div>
                            }
                          />
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td key={cell.id} {...cell.getCellProps()} data-label={dataLabel}>
                        <div>{cell.render("Cell")}</div>
                      </td>
                    );
                  }
                })}
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
      </div>

      {pagelimit > 1 && (
        <ReactPaginate
          pageCount={pagelimit}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination justify-content-end align-items-end rounded-pill px-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </Fragment>
  );
};

ManageCustomerTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ManageCustomerTableContainer;
