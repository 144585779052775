import { PricePerAfterKM, Setting, VehicleModel } from "models/Setting";
import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { AdminService } from "services/AdminService";
import { KMTypes } from "enum/Setting";

export interface Props {
  isOpen: boolean;
  handlePopup: () => void;
  isModified: boolean;
  setIsModified: (value: boolean) => void;
  settings?: Setting;
}

const UpdateService: React.FC<Props> = ({ isOpen, handlePopup, isModified, setIsModified, settings }: Props) => {
  const [settingValues, setSettingValues] = useState<Setting>();
  const [vehiclePrice, setVehiclePrice] = useState([] as VehicleModel[]);

  const [selectedAfterKM, setSelectedAfterKM] = useState<string>();
  const [priceForAfterKM, setPriceForAfterKM] = useState<number>();

  const [basePriceErr, setBasePriceErr] = useState<string>();
  const [additionalKMErr, setAdditionalKMErr] = useState<string>();
  const [afterKMPriceErr, setAfterKMPriceErr] = useState<string>();
  const [baseWeightErr, setBaseWeightErr] = useState<string>();
  const [vehiclePriceErr, setVehiclePriceErr] = useState<string>();

  useEffect(() => {
    setSelectedAfterKM(settings?.pricePerAfterKM?.km);
    setPriceForAfterKM(settings?.pricePerAfterKM?.price);
    setSettingValues(settings);
    setVehiclePrice(settings?.vehiclePrice !== undefined ? settings?.vehiclePrice : []);
  }, [settings]);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "basePrice":
        if (value == "" || value == undefined || value == null) {
          setBasePriceErr("Required");
        } else {
          setBasePriceErr(undefined);
        }
        setSettingValues({ ...settingValues, basePrice: value });
        break;
      case "pricePerAdditionalKM":
        if (value == "" || value == undefined || value == null) {
          setAdditionalKMErr("Required");
        } else {
          setAdditionalKMErr(undefined);
        }
        setSettingValues({ ...settingValues, pricePerAdditionalKM: value });
        break;
      case "priceForAfterKM":
        if (value == "" || value == undefined || value == null) {
          setAfterKMPriceErr("Required");
        } else {
          setAfterKMPriceErr(undefined);
        }
        setPriceForAfterKM(value);
        break;
      case "baseWeightPrice":
        if (value == "" || value == undefined || value == null) {
          setBaseWeightErr("Required");
        } else {
          setBaseWeightErr(undefined);
        }
        setSettingValues({ ...settingValues, baseWeightPrice: value });
        break;
      default:
        break;
    }
  };

  const handleDropdown = (e: any) => {
    setSelectedAfterKM(e.target.value);
  };

  const handleVehiclePrice = (index: number) => (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value == "" || value == undefined || value == null) {
      setVehiclePriceErr(`${name} price is required`);
    } else {
      setVehiclePriceErr(undefined);
    }

    const newArray = [...vehiclePrice];
    newArray[index] = { ...newArray[index], vehicle: name, price: value };
    setVehiclePrice(newArray);
  };

  const handleUpdate = () => {
    if (basePriceErr !== undefined) {
      toast.error("Base Price is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (additionalKMErr !== undefined) {
      toast.error("Additional KM Price is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (afterKMPriceErr !== undefined) {
      toast.error("After 45km Price is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (baseWeightErr !== undefined) {
      toast.error("Weight Price is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (vehiclePriceErr !== undefined) {
      toast.error("Vehicle Price Price is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      const pricePerAfterKM: PricePerAfterKM = {
        km: selectedAfterKM,
        price: priceForAfterKM,
      };

      const settingsData: Setting = {
        basePrice: settingValues?.basePrice,
        pricePerAdditionalKM: settingValues?.pricePerAdditionalKM,
        pricePerAfterKM: pricePerAfterKM,
        baseWeightPrice: settingValues?.baseWeightPrice,
        pricePerWeightAfter10: settingValues?.pricePerWeightAfter10,
        vehicles: vehiclePrice,
        hst: settingValues?.hst,
      };

      AdminService.updateSettings(settingsData)
        .then(res => {
          if (res.success) {
            setIsModified(!isModified);
            toast.success("Service updated successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
            handlePopup();
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleClose = () => {
    setBasePriceErr(undefined);
    setAdditionalKMErr(undefined);
    setAfterKMPriceErr(undefined);
    setBaseWeightErr(undefined);
    setVehiclePriceErr(undefined);
    setIsModified(!isModified);
    handlePopup();
  };
  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="lg" backdrop="static">
        <ModalHeader toggle={handlePopup} close={closeBtn}>
          Update Service
        </ModalHeader>
        <ModalBody>
          <div className="content clearfix">
            <Row>
              <Col lg="6">
                <div className="mb-3 form-group">
                  <Label>Base Price(Up to 5 Km)</Label>
                  <input
                    type="number"
                    name="basePrice"
                    value={settingValues?.basePrice}
                    required={true}
                    onChange={handleChange}
                    className={basePriceErr ? "input-error form-control" : "form-control"}
                  />
                  {basePriceErr && <p className="error">{basePriceErr}</p>}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3 form-group">
                  <Label>Additional Km</Label>
                  <input
                    type="number"
                    name="pricePerAdditionalKM"
                    value={settingValues?.pricePerAdditionalKM}
                    onChange={handleChange}
                    className={additionalKMErr ? "input-error form-control" : "form-control"}
                  />
                  {additionalKMErr && <p className="error">{additionalKMErr}</p>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3 form-group">
                  <Label>After KM</Label>
                  <select
                    onChange={e => {
                      handleDropdown(e);
                    }}
                    className="form-select font-size-15 fw-bold bg-transparent border-2"
                    value={selectedAfterKM}
                  >
                    <option value={KMTypes.KMAfter30}>After 30 km</option>
                    <option value={KMTypes.KMAfter35}>After 35 km</option>
                    <option value={KMTypes.KMAfter40}>After 40 km</option>
                    <option value={KMTypes.KMAfter45}>After 45 km</option>
                  </select>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3 form-group">
                  <Label>Price Per After KM</Label>
                  <input
                    type="number"
                    name="priceForAfterKM"
                    value={priceForAfterKM}
                    onChange={handleChange}
                    className={afterKMPriceErr ? "input-error form-control" : "form-control"}
                  />
                  {afterKMPriceErr && <p className="error">{afterKMPriceErr}</p>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3 form-group">
                  <Label>Weight</Label>
                  <input
                    type="number"
                    name="baseWeightPrice"
                    value={settingValues?.baseWeightPrice}
                    onChange={handleChange}
                    className={baseWeightErr ? "input-error form-control" : "form-control"}
                  />
                  {baseWeightErr && <p className="error">{baseWeightErr}</p>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="mb-3 form-group">
                  <Label>Vehicle Type</Label>
                  {vehiclePrice !== undefined &&
                    vehiclePrice.map((value, index) => (
                      <div key={value.vehicle}>
                        <Row className="mb-1">
                          <Col lg="6">
                            <input type="text" name={value.vehicle} value={value.vehicle} readOnly className="form-control" />
                          </Col>
                          <Col lg="6">
                            <input
                              type="number"
                              name={value.vehicle}
                              value={value?.price}
                              onChange={handleVehiclePrice(index)}
                              className="form-control"
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  {vehiclePriceErr && <p className="error">{vehiclePriceErr}</p>}
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary btn-sm px-5 py-2 mt-2 rounded-1 w-100" onClick={handleUpdate}>
            Update Service
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateService;
