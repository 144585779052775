// src/components/filter.
import { OrderStatus } from "enum/OrderStatus";
import { Order } from "models/Order";
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
//import components
import { format } from "date-fns";
import Breadcrumb from "components/Common/Breadcrumb";
import { AdminService } from "services/AdminService";
import AdminTableContainer from "../AdminTableContainer";
import { DispatchType } from "enum/DispatchType";
import { useDispachCount } from "context/OrderCountProvider";
import { Puff} from "react-loader-spinner";

const AdminCurrentOrders: React.FC = () => {
  const [currentOrders, setCurrentOrders] = useState([] as Order[]);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(true);
  // const handleRowClick = (row: any) => {
  //   setSelectedOrderId(row?.original?.uniqueId);
  //   setShowStatusUpdateModal(true);
  // };

  const columns = useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "uniqueId",
        Cell: ({ value }: any) => {
          return <div className="link-primary">{value}</div>;
        },
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Date",
        accessor: "deliveryTime",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd.MM.yyyy");
        },
      },
      {
        Header: "Pickup",
        accessor: "pickUpAddress.city",
        Cell: ({ row }: any) => {
          const value = row.original.pickUpAddress.city.split(",");
          const count = value.length;
          const city = count - 3 >= 0 ? value[count - 3] : "";
          return city;
        },
      },
      {
        Header: "PPC",
        accessor: "pickUpAddress.postalCode",
        Cell: ({ value }) => <div style={{ width: 80 }}>{value}</div>,
      },
      {
        Header: "Delivery",
        accessor: "deliveryAddress.city",
        Cell: ({ row }: any) => {
          const value = row.original.deliveryAddress.city.split(",");
          const count = value.length;
          const city = count - 3 >= 0 ? value[count - 3] : "";
          return city;
        },
      },
      {
        Header: "DPC",
        accessor: "deliveryAddress.postalCode",
        Cell: ({ value }) => <div style={{ width: 80 }}>{value}</div>,
      },
      {
        Header: "Driver",
        accessor: "driverUser.name",
      },
      {
        Header: "Weight (lbs)",
        accessor: "totalWeight",
        Cell: props => {
          return props.value.replace(/ pounds/gi, "");
        },
      },
      {
        Header: "Vehicle type",
        accessor: "vehicleType",
      },
      {
        Header: "Price",
        accessor: "subTotal",
        Cell: ({ value }) => `$${value.toFixed(2)}`,
      },
      {
        Header: "Order time",
        accessor: "packageReadyTime",
        Cell: ({ row }: any) => {
          return format(new Date(row.original.packageReadyTime), "hh:mma") + " to " + format(new Date(row.original.deliveryTime), "hh:mma");
        },
      },
      {
        Header: "Status",
        accessor: "orderStatus",
        Cell: props => {
          return props.value.replace(/_/g, " ").replace(/\b\w/g, (char: string) => char.toUpperCase());
        },
      },
    ],
    []
  );

  const [LIMIT_P_C, setLIMIT_P_C] = useState<number>(10);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const data = {
    status: OrderStatus.ASSIGNED,
    uniqueId: "",
    dispatchType: DispatchType.CURRENT_ORDERS,
  };

  const [dispachCount, setDispachCount] = useDispachCount();

  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  useEffect(() => {
    setIsloading(true);
    AdminService.getOrdersByStatus(data, OrderStatus.ASSIGNED, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setCurrentOrders(res.data.orderList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    });
    statuscount();


  }, [isModified]);

  const getChangedStatusDataHandler = () => {
    AdminService.getOrdersByStatus(data, OrderStatus.ASSIGNED, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setCurrentOrders(res.data.orderList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      } else {
      }
    });
  };

  const SeeMore = (page: any) => {
  
    setOffset_p_c(page.selected + 1);
    AdminService.getOrdersByStatus(data, OrderStatus.ASSIGNED, LIMIT_P_C, page.selected + 1).then(res => {
      if (res.success) {
        setCurrentOrders(res.data.orderList);
        setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
      }
    });
  };

  const handleColumnChange = (selectedOption: any) => {
    setLIMIT_P_C(selectedOption.value);
    AdminService.getOrdersByStatus(data, OrderStatus.ASSIGNED, selectedOption.value, 1).then(res => {
      if (res.success) {
        setCurrentOrders(res.data.orderList);
        setPageCount(Math.ceil(res.data.count / selectedOption.value));
      }
    });
  };

  const searchTable = (serchText: string) => {
    const searchdata = {
      status: OrderStatus.ASSIGNED,
      uniqueId: "",
      dispatchType: DispatchType.CURRENT_ORDERS,
      searchableString: serchText,
    };

    AdminService.getOrderdispachsBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        if (res.data.orderList.length !== 0) {
          setCurrentOrders(res.data.orderList);
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
      }
    });
  };

  //meta title
  document.title = "Current Orders | Zulu Courier";

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Dispatch" breadcrumbItem="Current Orders" />
        <Col lg={12}>
          <Card>
            <CardBody>
            {isloading? <div className="d-flex justify-content-center align-items-center"  style={{ height: '500px' }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>:
              <>
              {currentOrders.length > 0 ? (
                <AdminTableContainer
                  columns={columns}
                  data={currentOrders}
                  pagelimit={pageCount}
                  handlePageChange={SeeMore}
                  handleSearch={searchTable}
                  handleColumnChange={handleColumnChange}
                  selectedColumns={LIMIT_P_C}
                  showColumnChange={true}
                  customPageSize={50}
                  className="custom-header-css"
                  isJobListGlobalFilter={undefined}
                  isPDFGenerate={true}
                  ispopup={true}
                  isAddressBook={false}
                  hancleDeleteRecords={undefined}
                  handleSelect={undefined}
                  selectedID={undefined}
                  isChangeDateList={true}
                  isChangeDriverList={true}
                  isModified={isModified}
                  setIsModified={setIsModified}
                  getChangedStatusData={getChangedStatusDataHandler}
                  currentStatus={OrderStatus.ASSIGNED}
                  isChangeMultipleStatus={true}
                />
              ) : (
                <Row className="justify-content-md-center justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div>No Current Orders</div>
                  </Col>
                </Row>
              )}</>
            }
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default AdminCurrentOrders;
