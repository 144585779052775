import Breadcrumb from "components/Common/Breadcrumb";
import CustomGooglePlacesAutocomplete from "components/Common/auto-complete";
import { useAuth } from "context/AuthProvider";
import { AddressType } from "enum/AddressType";
import { environment } from "environment/environment";
import { AddressModel } from "models/City";
import React, { useEffect, useRef, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
// import { Input, DatePicker, Calendar } from 'rsuite';
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import { Radio } from "antd";
import { VehicleType } from "models/VehicleType";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import { QuoteModel } from "models/Quote";
import { GooglePlacesAutocompleteHandle } from "components/Common/auto-complete/types";
import { QuoteService } from "services/QuoteService";
import QuoteModalView from "./QuoteModalView";
import { isBefore, startOfDay } from "date-fns";

function changeTimezone(date: Date, ianatz: string) {
  const invdate = new Date(
    date.toLocaleString("en-US", {
      timeZone: ianatz,
    })
  );
  const diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() - diff);
}

function dateIsValid(date: any) {
  return !Number.isNaN(new Date(date).getTime());
}

const Quote: React.FC = () => {
  const [user] = useAuth();
  const [pickup, setPickUpAddress] = useState<AddressModel>();
  const [deliver, setDeliverAddress] = useState<AddressModel>();
  const [pickupDateTime, setPickupDateTime] = useState(moment.tz("America/Toronto"));
  const [deliveryDateTime, setDeliveryDateTime] = useState(moment.tz("America/Toronto").add(5, "hours"));
  const [totalWeight, setTotalWeight] = useState<number>();
  const [vehicleType, setVehicleType] = useState<string>();
  const [datetimeErr, setDateTimeErr] = useState("");
  const [quote, setQuote] = useState<QuoteModel>();
  const [modal, setModal] = useState(false);
  const [currentDateTime, setcurrentDateTime] = useState(moment.tz("America/Toronto"));
  const weightRef = useRef<HTMLInputElement>(null);
  const pickupRef = useRef<GooglePlacesAutocompleteHandle>(null);
  const deliverRef = useRef<GooglePlacesAutocompleteHandle>(null);

  const minDate = startOfDay(new Date());

  useEffect(() => {
    const toronto = changeTimezone(new Date(), "America/Toronto");
    setcurrentDateTime(moment(toronto));

    if (toronto.getHours() >= 9 && toronto.getHours() < 17) {
      setPickupDateTime(moment(toronto));
      const roundedTime = roundUpToNext15Min(moment.tz("America/Toronto"));
      setPickupDateTime(moment(roundedTime));
    } else {
      setPickupDateTime(moment(toronto).hour(9).minute(0).second(0));
      setDeliveryDateTime(moment(toronto).hour(17).minute(0).second(0));

    }
  }, []);

  const handlePopup = () => {
    setModal(!modal);
  };

  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };
  function roundUpToNext15Min(momentDate: any) {
    const remainder = 15 - (momentDate.minute() % 15);
    if (remainder !== 15) {
      return momentDate.add(remainder, 'minutes').startOf('minute');
    }
    return momentDate.startOf('minute');
  }

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id).then(([place]: google.maps.GeocoderResult[]) => {
        const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
        const latLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        if (type == AddressType.pickup) {
          setPickUpAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        } else if (type == AddressType.delivery) {
          setDeliverAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
        } else {
        }
      }).catch(err => {
        console.log(err);
      });
    }
  };

  const setDefaultTimeToNineAM = (date:Date|null) => {
    // const newDate = new Date(date);
    const newDate = moment.tz(date, 'America/Toronto').toDate();
    newDate.setHours(9, 0, 0, 0); // Set the time to 9:00 AM
    return newDate;
  };

  const handlePickupDateChange = (date: Date | null) => {
    let pickupDateTimeObj = moment(date);

    if (pickupDateTimeObj.isSameOrAfter(currentDateTime, "hour") && dateIsValid(pickupDateTimeObj)) {
      setPickupDateTime(pickupDateTimeObj);
      setDeliveryDateTime(moment(pickupDateTimeObj).add(5, "hours"));
    }

  };

  const handleDeliveryDateChange = (date: Date | null) => {
    const pickDate = moment(pickupDateTime).add(2, "hours");
    const deliveryDate = moment(date);
    // if (deliveryDate <= pickDate) {
    if (deliveryDate < pickDate) {
      setDateTimeErr("Delivery date must be at least two hours after the pickup date and time.");
      setDeliveryDateTime(deliveryDate);
    } 
     else if (dateIsValid(deliveryDate)) {

      setDeliveryDateTime(deliveryDate);
      setDateTimeErr("");
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "totalWeight":
        setTotalWeight(value);
        break;
      case "vehicleType":
        setVehicleType(value);
        break;
      default:
        break;
    }
  };

  const handleView = () => {
    const pickDate = moment(pickupDateTime).add(2, "hours");

    if (pickup?.city == "" || pickup?.city == undefined) {
      toast.error("Pick up address is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (deliver?.city == "" || deliver?.city == undefined) {
      toast.error("Delivery address is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (!dateIsValid(pickupDateTime)) {
      toast.error("Pick - up date or time can not be empty", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (!dateIsValid(deliveryDateTime)) {
      toast.error("Delivery date or time can not be empty", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (deliveryDateTime <= pickDate) {
      toast.error("Delivery date must be at least two hours after the pickup date and time", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (totalWeight == null || totalWeight == 0) {
      toast.error("Total weight is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (vehicleType == undefined || vehicleType == null) {
      toast.error("Vehicle type is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else {
      const submitData: QuoteModel = {
        pickUp: pickup,
        delivery: deliver,
        deliveryTime: moment(deliveryDateTime).tz('America/Toronto').format(),
        packageReadyTime: moment(pickupDateTime).tz('America/Toronto').format(),
        totalWeight: totalWeight.toString() + " pounds",
        vehicleType: vehicleType,
      };

      QuoteService.generateQuote(submitData)
        .then(res => {
          if (res.success) {
            setQuote(res.data);
            setPickUpAddress(undefined);
            setDeliverAddress(undefined);
            setPickupDateTime(moment.tz("America/Toronto"));
            setDeliveryDateTime(moment.tz("America/Toronto").add(5, "hours"));
            setTotalWeight(undefined);
            setVehicleType(undefined);
            setDateTimeErr("");

            pickupRef.current != null ? pickupRef.current.clearField() : "";
            deliverRef.current != null ? deliverRef.current.clearField() : "";
            weightRef.current != null ? (weightRef.current.value = "") : "";

            toast.success("Successfully generated quote.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });

            handlePopup();
          } else {
            toast.error("error occured", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
              style: { marginBottom: "4rem" },
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  document.title = "Quote | Zulu Courier";
  return (
    <React.Fragment>
      <div className={user !== undefined ? "page-content" : "m-5"}>
        <div className="container-fluid">
          {user !== undefined ? (
            <Breadcrumb title="Price Quote" breadcrumbItem="Create Quote" />
          ) : (
            <Label className="fs-4 fw-semibold mb-4">Price Quote For Orders</Label>
          )}
          <Row>
            <Col lg="12">
              <Card className="bg-m-none">
                <CardBody>
                  <div className="wizard clearfix">
                    <Row>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Pick - Up Address <Label className="title-star">*</Label>
                          </Label>
                          <CustomGooglePlacesAutocomplete
                            apiKey={environment.google_maps_key}
                            autocompletionRequest={mapprops}
                            addressType={AddressType.pickup}
                            ref={pickupRef}
                            selectProps={{
                              name: "address",
                            }}
                            handleAddress={handleAddress}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Delivery Address <Label className="title-star">*</Label>
                          </Label>
                          <CustomGooglePlacesAutocomplete
                            apiKey={environment.google_maps_key}
                            autocompletionRequest={mapprops}
                            addressType={AddressType.delivery}
                            ref={deliverRef}
                            selectProps={{
                              name: "address",
                            }}
                            handleAddress={handleAddress}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Pick - Up Date and Time <Label className="title-star">*</Label>
                          </Label>
                          
                          <DatePicker
                            onFocus={(e) => e.target.blur()}
                            selected={pickupDateTime ? pickupDateTime.toDate() : null}
                            onChange={handlePickupDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="Select Date and Time"
                            className="form-control"
                            minDate={moment().toDate()}
                            maxDate={moment().add(1, "year").toDate()}
                            // minTime={moment("9:00 am", "h:mm a").toDate()  }
                            minTime={pickupDateTime && moment(pickupDateTime).isSame(moment(), "day") && moment(currentDateTime, "h:mm a").toDate() > moment("9:00 am", "h:mm a").toDate()
                              ? moment(currentDateTime, "h:mm a").toDate()// Hide past times if delivery is today
                              : moment("9:00 am", "h:mm a").toDate() // Show full range for other days
                            }
                            maxTime={moment("5:00 pm", "h:mm a").toDate()}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Delivery Date and Time <Label className="title-star">*</Label>
                          </Label>
                          <DatePicker
                            onFocus={(e) => e.target.blur()}
                            selected={deliveryDateTime ? deliveryDateTime.toDate() : null}
                            onChange={handleDeliveryDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="Select Date and Time"
                            minDate={moment(pickupDateTime).toDate()}
                            maxDate={moment(pickupDateTime).toDate()}
                            minTime={moment(pickupDateTime, "h:mm a").add(2, "hour").toDate()}
                            maxTime={moment("7:00 pm", "h:mm a").toDate()}
                            className="form-control"
                          />
                         
                          {datetimeErr && <p className="error place-order-title">{datetimeErr}</p>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Total weight of all the items(lbs) <Label className="title-star">*</Label>
                          </Label>
                          <input
                            value={totalWeight}
                            onChange={handleChange}
                            name="totalWeight"
                            type="number"
                            ref={weightRef}
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3 form-group">
                          <Label>
                            Choose vehicle <Label className="title-star">*</Label>
                          </Label>
                          <div className="col-lg-6 form-check">
                            <Radio.Group name="vehicleType" value={vehicleType} onChange={handleChange}>
                              <Radio value={VehicleType.CAR}>Car</Radio>
                              <Radio value={VehicleType.SUV}>SUV</Radio>
                              <Radio value={VehicleType.VAN}>Mini Van</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="actions clearfix">
                      <button type="submit" onClick={handleView} className="btn btn-primary btn-sm px-5">
                        Generate Quote
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <QuoteModalView isOpen={modal} handlePopup={handlePopup} quote={quote} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Quote;
