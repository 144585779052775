import Breadcrumb from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { User } from "models/User";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AdminService } from "services/AdminService";
import PlaceOrders from "./PlaceOrders";
import { AuthService } from "services/AuthService";
import { Puff} from "react-loader-spinner";

const getInitials = (name: any) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
      initials =
          nameSplit[0].substring(0, 1) +
          nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

const createImageFromInitials = (size: number, name: any, color: any) => {
  if (name == null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context!.fillStyle = color
    context!.fillRect(0,0,size,size)

    context!.fillStyle = "#ffffff";
    context!.textBaseline = 'middle'
    context!.textAlign = 'center'
    context!.font =`${size/2}px Roboto`
    context!.fillText(name,(size/2),(size/2))

    return canvas.toDataURL()
};

export const CustonerImage = (photoId: any) => {
  const [imgSrc, setImgSrc] = useState<any>()
  useEffect(() => {
    AuthService.getProfileImage(photoId.photoId).then(res => {     
      setImgSrc(`data:image/*;base64,${res}`)
    })
  }, [])

  
  
  return (
    <img className="rounded-circle header-profile-user" src={imgSrc} alt="profile-pic"/>
  )
}

const Users: React.FC = () => {

  const [isOffset_p_c, setOffset_p_c] = useState<number>(1);
    const [customers, setCustomers] = useState([] as User[]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [modal, setModal] = useState(false)
    const [customer, setCustomer] = useState<User>()
    const [LIMIT_P_C, setLIMIT_P_C] = useState(10);
    const [isloading, setIsloading] = useState<boolean>(true);

    const columns = useMemo(
      () => [
        {
          id: "customerImage",
          Header: "",
          //accessor: "firstName",
          Cell: ({ row }: any) => {
            return (
              <div className="text-center">
                {row.original.photoId !== undefined && row.original.photoId !== "" && row.original.photoId !== null ? (
                  <CustonerImage photoId={row.original.photoId}/>
                ) : (
                  <img
                    className="rounded-circle header-profile-user"
                    id='preview'
                    src={createImageFromInitials(40, row.original.firstName, "#ff115a")}
                    alt="profile-pic"
                  />
                )}
                
              </div>
            )
          },
        },
        {
          id: "customerName",
          Header: "Customer",
          accessor: "firstName",
          isSorted: true,
          Cell: ({ row }: any) => {
            return row.original.firstName + " " + row.original.lastName
          },
        },
        {
          id: "email",
          Header: "Email",
          accessor: "email",
        },
        {
          id: "companyName",
          Header: "Company",
          accessor: "address.companyName",
          Cell: ({ value }: any) => {
            return value !== "" && value !== undefined && value !== null ? value : " - "
          },
        },
        {
          id: "action",
          Header: "Action",
          accessor: "_id",
          Cell: ({row}: any) => { return (
            <div className="col-md-12 text-center width_full_td">
              <button
                className="btn btn-primary btn-sm px-md-3 table_button"
                onClick={() => handleAction(row)}
              >
                View
              </button>
            </div>
          )}
        },
      ],
      []
    )

    useEffect(() => {
      setIsloading(true);
     AdminService.getCustomerList(LIMIT_P_C, isOffset_p_c).then(res => {
        if (res.success) {
          setCustomers(res.data.userList)
          setPageCount(Math.ceil(res.data.count / LIMIT_P_C));
        }
        setIsloading(false);
      })
    }, [])

    const SeeMore = (page:any) => {
      setOffset_p_c(page.selected+1);
      AdminService.getCustomerList(LIMIT_P_C, page.selected+1).then(res => {
        if (res.success) {
          setCustomers(res.data.userList)
        }
      });
    };

    const handleColumnChange = (selectedOption:any) => {
      setLIMIT_P_C(selectedOption.value);
      AdminService.getCustomerList(selectedOption.value,1).then(res => {
        if (res.success) {
          setCustomers(res.data.userList);
          setPageCount(Math.ceil(res.data.count / selectedOption.value));
        } 
      });
    };

    const searchTable = (serchText:string) => {
      const searchdata = {
        searchableString:serchText
      };
      AdminService.getUsersBySearch(searchdata, LIMIT_P_C, isOffset_p_c).then(res => {
        if (res.success) {
          if (res.data.length !== 0){
            setCustomers(res.data);
          }
        } 
      });
    };

    const handleAction = (values: any) =>{
      AdminService.getCustomerById(values.original?._id).then(res => {
        if (res.success) {
          setCustomer(res.data)
        }
      })
      handlePopup()
    }

    const handlePopup = () => {
      setModal(!modal)
    }

    document.title = "Place Order | Zulu Courier"

    return (
      <React.Fragment>  
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumb title="Place Order" breadcrumbItem="Create Order" />
            <Col lg={12}>
              <Card className="bg-m-none">
                <CardBody>
                {isloading? <div className="d-flex justify-content-center align-items-center"  style={{ height: '500px' }}>
                  <Puff color="#FFD0B8" height={50} width={50} />
                </div>:
                 <>
                  {customers.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={customers}
                      currentPage={isOffset_p_c}
                      pagelimit={pageCount}
                      handlePageChange={SeeMore}
                      handleSearch ={searchTable}
                      handleColumnChange={handleColumnChange}
                      selectedColumns={LIMIT_P_C}
                      showColumnChange={true}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={50}
                      className="custom-header-css"
                      isJobListGlobalFilter={undefined}
                      isAddUserList={undefined}
                      handleOrderClicks={undefined}
                      handleUserClick={undefined}
                      handleCustomerClick={undefined}
                      isAddCustList={undefined}
                      handleSendEmail={undefined}
                      isPDFGenerate={false}
                      isAddressBook={false}
                      ispopup={false}
                      hancleDeleteRecords={undefined}
                      handleSelect={undefined}
                      selectedID={undefined}
                      hasCheckBox={false}
                    />
                  ): (
                    <Row className="justify-content-md-center justify-content-center align-items-center">
                      <Col className="col-md-auto">
                        <div>No Customers</div>
                      </Col>
                    </Row>
                  )}
                  </>}
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>

        <PlaceOrders isOpen={modal} handlePopup={handlePopup} customer={customer} setModal={setModal} />
      </React.Fragment>
    )
}

export default Users