import React, { useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import UpdateOrder from "./UpdateOrder";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import CancelOrder from "./CancelOrder";
import { AdminService } from "services/AdminService";

export type InputValueType = { uniqueId?: string; _id?: string };

const ManageOrder: React.FC = () => {

  const [modalUpdate, setModalUpdate] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)

  const [options, setOptions] = useState<InputValueType[]>([]);
  const [value, setValue] = useState<InputValueType>();

  const handleUpdatePopup = () => {
    setModalUpdate(!modalUpdate)
  }
  const updateOrder = () => {
    if (value?.uniqueId == "" || value?.uniqueId == undefined) {
      toast.error("Order Id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: {marginBottom: "4rem"},
      })
    } else {
      AdminService.searchOrders(value?.uniqueId).then(res => {
        if (res.data.length === 0) {
          setValue({uniqueId: "", _id:""})
          toast.error("Order Id is incorrect.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          })
        } else if (res.success) {
          res.data.map(val => {
            setValue({...value, _id: val._id});
          });
          handleUpdatePopup();
        }
      })
    }
  }

  const handleCancelPopup = () => {
    setModalCancel(!modalCancel)
  }
  const cancelOrder = () => {
    if (value?.uniqueId == "" || value?.uniqueId == undefined) {
      toast.error("Order Id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: {marginBottom: "4rem"},
      })
    } else {
      AdminService.searchOrders(value?.uniqueId).then(res => {
        if (res.data.length === 0) {
          setValue({uniqueId: "", _id: ""})
          toast.error("Order Id is incorrect.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: {marginBottom: "4rem"},
          })
        } else if (res.success) {
          res.data.map(val => {
            setValue({...value, _id: val._id});
          });
          handleCancelPopup();
        }
      })
    }
  }

  document.title = "Manage Order";

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="wizard clearfix">
            <div className="content clearfix">
              <Label>Order ID</Label>
              <Row>
                <Col lg="7">
                  <div className="autocomplete-container">
                    <div className="mb-3 form-group">
                      
                      <input
                        onReset={() => {
                          setValue({} as InputValueType);
                        }}
                        onInput={() => setOptions((_) => [])}
                        type="search"
                        value={value?.uniqueId}
                        className="form-control"
                        onChange={(e) => {
                          const targetValue = e.target.value;
                          setValue({ uniqueId: targetValue });

                          AdminService.searchOrders(targetValue).then(val => {
                            if (val.success) {
                              const mappedValues: InputValueType[] = val.data.map((v) => ({
                                uniqueId: v.uniqueId,
                                _id: v._id,
                              }));
                              setOptions((_) => [...mappedValues]);
                            }
                          })
                        }}
                      />
                    </div>
                    {options.length > 0 && (
                      <div className="autocomplete-overlay">
                        {options.map((option, index) => (
                          <div
                            key={index}
                            className="autocomplete-option"
                            onClick={() => {
                              setValue({ uniqueId: option.uniqueId, _id: option._id });
                              setOptions([]);
                            }}
                          >
                            {option.uniqueId}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg="4">
                  <button className="btn btn-primary btn-sm px-3 mt-2 me-2" onClick={updateOrder}>
                    Update Order
                  </button>
                  <button className="btn btn-primary btn-sm px-3 mt-2" onClick={cancelOrder}>
                    Cancel Order
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>

      <UpdateOrder isOpen={modalUpdate} handlePopup={handleUpdatePopup} value={value} setValue={setValue} setOptions={setOptions} />
      <CancelOrder isOpen={modalCancel} handlePopup={handleCancelPopup} value={value} setValue={setValue} setOptions={setOptions} />
    </React.Fragment>
  )
}

export default ManageOrder;