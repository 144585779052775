import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
// import theme from "../../assets/images/signup.png"
import theme from "../../assets/images/login_img_new.png"
import logo from "../../assets/images/logo.png"
import { useFormik } from "formik"
import { AuthService, UserLoginData } from "services/AuthService"
import { toast } from "react-toastify"
toast.configure()
import "react-toastify/dist/ReactToastify.css"
import { RequestState } from "enum/RequestState"
import { loginSchema } from "schemas/loginSchema"
import jwt_decode from "jwt-decode"
import Copyright from "components/Common/copyright"
import { RouteContext } from "context/RouteProvider"

const Signin: React.FC = () => {
  const navigate = useNavigate()
  const [isProtected, setIsProtected] = useContext(RouteContext)
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(RequestState.INITIAL)

  const onSubmit = async (values: UserLoginData, actions: any) => {
    try {
      setLoginRequestState(RequestState.LOADING)
      const email = values.email
      const res = await AuthService.userLogin(values)
      if (res.success) {
        setLoginRequestState(RequestState.SUCCESS)
        const data: any = await jwt_decode(res.data)
        navigate(`/${data?.user_role}/place-orders`, { replace: true })
      } else if (res.errorCode === 1) { 
        setLoginRequestState(RequestState.FAILED)
        setIsProtected(true)
        navigate("/email-verification", { state: { email: email } })
      } else {
        setLoginRequestState(RequestState.FAILED)
        
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: {marginBottom: "4rem"},
        })
      }
    } catch (error) {
      setLoginRequestState(RequestState.FAILED)
    }
    actions.resetForm()
  }

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
      termsAndConditions: false,
    },
    validationSchema: loginSchema,
    onSubmit, // Fix the attribute name here
  })

  const handleCheckboxChange = () => {
    setFieldValue("termsAndConditions", !values.termsAndConditions)
  }

  document.title = "Login | Zulu Courier Dashboard "

  return (
    <div className="container">
      <form onSubmit={handleSubmit} autoComplete="off">
        <section className="my-4">
          <div className="position-relative">
            <div className="row g-5 w-100 justify-content-center sign-form">
              <div className="col-lg-7 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                <img src={theme} alt="" className="w-100 px-5 py-5 position-relative" />
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                <div className="px-4 py-5 w-100">
                  <div className="sign-logo mb-3">
                    <a href="/home">
                    {/* <a href="https://zulucourier.com/home"> */}
                      <img src={logo} alt="" />
                    </a>
                  </div>
                  <h3 className="title my-4 text-black">Customer Login</h3>
                  <p className="mb-4">
                    Do not have an account? <a href="/signup">Sign up now</a>
                  </p>
                  <div className="form-groupco mt-2">
                    <label className="place-order-title">Email</label>
                    {errors.email && touched.email && <label className="error place-order-title ms-3">{errors.email}</label>}
                    <input
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      id="email"
                      type="text"
                      placeholder=""
                      onBlur={handleBlur}
                      className={errors.email && touched.email ? "input-error form-control" : "form-control"}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label className="place-order-title">Password</label>
                    {errors.password && touched.password && <label className="error place-order-title ms-3">{errors.password}</label>}
                    <input
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      id="password"
                      type="password"
                      placeholder=""
                      onBlur={handleBlur}
                      className={errors.password && touched.password ? "input-error form-control" : "form-control"}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <Link to="/forget-password">Forgot your password?</Link>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-12">
                        <input
                          checked={values.termsAndConditions}
                          className="me-2"
                          type="checkbox"
                          onChange={handleCheckboxChange}
                          onBlur={handleBlur}
                        />

                        <label className="place-order-title ms-2">Remember Me</label>
                        {errors.termsAndConditions && touched.termsAndConditions && (
                          <p className="error place-order-title">{errors.termsAndConditions}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button disabled={isSubmitting} className="btn btn-md bg-orange text-white w-100 mt-4 button-bg">
                      Log In
                    </button>
                  </div>

                  <div className="w-100 text-center mt-5">
                    <Copyright />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  )
}

export default Signin
