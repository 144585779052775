import { AddressModel } from "models/City";
import { User } from "models/User";
import { OrderResponse } from "models/orderResponse";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Form, Label, Modal, ModalBody, ModalHeader, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { AdminService } from "services/AdminService";
import "moment/locale/en-gb";
import moment from "moment-timezone";
import "moment-timezone";
import { useFormik } from "formik";
import { personalPlaceOrderSchema } from "schemas/placeOrderSchema";
import { AddressBook } from "models/AddressBook";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { environment } from "environment/environment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Input, DatePicker, Calendar, InputGroup } from 'rsuite';
import { Radio, RadioChangeEvent, Select } from "antd";
import { DeliverOption } from "enum/DeliveryOption";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format, isBefore, isSameDay, startOfDay } from "date-fns";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { VehicleType } from "models/VehicleType";
import { SelectValue } from "antd/lib/select";
import { AdminOrder } from "models/Admin";
import { PaymentOption, PaymentType } from "enum/PaymentOption";
import { AddressType } from "enum/AddressType";
import AdminAddressBookModal from "./AdminAddressBookModal";
import { useDispachCount } from "context/OrderCountProvider";
import CustomGooglePlacesAutocompleteOrders from "components/Common/auto-complete-order";
import { InputValueType } from "components/Common/auto-complete/google-places-autocomplete";
import { OrderService } from "services/OrderService";
import DeliveryVerificationModal from "components/CommonForBoth/DeliveryPinModal/DeliveryPinModal";

export interface PlaceOrderProps {
  isOpen: boolean;
  handlePopup: () => void;
  customer: User | undefined;
  setModal: (value: boolean) => void;
}

const PlaceOrders: React.FC<PlaceOrderProps> = ({ isOpen, handlePopup, customer, setModal }: PlaceOrderProps) => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [pickup, setPickUpAddress] = useState<AddressModel>();
  const [deliver, setDeliverAddress] = useState<AddressModel>();
  const [orderDetailsRespose, setOrderDetailsRespose] = useState<OrderResponse>({} as OrderResponse);
  const [orderRespose, setOrderRespose] = useState<OrderResponse>({} as OrderResponse);
  const [pickupDateTime, setPickupDateTime] = useState<moment.Moment >(moment.tz("America/Toronto"));
  const [currentDateTime, setcurrentDateTime] = useState(moment.tz("America/Toronto"));
  const [deliveryDateTime, setDeliveryDateTime] = useState(moment.tz("America/Toronto").add(5, "hours"));
  const [isDeliveryDateModified, setIsDeliveryDateModified] = useState(false);
  const [ispickupDateModified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isPicked, setIsPicked] = useState<boolean>(false);
  const [isDeliverClicked, setIsDeliverClicked] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const canadianPhoneRegExp = /^(?:(?:\+1|1-)?(?:\(\d{3}\)|\d{3})[-.]\d{3}[-.]\d{4}|(?:\+1|1-)?\d{10})$/;
  const [dispachCount, setDispachCount] = useDispachCount();
  const [mindate, setMindate] = useState(moment.tz("America/Toronto"))
  const [picvalue, setpicValue] = useState<InputValueType>({
    label: "",
    placeId: "",
  });
  const [delvalue, setdelValue] = useState<InputValueType>({
    label: "",
    placeId: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [deliveryPin, setDeliveryPin] = useState('');


  const handleClosePinModal = () => setShowModal(false);


  const handleMouseDown = (value :DeliverOption) => {
    if (value === DeliverOption.DELIVERY_VERIFICATION_PIN) {
      setShowModal(true);
    }
    setFieldValue("deliveryOption", value);
  };

  const validateAndCorrectTime = (date: Date) => {
    const pickupDateTimeObj = moment(date);
    const currentTime = moment().tz("America/Toronto");

    // Ensure the time is within working hours (9 AM to 5 PM)
    const minTime = moment(pickupDateTimeObj).set({ hour: 9, minute: 0, second: 0 });
    const maxTime = moment(pickupDateTimeObj).set({ hour: 17, minute: 0, second: 0 });

    if (pickupDateTimeObj.isSame(currentTime, 'day')) {
      if (pickupDateTimeObj.isBefore(currentTime)) {
        // If the selected date is today and the time is in the past, round up to the next valid time
        const roundedTime = moment(currentTime).add(15 - (currentTime.minute() % 15), 'minutes').startOf('minute');
        return roundedTime.toDate();
      } else if (pickupDateTimeObj.isBefore(minTime)) {
        // If the selected date is today and the time is before 9:00 AM, set to the current time
        return currentTime.toDate();
      } else if (pickupDateTimeObj.isAfter(maxTime)) {
        // If the selected date is today and the time is after 5:00 PM, set to 5:00 PM
        return maxTime.toDate();
      }
    } else {
      if (pickupDateTimeObj.isBefore(minTime)) {
        return minTime.toDate();
      } else if (pickupDateTimeObj.isAfter(maxTime)) {
        return maxTime.toDate();
      }
    }

    return pickupDateTimeObj.toDate();
  };
  const handlePickupDateChange = (date: Date) => {
    const correctedDate = validateAndCorrectTime(date);
    setPickupDateTime(moment(correctedDate));
    handleDeliverDatefromPickupChange(correctedDate);
  };



  // const handlePickupDateChange = (date: Date | null) => {
  //   let pickupDateTimeObj = moment(date);
  //   // const currentDate = moment.tz('America/Toronto');

  //   setPickupDateTime(pickupDateTimeObj);
  //   handleDeliverDatefromPickupChange(date);
  // };


  const handleDeliverDatefromPickupChange = (date: Date | null) => {
    const pickupDateTimeObj = moment(date);
    const threePM = moment(date).set({ hour: 15, minute: 0, second: 0 });
    const fourPM = moment(date).set({ hour: 16, minute: 0, second: 0 });
    if (pickupDateTimeObj.isBefore(threePM)) {
      setDeliveryDateTime(pickupDateTimeObj.hour(17).minute(0).second(0));
    } else if  (pickupDateTimeObj.isBefore(fourPM)) {
      setDeliveryDateTime(pickupDateTimeObj.hour(18).minute(0).second(0));
    } else {
      setDeliveryDateTime(pickupDateTimeObj.hour(19).minute(0).second(0));
    }
  }


  // const handleDeliveryDateChange = (date: Date | null) => {
  //   const pickDate = moment(pickupDateTime).add(2, "hours");
  //   const deliveryDate = moment(date);
  //   // if (deliveryDate <= pickDate) {
  //   if (deliveryDate < pickDate) {
  //     setIsDeliveryDateModified(true);
  //     setErrorMessage("Delivery date must be at least two hours after the pickup date and time.");
  //   } else {
  //       setDeliveryDateTime(moment(date));
  //       setErrorMessage("");
  //       setIsDeliveryDateModified(false);
  //   }
  // };
  const handleDeliveryDateChange = (date: Date | null) => {
    const pickDate = moment(pickupDateTime).add(2, "hours");
    const deliveryDate = moment(date);
    const maxDeliveryTime = moment(date).set({ hour: 19, minute: 0, second: 0 });
  
    if (deliveryDate.isAfter(maxDeliveryTime)) {
      setErrorMessage("Delivery time cannot be after 19:00 (7:00 PM). Setting to 19:00.");
      setDeliveryDateTime(maxDeliveryTime);
      // setIsDeliveryDateModified(false);
      setIsDeliveryDateModified(true);
    } else if (deliveryDate < pickDate) {
      setIsDeliveryDateModified(true);
      setErrorMessage("Delivery date must be at least two hours after the pickup date and time.");
      
    } else {
      setDeliveryDateTime(moment(date));
      setErrorMessage("");
      setIsDeliveryDateModified(false);
    }
  };
  const minDate = startOfDay(new Date());

  useEffect(() => {

    customer?.address?.unit != "" && customer?.address?.unit != null && customer?.address?.unit != undefined
      ? setFieldValue("pickupUnit", customer?.address?.unit)
      : setFieldValue("pickupUnit", "");
    customer?.address?.contactPhone != "" && customer?.address?.contactPhone != null && customer?.address?.contactPhone != undefined
      ? setFieldValue("pickupContactPhone", customer?.address?.contactPhone)
      : setFieldValue("pickupContactPhone", "");
    customer?.address?.contactName != "" && customer?.address?.contactName != null && customer?.address?.contactName != undefined
      ? setFieldValue("pickupContactName", customer?.address?.contactName)
      : setFieldValue("pickupContactName", "");
    customer?.address?.companyName != "" && customer?.address?.companyName != null && customer?.address?.companyName != undefined
      ? setFieldValue("companyName", customer?.address?.companyName)
      : setFieldValue("companyName", "");

    if (customer?.address != null && customer?.address != undefined) {
      setPickUpAddress({
        city: customer?.address?.city,
        lat: customer?.address?.lat,
        lng: customer?.address?.lng,
        postalCode: customer?.address?.postalCode,
        companyName: customer?.businessName,
      });
      customer?.address?.city != "" && customer?.address?.city != null && customer?.address?.city != undefined
        ? setpicValue({ label: customer?.address?.city, placeId: "" })
        : setpicValue({ label: "", placeId: "" });
      setIsPicked(true);
    } else {
      setPickUpAddress({ city: "", lat: 0, lng: 0, postalCode: "", companyName: "" });
    }
    setdelValue({ label: "", placeId: "" });
    const toronto = changeTimezone(new Date(), "America/Toronto");
    setPickupDateTime(moment(toronto));
    setcurrentDateTime(moment(toronto));
    setDeliveryDateTime(moment(toronto).add(2, "hours"));
    const threePM = moment(toronto).set({ hour: 15, minute: 0, second: 0 });
    const fourPM = moment(toronto).set({ hour: 16, minute: 0, second: 0 });


    if (toronto.getHours() >= 9 && toronto.getHours() < 17) {
      setPickupDateTime(moment(toronto));
      const roundedTime = roundUpToNext15Min(moment.tz("America/Toronto"));
      setPickupDateTime(moment(roundedTime));
      setMindate(moment(toronto));
      if (moment(toronto).isBefore(threePM)) {
        setDeliveryDateTime(moment(toronto).hour(17).minute(0).second(0));
      } else if (moment(toronto).isBefore(fourPM)) {
        setDeliveryDateTime(moment(toronto).hour(18).minute(0).second(0));
      } else {
        setDeliveryDateTime(moment(toronto).hour(19).minute(0).second(0));
      }
    } else if (toronto.getHours() >= 17) {
      setMindate(moment(toronto).add(1, "days"));
      setPickupDateTime(moment(toronto).hour(9).minute(0).second(0).add(1, "days"));
      setDeliveryDateTime(moment(toronto).hour(17).minute(0).second(0).add(1, "days"));
    } else {
      setPickupDateTime(moment(toronto).hour(9).minute(0).second(0));
      setDeliveryDateTime(moment(toronto).hour(17).minute(0).second(0));
    }
  }, [isOpen, customer]);

  function changeTimezone(date: Date, ianatz: string) {
    const invdate = new Date(
      date.toLocaleString("en-US", {
        timeZone: ianatz,
      })
    );
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  }
  function roundUpToNext15Min(momentDate: any) {
    const remainder = 15 - (momentDate.minute() % 15);
    if (remainder !== 15) {
      return momentDate.add(remainder, 'minutes').startOf('minute');
    }
    return momentDate.startOf('minute');
  }

  const getStripeSession = () => {
    const orderId = orderRespose._id;
    const userId = orderRespose.userId;

    OrderService.createStripeSession({ orderIdList: [orderId], paymentType: PaymentType.ADMINORDER, customer: userId })
      .then(res => {
        if (res.success) {
          window.location.href = res.data;
        } else {
          return toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          });
        }
      })
      .catch(err => {
        console.error(err.message);
      });
  };

  const initialValues = {
    pickupUnit: "",
    pickupContactPhone: "",
    pickupContactName: "",
    companyName: "",
    pickUpAddressBook: "",
    deliverAddressBook: "",
    deliverUnit: "",
    deliverContactPhone: "",
    deliverContactName: "",
    deliverCompany: "",
    vehicleType: "",
    totalWeight: "",
    totalItems: "",
    description: "",
    deliveryOption: "",
    deliveryPin:"",
    note: "",
    userId: "",
    clientRole: "",
    sentBy: "",
  };

  const getDeails = async (values: typeof initialValues, actions: any) => {
    const submitData: AdminOrder = {
      pickup: pickup,
      deliver: deliver,
      deliveryOption: values.deliveryOption,
      deliveryTime: moment(deliveryDateTime).tz('America/Toronto').format(),
      packageReadyTime: moment(pickupDateTime).tz('America/Toronto').format(),
      totalItems: values.totalItems,
      totalWeight: values.totalWeight.toString() + " pounds",
      vehicleType: values.vehicleType,
      userId: customer?._id,
    };

    await AdminService.orderDetails(submitData).then((res: { success: any; data: any }) => {
      if (res.success) {
        setOrderDetailsRespose(res.data);
      } else {
        toast.error("error occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
    });
  };

  const onSubmit = async (values: typeof initialValues, actions: any) => {
    const submitData: AdminOrder = {
      pickup: pickup,
      deliver: deliver,
      notes: values?.note,
      description: values?.description,
      deliveryOption: values.deliveryOption,
      deliveryTime: moment(deliveryDateTime).tz('America/Toronto').format(),
      packageReadyTime: moment(pickupDateTime).tz('America/Toronto').format(),
      totalItems: values.totalItems,
      totalWeight: values.totalWeight.toString() + " pounds",
      vehicleType: values.vehicleType,
      userId: customer?._id,
      paymentOption: customer?.paymentOption,
      sentBy: values.sentBy,
      deliveryPin:deliveryPin
    };

    await AdminService.createOrder(submitData).then((res: { success: any; data: any }) => {
      if (res.success) {
        setOrderRespose(res.data);
        setactiveTab(1);
        toggleTab(4);

        actions.resetForm();

        customer?.paymentOption == PaymentOption.MONTHLY
          ? toast.success("Successfully placed order.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
            style: { marginBottom: "4rem" },
          })
          : {};
      } else {
        toast.error("error occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          style: { marginBottom: "4rem" },
        });
      }
      statuscount();
    });
  };

  const statuscount = async () => {
    await AdminService.getalldispachcount().then(res => {
      if (res.success) {
        setDispachCount(res.data);
      } else {
      }
    });
  };

  function toggleTab(tab: number): void {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTab1(tab: number): void {
    if (pickup?.city == "" || pickup?.city == undefined) {
      toast.error("Pick-Up Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.pickupContactName == "") {
      toast.error("Contact Name is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (ispickupDateModified == true) {
      toast.error("Invalid date or time selected", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.pickupContactPhone != "" && !canadianPhoneRegExp.test(values.pickupContactPhone)) {
      toast.error("Invalid Canadian phone number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function toggleTab2(tab: number): void {
    if (pickup?.city == "" || pickup?.city == undefined) {
      toast.error("Pick-Up Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.pickupContactName == "") {
      toast.error("Contact Name is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (deliver?.city == "" || deliver?.city == undefined) {
      toast.error("Deliver Address is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.deliverContactName == "") {
      toast.error("Contact Name is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.deliverContactPhone != "" && !canadianPhoneRegExp.test(values.deliverContactPhone)) {
      toast.error("Invalid phone number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.vehicleType == "") {
      toast.error("Vehicle Type is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.totalWeight == "") {
      toast.error("Total weight is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.totalWeight < "1") {
      toast.error("Total weight cannot be negative", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.totalItems == "") {
      toast.error("Total Items is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.totalItems < "1") {
      toast.error("Total Items cannot be negative", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (isDeliveryDateModified == true) {
      toast.error("Delivery date must be at least two hours after the pickup date and time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } else if (values.deliveryOption == "--select delivery option--") {
      toast.error("Delivery Option is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } 
    else if (values.deliveryOption == DeliverOption.DELIVERY_VERIFICATION_PIN && !deliveryPin) {
      toast.error("Delivery pin is requied", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
        style: { marginBottom: "4rem" },
      });
    } 
    
    else if (activeTab !== tab) {
      setPickUpAddress({
        ...pickup,
        unit: values.pickupUnit,
        contactPhone: values.pickupContactPhone,
        contactName: values.pickupContactName,
        companyName: values.companyName,
      });
      setDeliverAddress({
        ...deliver,
        unit: values.deliverUnit,
        contactPhone: values.deliverContactPhone,
        contactName: values.deliverContactName,
        companyName: values.deliverCompany,
      });
      getDeails(values, "set");
      const modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const handleAddress = async (label: string, place_id: string, type: string) => {
    if (place_id !== "") {
      const address = label;
      await geocodeByPlaceId(place_id)
        .then(([place]: google.maps.GeocoderResult[]) => {
          const { long_name: postalCode = "" } = place.address_components.find(c => c.types.includes("postal_code")) || {};
          const latLng = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          if (type == AddressType.pickup) {
            setPickUpAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
          } else if (type == AddressType.delivery) {
            setDeliverAddress({ city: address, lat: latLng.lat, lng: latLng.lng, postalCode: postalCode });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      pickupUnit: "",
      pickupContactPhone: "",
      pickupContactName: "",
      companyName: "",
      pickUpAddressBook: "",
      deliverAddressBook: "",
      deliverUnit: "",
      deliverContactPhone: "",
      deliverContactName: "",
      deliverCompany: "",
      vehicleType: "",
      totalWeight: "",
      totalItems: "",
      description: "",
      deliveryOption: "--select delivery option--",
      note: "",
      userId: "",
      clientRole: "",
      sentBy: "",
      deliveryPin: ""
    },
    validationSchema: personalPlaceOrderSchema,
    onSubmit,
  });

  const handleRadioChange = (event: RadioChangeEvent) => {
    const selectedValue = event.target.value;
    setFieldValue("vehicleType", selectedValue);
  };

  interface TextWithLineBreaksProps {
    text: string;
  }

  const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({ text }) => {
    const maxCharactersPerRow = 25;
    const lines: string[] = [];

    for (let i = 0; i < text.length; i += maxCharactersPerRow) {
      lines.push(text.slice(i, i + maxCharactersPerRow));
    }

    return (
      <div>
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    );
  };

  const handleModal = () => {
    setIsClicked(!isClicked);
  };

  const getAddress = (value: AddressBook) => {
    const pickupAddress: AddressModel = {
      unit: value.address?.unit,
      city: value.address?.city,
      lat: value.address?.lat,
      lng: value.address?.lng,
      postalCode: value.address?.postalCode,
      contactName: value.address?.contactName,
      contactPhone: value.address?.contactPhone,
      street: value.address?.street,
    };

    pickupAddress.city != "" && pickupAddress.city != null && pickupAddress.city != undefined
      ? setpicValue({ label: pickupAddress.city, placeId: "" })
      : setpicValue({ label: "", placeId: "" });

    pickupAddress.unit != "" && pickupAddress.unit != null && pickupAddress.unit != undefined
      ? setFieldValue("pickupUnit", pickupAddress.unit)
      : "";
    pickupAddress.contactPhone != "" && pickupAddress.contactPhone != null && pickupAddress.contactPhone != undefined
      ? setFieldValue("pickupContactPhone", pickupAddress.contactPhone)
      : "";
    pickupAddress.contactName != "" && pickupAddress.contactName != null && pickupAddress.contactName != undefined
      ? setFieldValue("pickupContactName", pickupAddress.contactName)
      : "";
    value.companyName != "" && value.companyName != null && value.companyName != undefined
      ? setFieldValue("companyName", value.companyName)
      : "";

    setPickUpAddress({ city: pickupAddress.city, lat: pickupAddress.lat, lng: pickupAddress.lng, postalCode: pickupAddress.postalCode });
  };

  const handleDeliverModal = () => {
    setIsDeliverClicked(!isDeliverClicked);
  };

  const getDeliverAddress = (value: AddressBook) => {
    const DeliverAddres: AddressModel = {
      unit: value.address?.unit,
      city: value.address?.city,
      lat: value.address?.lat,
      lng: value.address?.lng,
      postalCode: value.address?.postalCode,
      contactName: value.address?.contactName,
      contactPhone: value.address?.contactPhone,
      street: value.address?.street,
    };
    setdelValue({ label: DeliverAddres.city == undefined ? "" : DeliverAddres.city, placeId: "" });

    DeliverAddres.unit != "" && DeliverAddres.unit != null && DeliverAddres.unit != undefined
      ? setFieldValue("deliverUnit", DeliverAddres.unit)
      : "";
    DeliverAddres.contactPhone != "" && DeliverAddres.contactPhone != null && DeliverAddres.contactPhone != undefined
      ? setFieldValue("deliverContactPhone", DeliverAddres.contactPhone)
      : "";
    DeliverAddres.contactName != "" && DeliverAddres.contactName != null && DeliverAddres.contactName != undefined
      ? setFieldValue("deliverContactName", DeliverAddres.contactName)
      : "";
    value.companyName != "" && value.companyName != null && value.companyName != undefined
      ? setFieldValue("deliverCompany", value.companyName)
      : "";

    setDeliverAddress({ city: DeliverAddres.city, lat: DeliverAddres.lat, lng: DeliverAddres.lng, postalCode: DeliverAddres.postalCode });
  };

  // const togglePickupAddressInput = () => {
  //   setIsPicked(prevIsPicked => {
  //     if (!prevIsPicked) {
  //       handleModal();
  //     } else {
  //       if (!isClicked) {
  //         setPickUpAddress(undefined);
  //         setFieldValue("pickupUnit", "");
  //         setFieldValue("pickupContactPhone", "");
  //         setFieldValue("pickupContactName", "");
  //         setFieldValue("companyName", "");
  //       }
  //     }
  //     return !prevIsPicked;
  //   });
  // };

  // const toggleDeliverAddressInput = () => {
  //   setIsChecked(prevIsPicked => {
  //     if (!prevIsPicked) {
  //       handleDeliverModal();
  //     } else {
  //       if (!isDeliverClicked) {
  //         setDeliverAddress(undefined);
  //         setFieldValue("deliverUnit", "");
  //         setFieldValue("deliverContactPhone", "");
  //         setFieldValue("deliverContactName", "");
  //         setFieldValue("deliverCompany", "");
  //       }
  //     }
  //     return !prevIsPicked;
  //   });
  // };

  const mapprops: any = {
    componentRestrictions: { country: "ca" },
    locationRestriction: {
      north: 56.85,    // Northeast corner latitude (Keep this as is)
      south: 41.6,     // Southwest corner latitude (Keep this as is)
      east: -74.0,     // Adjusted Northeast corner longitude to cover Kingston and Cornwall
      west: -95.15     // Keep this as is to cover the entire Ontario province
    }
  };

  const handleClose = () => {
    toggleTab(1);
    resetForm();
    setModal(!isOpen);
    setIsChecked(!isChecked);
    setDeliverAddress(undefined);
    setPickUpAddress(undefined);
    setFieldValue("deliverUnit", "");
    setFieldValue("deliverContactPhone", "");
    setFieldValue("deliverContactName", "");
    setFieldValue("deliverCompany", "");
    setFieldValue("pickupUnit", "");
    setFieldValue("pickupContactPhone", "");
    setFieldValue("pickupContactName", "");
    setFieldValue("companyName", "");
    setDeliveryPin(""); // Reset the delivery pin
    statuscount();
  };

  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="xl" backdrop="static" style={{ maxWidth: "1300px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}>
          Place New Order
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit} autoComplete="off">
            <React.Fragment>
              <AdminAddressBookModal
                isOpen={isClicked}
                handleIsOpen={handleModal}
                customerId={customer?._id}
                getAddress={getAddress}
                setIsChecked={setIsPicked}
              />
              <AdminAddressBookModal
                isOpen={isDeliverClicked}
                handleIsOpen={handleDeliverModal}
                customerId={customer?._id}
                getAddress={getDeliverAddress}
                setIsChecked={setIsChecked}
              />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem className={classnames({ current: activeTab === 1 })}>
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1);
                                }}
                                to={""}
                              >
                                <span className="number">1</span> Add Pick - Up Details
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  toggleTab1(2);
                                }}
                                to={""}
                              >
                                <span className="number">2</span> Add Delivery Details
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                  toggleTab2(3);
                                }}
                                to={""}
                              >
                                <span className="number">3</span> Confirm Details
                              </NavLink>
                            </NavItem>

                            <NavItem className={classnames({ current: activeTab === 4 })}>
                              <NavLink className={classnames({ active: activeTab === 4 })} to={""}>
                                <span className="number">4</span> Payment Details
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <div className="d-flex flex-wrap align-items-center justify-content-start mb-2">
                                        <Label for="basicpill-firstname-input1 input-title" className="place-order-title me-2 me-sm-0 justify-content-center text-center mb_sm_0">
                                          Pick - Up Address <Label className="title-star">*</Label>
                                        </Label>
                                        <div className="label-wrap" onClick={handleModal}>
                                          <span className="custom-label">{"Use Address Book"}</span>
                                        </div>
                                      </div>
                                      <div>
                                        <CustomGooglePlacesAutocompleteOrders
                                          apiKey={environment.google_maps_key}
                                          autocompletionRequest={mapprops}
                                          value={picvalue}
                                          setValue={setpicValue}
                                          addressType={AddressType.pickup}
                                          selectProps={{
                                            name: "address",
                                          }}
                                          handleAddress={handleAddress}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-lastname-input2" className="place-order-title">
                                        Unit Number <Label className="py-2"></Label>
                                      </Label>
                                      <input
                                        value={values.pickupUnit}
                                        onChange={handleChange}
                                        id="pickupUnit"
                                        type="text"
                                        placeholder=""
                                        onBlur={handleBlur}
                                        className={
                                          errors.pickupUnit && touched.pickupUnit
                                            ? "input-error form-control unit-number py-2"
                                            : "form-control  unit-number py-2"
                                        }
                                      />
                                      {errors.pickupUnit && touched.pickupUnit && (
                                        <p className="error place-order-title">{errors.pickupUnit}</p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-phoneno-input3" className="place-order-title">
                                        Contact Name <Label className="title-star">*</Label>
                                      </Label>
                                      <input
                                        value={values.pickupContactName}
                                        onChange={handleChange}
                                        id="pickupContactName"
                                        type="text"
                                        placeholder=""
                                        onBlur={handleBlur}
                                        className={
                                          errors.pickupContactName && touched.pickupContactName
                                            ? "input-error form-control"
                                            : "form-control"
                                        }
                                      />
                                      {errors.pickupContactName && touched.pickupContactName && (
                                        <p className="error place-order-title">{errors.pickupContactName}</p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-email-input4" className="place-order-title">
                                        Contact Phone <Label className="py-2"></Label>
                                      </Label>
                                      <input
                                        value={values.pickupContactPhone}
                                        onChange={handleChange}
                                        id="pickupContactPhone"
                                        type="text"
                                        placeholder=""
                                        onBlur={handleBlur}
                                        className={
                                          errors.pickupContactPhone && touched.pickupContactPhone
                                            ? "input-error form-control"
                                            : "form-control"
                                        }
                                      />
                                      {errors.pickupContactPhone && touched.pickupContactPhone && (
                                        <p className="error place-order-title">{errors.pickupContactPhone}</p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-phoneno-input3" className="place-order-title">
                                        Pickup date & time <Label className="title-star">*</Label>
                                      </Label>
                            
                                      <DatePicker
                                      onFocus={(e) => e.target.blur()}
                                      selected={pickupDateTime ? pickupDateTime.toDate() : null}
                                      onChange={handlePickupDateChange}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={15}
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      timeCaption="Time"
                                      placeholderText="Select Date and Time"
                                      className="form-control"
                                      minDate={mindate.toDate()}
                                      maxDate={moment().add(1, "year").toDate()}
                                     // minTime={moment("9:00 am", "h:mm a").toDate()  }
                                      minTime={
                                        pickupDateTime && moment(pickupDateTime).isSame(moment(), "day") && moment(currentDateTime, "h:mm a").toDate() > moment("9:00 am", "h:mm a").toDate()
                                          ? moment(currentDateTime, "h:mm a").toDate()// Hide past times if delivery is today
                                          : moment("9:00 am", "h:mm a").toDate() // Show full range for other days
                                      }
                                      maxTime={moment("5:00 pm", "h:mm a").toDate()}
                                   
                                    />
                                      {ispickupDateModified && <p className="error place-order-title">{errorMessage}</p>}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-email-input4" className="place-order-title">
                                        Company Name <Label className="py-2"></Label>
                                      </Label>
                                      <input
                                        value={values.companyName}
                                        onChange={handleChange}
                                        id="companyName"
                                        type="text"
                                        placeholder=""
                                        onBlur={handleBlur}
                                        className={errors.companyName && touched.companyName ? "input-error form-control" : "form-control"}
                                      />
                                      {errors.companyName && touched.companyName && (
                                        <p className="error place-order-title">{errors.companyName}</p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-address-input2" className="place-order-title">
                                        Note
                                        <Label className="title-star">
                                          <Label className="title-star"></Label>
                                        </Label>
                                      </Label>
                                      <input
                                        value={values.note}
                                        onChange={handleChange}
                                        id="note"
                                        type="text"
                                        placeholder=""
                                        onBlur={handleBlur}
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Form>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <div className="d-flex flex-wrap align-items-center">
                                          <Label for="basicpill-firstname-input1 input-title" className="place-order-title">
                                            Delivery Address<Label className="title-star">*</Label>
                                          </Label>
                                          <div className="label-wrap" onClick={handleDeliverModal}>
                                            <span className="custom-label">{"Use Address Book"}</span>
                                          </div>
                                        </div>

                                        <div>
                                          <CustomGooglePlacesAutocompleteOrders
                                            apiKey={environment.google_maps_key}
                                            autocompletionRequest={mapprops}
                                            value={delvalue}
                                            setValue={setdelValue}
                                            addressType={AddressType.delivery}
                                            selectProps={{
                                              name: "address",
                                            }}
                                            handleAddress={handleAddress}
                                          />
                                        </div>
                                      </div>
                                    </Col>

                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-vatno-input6" className="place-order-title">
                                          Unit Number <Label className="py-2"></Label>
                                        </Label>
                                        <input
                                          value={values.deliverUnit}
                                          onChange={handleChange}
                                          id="deliverUnit"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className={
                                            errors.deliverUnit && touched.deliverUnit
                                              ? "input-error form-control unit-number py-2"
                                              : "form-control unit-number py-2"
                                          }
                                        />
                                        {errors.deliverUnit && touched.deliverUnit && (
                                          <p className="error place-order-title">{errors.deliverUnit}</p>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-cstno-input7" className="place-order-title">
                                          Contact Name<Label className="title-star">*</Label>
                                        </Label>
                                        <input
                                          value={values.deliverContactName}
                                          onChange={handleChange}
                                          id="deliverContactName"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className={
                                            errors.deliverContactName && touched.deliverContactName
                                              ? "input-error form-control"
                                              : "form-control"
                                          }
                                        />
                                        {errors.deliverContactName && touched.deliverContactName && (
                                          <p className="error place-order-title">{errors.deliverContactName}</p>
                                        )}
                                      </div>
                                    </Col>

                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-servicetax-input8" className="place-order-title">
                                          Contact Phone <Label className="py-2"></Label>
                                        </Label>
                                        <input
                                          value={values.deliverContactPhone}
                                          onChange={handleChange}
                                          id="deliverContactPhone"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className={
                                            errors.deliverContactPhone && touched.deliverContactPhone
                                              ? "input-error form-control"
                                              : "form-control"
                                          }
                                        />
                                        {errors.deliverContactPhone && touched.deliverContactPhone && (
                                          <p className="error place-order-title">{errors.deliverContactPhone}</p>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-declaration-input10" className="place-order-title">
                                          Choose vehicle<Label className="title-star">*</Label>
                                        </Label>
                                        <div className="col-lg-6 form-check">
                                          <Radio.Group value={values.vehicleType} onChange={handleRadioChange} onBlur={handleBlur}>
                                            <Radio value={VehicleType.CAR}>Car</Radio>
                                            <Radio value={VehicleType.SUV}>SUV</Radio>
                                            <Radio value={VehicleType.VAN}>Mini Van</Radio>
                                          </Radio.Group>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-companyuin-input9" className="place-order-title">
                                          Company Name<Label className="title-star"></Label>
                                        </Label>
                                        <input
                                          value={values.deliverCompany}
                                          onChange={handleChange}
                                          id="deliverCompany"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-companyuin-input9" className="place-order-title">
                                          Total weight of all the items(lbs)<Label className="title-star">*</Label>
                                        </Label>
                                        <input
                                          value={values.totalWeight}
                                          onChange={handleChange}
                                          id="totalWeight"
                                          type="number"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          min={1}
                                          className={
                                            errors.totalWeight && touched.totalWeight ? "input-error form-control" : "form-control"
                                          }
                                        />

                                        {errors.totalWeight && touched.totalWeight && (
                                          <p className="error place-order-title">{errors.totalWeight}</p>
                                        )}
                                      </div>
                                    </Col>

                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-declaration-input10" className="place-order-title">
                                          Total number of the items<Label className="title-star">*</Label>
                                        </Label>
                                        <input
                                          value={values.totalItems}
                                          onChange={handleChange}
                                          id="totalItems"
                                          type="number"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          min={1}
                                          className={errors.totalItems && touched.totalItems ? "input-error form-control" : "form-control"}
                                        />
                                        {errors.totalItems && touched.totalItems && (
                                          <p className="error place-order-title">{errors.totalItems}</p>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-phoneno-input3" className="place-order-title">
                                          Deliver Date and Time <Label className="title-star">*</Label>
                                        </Label>
                                  
                                        <DatePicker
                                      onFocus={(e) => e.target.blur()}
                                        selected={deliveryDateTime ? deliveryDateTime.toDate() : null}
                                        onChange={handleDeliveryDateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        timeCaption="Time"
                                        placeholderText="Select Date and Time"
                                        minDate={moment(pickupDateTime).toDate()}
                                        maxDate={moment(pickupDateTime).toDate()}
                                        minTime={moment(pickupDateTime, "h:mm a").add(2, "hour").toDate() }
                                        maxTime={moment("7:00 pm", "h:mm a").toDate()}
                                        className="form-control"
                                     
                                      />

                                        {isDeliveryDateModified && <p className="error place-order-title">{errorMessage}</p>}
                                      </div>
                                    </Col>

                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label className="place-order-title">
                                          If the recipient is not available to select<Label className="title-star">*</Label>
                                        </Label>
                                        <Select
                                          value={values.deliveryOption}
                                          className="form-select"
                                          placeholder="Select Card Type"
                                          defaultValue={DeliverOption.DROP_AT_DOOR}
                                          onChange={(value: SelectValue) => {
                                            setFieldValue("deliveryOption", value);
                                            if (value !== DeliverOption.DELIVERY_VERIFICATION_PIN  ) {
                                              console.log("delivery pin set to empty");
                                              setDeliveryPin("");
                                            }
                                          }}
                                        >
                                          <option value={DeliverOption.DROP_AT_DOOR} className="place-order-title">
                                            Drop at door
                                          </option>
                                          <option value={DeliverOption.DROP_AT_MAILBOX}>Drop at mailbox</option>
                                          <option value={DeliverOption.DROP_AT_FRONT_DESK}>Drop at front desk</option>
                                          <option value={DeliverOption.RETURN_ON_FAILED}>Return the package to me</option>
                                          <option value={DeliverOption.REATTEMPT_ON_FAILED}>Reattempt for tomorrow.</option>
                                          <option value={DeliverOption.CALL_ME_TO_CONFIRM}>Call me to confirm</option>
                                          <option value={DeliverOption.DELIVERY_VERIFICATION_PIN} onMouseDown={() => handleMouseDown(DeliverOption.DELIVERY_VERIFICATION_PIN)  }    >Delivery Verification Pin</option>
                                        
                                        </Select>
                                        
                                        <DeliveryVerificationModal showModal={showModal} handleClosePinModal={handleClosePinModal} setDeliveryPin={setDeliveryPin}
                                        />
                                      
                                      </div>
                                      {errors.deliveryOption && touched.deliveryOption && (
                                        <p className="error place-order-title">{errors.deliveryOption}</p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-companyuin-input9" className="place-order-title">
                                          Notes about the Package<Label className="title-star"></Label>
                                        </Label>
                                        <input
                                          value={values.description}
                                          onChange={handleChange}
                                          id="description"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="mb-3 form-group">
                                        <Label for="basicpill-companyuin-input9" className="place-order-title">
                                          Sent by<Label className="title-star"></Label>
                                        </Label>
                                        <input
                                          value={values.sentBy}
                                          onChange={handleChange}
                                          id="sentBy"
                                          type="text"
                                          placeholder=""
                                          onBlur={handleBlur}
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </TabPane>

                            <TabPane tabId={3}>
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Confirm Detail</h5>
                                      <p className="text-muted">Successfully Place Your Order</p>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                              <div className="row justify-content-center m-lg-5">
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-companyuin-input9" className="place-order-title text-primary fw-bold">
                                        Pick-Up Details
                                      </Label>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Pickup address
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              {pickup?.city}<br />
                                              {pickup?.postalCode}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Company Name
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              {values?.companyName}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Contact Name
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              {values?.pickupContactName}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Contact Phone
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              {values?.pickupContactPhone}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Pick - Up date and Time
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              {pickupDateTime?.format("YYYY-MM-DD HH:mm")}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col lg="5" md="5" sm="4" xs="4">
                                            <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                              Notes
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label> : </Label>
                                          </Col>
                                          <Col lg="5" md="6" sm="6" xs="6">
                                            <Label for="basicpill-companyuin-input9" className="text-muted">
                                              <TextWithLineBreaks text={values?.note} />
                                            </Label>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-companyuin-input9" className="place-order-title text-primary fw-bold">
                                        Delivery Details
                                      </Label>
                                      <Row>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Delivery address
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {deliver?.city}<br />
                                                {deliver?.postalCode}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Company Name
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.deliverCompany}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Contact Name
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.deliverContactName}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Contact Phone
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.deliverContactPhone}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Total Number of Items
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.totalItems}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Total weight of all Items
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.totalWeight}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Choose Vehicle
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.vehicleType}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Notes
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                <TextWithLineBreaks text={values?.description} />
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Sent By
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {values?.sentBy}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>

                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Service time
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {format(new Date(pickupDateTime.toString()), "hh:mma")+ " to " + format(new Date(deliveryDateTime.toString()), "hh:mma")}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3 form-group">
                                      <Label for="basicpill-companyuin-input9" className="place-order-title text-primary fw-bold">
                                        Quote Details
                                      </Label>
                                      <Row>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Total distance
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {orderDetailsRespose?.totalDistance}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div>
                                          <Row>
                                            <Col lg="5" md="5" sm="4" xs="4">
                                              <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                Subtotal
                                              </Label>
                                            </Col>
                                            <Col>
                                              <Label> : </Label>
                                            </Col>
                                            <Col lg="5" md="6" sm="6" xs="6">
                                              <Label for="basicpill-companyuin-input9" className="text-muted">
                                                {"$" + orderDetailsRespose?.subTotal?.toString()}
                                              </Label>
                                            </Col>
                                          </Row>
                                        </div>
                                        {customer?.paymentOption == PaymentOption.SINGLE ? (
                                          <>
                                            <div>
                                              <Row>
                                                <Col lg="5" md="5" sm="4" xs="4">
                                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                    HST 13%
                                                  </Label>
                                                </Col>
                                                <Col>
                                                  <Label> : </Label>
                                                </Col>
                                                <Col lg="5" md="6" sm="6" xs="6">
                                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                                    {"$" + orderDetailsRespose?.hst?.toString()}
                                                  </Label>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div>
                                              <Row>
                                                <Col lg="5" md="5" sm="4" xs="4">
                                                  <Label for="basicpill-companyuin-input9" className="text-muted fw-semibold">
                                                    Total Fare
                                                  </Label>
                                                </Col>
                                                <Col>
                                                  <Label> : </Label>
                                                </Col>
                                                <Col lg="5" md="6" sm="6" xs="6">
                                                  <Label for="basicpill-companyuin-input9" className="text-muted">
                                                    {"$" + orderDetailsRespose?.totalFare?.toString()}
                                                  </Label>
                                                </Col>
                                              </Row>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div>
                                <div className="table-responsive react-table">
                                  <table className="newTable">
                                    <thead className="newThead">
                                      <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Pick-up</th>
                                        <th scope="col">Delivery</th>
                                        <th scope="col">Distance</th>
                                      </tr>
                                    </thead>
                                    <tbody className="place-order-title">
                                      <tr>
                                        <td scope="row" data-label="S.No">1</td>
                                        <td data-label="Pick-up">{orderRespose?.pickUpAddress?.city}</td>
                                        <td data-label="Delivery">{orderRespose?.deliveryAddress?.city}</td>
                                        <td data-label="Distance">{orderRespose?.totalDistance}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <line></line>
                                <Row className="row g-5 justify-content-center">
                                  <Col lg="3">
                                    <table className="table">
                                      <tbody className="place-order-title">
                                        <tr>
                                          <td>Total distance:</td>
                                          <td>{orderRespose?.totalDistance}</td>
                                        </tr>
                                        <tr>
                                          <td>Subtotal:</td>
                                          <td>${orderRespose?.subTotal?.toFixed(2)}</td>
                                        </tr>
                                      </tbody>
                                      {customer?.paymentOption === PaymentOption.SINGLE ? (
                                        <tbody className="place-order-title">
                                          <tr>
                                            <td>HST 13%:</td>
                                            <td>${orderRespose?.hst?.toFixed(2)}</td>
                                          </tr>
                                          <tr>
                                            <td>Total Fare:</td>
                                            <td>${orderRespose?.totalFare?.toFixed(2)}</td>
                                          </tr>
                                        </tbody>
                                      ) : (
                                        <div></div>
                                      )}
                                    </table>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>

                        <div className="actions clearfix">
                          <ul>
                            <li className={activeTab === 1 || activeTab === 4 ? "previous disabled" : "previous"}>
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1);
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            {activeTab === 3 ? (
                              <li className={"next"}>
                                <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-sm py-2">
                                  Confirm <i className="icon-4"></i>
                                </button>{" "}
                              </li>
                            ) : activeTab === 4 ? (
                              customer?.paymentOption == PaymentOption.SINGLE ? (
                                <li className={"next"}>
                                  <button type="button" className="btn btn-sm btn-primary py-2" onClick={getStripeSession}>
                                    Pay Now <i className="icon-4"></i>
                                  </button>{" "}
                                </li>
                              ) : (
                                <li className={"next"}>
                                  <button type="button" className="btn btn-primary btn-sm py-2" onClick={() => handleClose()}>
                                    Close Tab <i className="icon-4"></i>
                                  </button>{" "}
                                </li>
                              )
                            ) : (
                              // : activeTab === 4 && !customer?.isABusiness ? (
                              //   <li className={"next"}>
                              //     <button type="button" className="btn btn-primary btn-sm px-3 py-2" onClick={getStripeSession}>
                              //       Pay Now <i className="icon-4"></i>
                              //     </button>{" "}
                              //   </li>
                              // )
                              <li className={"next"}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    if (activeTab == 1) {
                                      toggleTab1(2);
                                    } else if (activeTab == 2) {
                                      toggleTab2(3);
                                    }
                                  }}
                                >
                                  Next
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PlaceOrders;
