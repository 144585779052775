import { Invoice } from "models/Invoice";
import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { format } from "date-fns";
import { GeneratePDF, GenerateOrderPdf } from "./GeneratePDF";
import { OrderResponse } from "models/orderResponse";
import download from "../../assets/images/download.svg";
import moment from "moment";
import { Tooltip } from "react-tooltip";

export interface CustomerViewProps {
  isOpen: boolean;
  handlePopup: () => void;
  invoice?: Invoice;
  invoiceDate: {
    index: string;
    customerName: string;
    customerId: string;
    totalOrders: number;
    totalAmount: number;
    totalsubTotal: number;
    totalhst: number;
    discount?: number;
    outstandingBalance: number;
  };
  orders: OrderResponse[];
}

const InvoiceView: React.FC<CustomerViewProps> = ({ isOpen, handlePopup, invoice, invoiceDate, orders }: CustomerViewProps) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setButtonDisabled(false);
  }, [isOpen]);

  const handleDownload = () => {
    GeneratePDF(invoice?._id);
    setButtonDisabled(true);
  };

  const handleClose = () => {
    handlePopup();
  };

  const handleaddress = (address: any) => {
    const value = address.split(",");
    const count = value.length;
    const city = count - 3 >= 0 ? value[count - 3] : "";
    return city;
  };

  const closeBtn = <button onClick={handleClose} type="button" className="btn-close" />;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={handlePopup} size="lg" backdrop="static" style={{ maxWidth: "1200px" }}>
        <ModalHeader toggle={handlePopup} close={closeBtn}></ModalHeader>
        <ModalBody className="px-lg-5">
          <h4 className="bg-primary bg-opacity-25 popover-header py-2 text-center">Invoice</h4>
          <div className="mx-lg-3 px-lg-5 small">
            <Row className="pt-4">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Invoice Number
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{invoice?.invoiceId}</Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Number of Orders
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{invoiceDate.totalOrders.toString().padStart(2, "0")}</Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Billing period
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">:</Col>
              <Col xs="5" sm="5" lg="7">
                from {invoice?.startDate !== undefined ? format(new Date(invoice?.startDate), "MMMM do, yyyy") : ""} to{" "}
                {invoice?.endDate !== undefined ? format(new Date(invoice.endDate), "MMMM do, yyyy") : ""}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Customer ID
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{invoiceDate?.customerId}</Col>
            </Row>
            <Row className="pt-2">
              <Col xs="6" sm="6" lg="4" className="fw-semibold">
                Customer Name
              </Col>
              <Col xs="1" sm="1" lg="1" className="d-xs-flex d-none">
                :
              </Col>
              <Col xs="5" sm="5" lg="7">{invoiceDate.customerName}</Col>
            </Row>
          </div>

          <div className="table-responsive react-table mx-lg-3 px-lg-5 small mt-5">
            <table className="newTable">
              <thead className="newThead">
                <tr>
                  <th>Order Id</th>
                  <th>Pick-up</th>
                  <th>Pick-up Time</th>
                  <th>Pick-up Name</th>
                  <th>Delivery</th>
                  <th>Delivery Time</th>
                  <th>Delivery Name</th>
                  <th>No of Orders</th>
                  <th>Amount</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {orders.map((ord, index) => {
                  return (
                    <tr key={ord._id}>
                      <td data-label="Order Id">{ord.uniqueId}</td>
                      <td data-label="Pick-up">
                        <div data-tooltip-id={ord.uniqueId}>{handleaddress(ord.pickUpAddress?.city)}</div>
                        <Tooltip
                          id={ord.uniqueId}
                          content={ord.pickUpAddress?.city}
                          style={{ backgroundColor: "#FFEDE5", color: "#495057", width: 200 }}
                        />
                      </td>
                      <td data-label="Pick-up Time">{moment(ord.packageReadyTime).format("YYYY-MM-DD HH:mm")}</td>
                      <td data-label="Pick-up Name">{ord.pickUpAddress?.contactName}</td>
                      <td data-label="Delivery">
                        <div data-tooltip-id={ord._id}>{handleaddress(ord.deliveryAddress?.city)}</div>
                        <Tooltip
                          id={ord._id}
                          content={ord.deliveryAddress?.city}
                          style={{ backgroundColor: "#FFEDE5", color: "#495057", width: 200 }}
                        />
                      </td>
                      <td data-label="Delivery Time">{moment(ord.deliveryTime).format("YYYY-MM-DD HH:mm")}</td>
                      <td data-label="Delivery Name">{ord.deliveryAddress?.contactName}</td>
                      <td data-label="No of Orders">
                        {ord.totalItems} {ord.totalItems === "1" ? "Pc" : "Pcs"}
                      </td>
                      <td data-label="Amount" className="">${ord.subTotal?.toFixed(2)}</td>
                      {/* <td className="text-end">
                        <img src={download} alt="" onClick={() => GenerateOrderPdf(ord._id)} />
                      </td> */}
                    </tr>
                  );
                })}
                <tr className="large_screen_visibilty">
                  <th colSpan={6} className="bg-primary bg-opacity-25">
                    Total Due
                  </th>
                  <th colSpan={2} className="bg-primary bg-opacity-25">
                    <tr>Total subtotal</tr>
                    <tr>Total HST</tr>
                    <tr>Total Fare</tr>
                    {invoice?.discount && <tr>Discount</tr>}
                    {invoice?.isAdminUpdated && <tr>Discounted total</tr>}
                  </th>
                  <th className="text-end bg-primary bg-opacity-25">
                    <tr> ${invoiceDate?.totalsubTotal.toFixed(2)}</tr>
                    <tr> ${invoiceDate?.totalhst.toFixed(2)}</tr>
                    <tr> ${invoiceDate?.totalAmount.toFixed(2)}</tr>
                    {invoice?.discount && <tr> ${invoiceDate?.discount}</tr>}
                    {invoice?.isAdminUpdated && <tr> ${invoiceDate?.outstandingBalance.toFixed(2)}</tr>}
                  </th>
                </tr>

                <tr className="small_screen_visibilty">
                  <th colSpan={6} className="bg-primary bg-opacity-25">
                    Total Due
                  </th>
                  <th className="text-end bg-primary bg-opacity-25">
                    <tr data-label="Total subtotal" className="d-flex justify-content-between align-items-center"><span>Total subtotal</span> <span>${invoiceDate?.totalsubTotal.toFixed(2)}</span></tr>
                    <tr data-label="Total HST" className="d-flex justify-content-between align-items-center"><span>Total HST</span> <span> ${invoiceDate?.totalhst.toFixed(2)}</span></tr>
                    <tr data-label="Total Fare" className="d-flex justify-content-between align-items-center"><span>Total Fare</span> <span>${invoiceDate?.totalAmount.toFixed(2)}</span> </tr>
                    {invoice?.discount && <tr data-label="Discount"> ${invoiceDate?.discount}</tr>}
                    {invoice?.isAdminUpdated && <tr data-label="Discounted total"> ${invoiceDate?.outstandingBalance.toFixed(2)}</tr>}
                  
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button type="submit" onClick={handleDownload} className="btn btn-primary btn-sm px-3 ms-lg-4" disabled={isButtonDisabled}>
              Download PDF
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceView;
