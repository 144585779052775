import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

// import logo from "../../assets/images/logo.svg";
import logo from "../../assets/images/logo.png";
import logo1 from "../../assets/images/logo1.png";
import logoDark from "../../assets/images/logo-dark.png";
import longLogo from "../../assets/images/longLogo.png";
import LiveChat from "components/CommonForBoth/LiveChat/LiveChat";
import { AuthService } from "services/AuthService";
import { useAuth } from "context/AuthProvider";


const Sidebar = props => {
  const [userData, setUserData] = useState({});
  const [displayChat, setDisplayChat] = useState(false);
  const [user] = useAuth();
  // get userdata for the live chat
  const getUserData = async () => {
    try {
      const currentUser = await AuthService.getMe();
      if (currentUser.success) {
        setUserData(currentUser.data);
        setDisplayChat(true);
      } else {
        setDisplayChat(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []); // Run once when the component mounts

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setDisplayChat(true);
    }
  }, [userData]);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link  to={`/${user?.role}/place-orders`} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="15" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to={`/${user?.role}/place-orders`} className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="15" />
            </span>
            <span className="logo-lg">
              <img src={longLogo} alt="" height="25" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent isMobile={props.isMobile} /> : <SidebarContent isMobile={props.isMobile} />}
        </div>
        <div className="sidebar-background"></div>
      </div>
      {/* LiveChat */}
      {userData.email && userData._id && displayChat && userData.role !== "super_admin" && (
        <LiveChat
          id={userData._id}
          email={userData.email}
          userName={userData.role === "business_customer" ? userData.address.companyName : `${userData.firstName} ${userData.lastName}`}
        />
      )}
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
